<template>
  <van-overlay :show="showSub" class="handleSub">
    <div class="lay">
      <div class="layWrap">
        <div class="layTop">
          <a-icon type="mail"></a-icon>
          <p>我要订阅</p>
        </div>
        <p class="con">输入常用邮箱或手机号，开启订阅，快速获取您感兴趣的州和校园疫情动态～</p>
        <div class="selectWrap">
          <a-select v-model="select" class="select" @change="handleChange">
            <a-select-option :value="index" v-for="(item,index) in options" :key="item.id">{{ item.label }}</a-select-option>
          </a-select>
          <a-input
            :placeholder="placeholder"
            @change="validate=''"
            v-model="loginIdInput"
            class="input"
          ></a-input>
        </div>

        <p class="valTips" v-if="validate">{{ validate }}</p>
        <a-button class="btn" type="primary" @click="handleLogin">开启订阅</a-button>

        <a-icon type="close-circle" class="close" @click="setShowSub(false)"></a-icon>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
export default {
  components: {

  },
  props: {

  },
  data () {
    return {

      loginIdInput: '',
      placeholder: '请输入中国手机',
      validate: '',
      select: 1,
      options: [
        {
          label: '邮箱账号'
        },
        {
          label: '中国手机',
          countryCode: 'CN'
        },
        {
          label: '美国手机',
          countryCode: 'US'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      showSub: state => state.app.showSub
    }),
    ...mapGetters(['loginId'])
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    ...mapMutations({
      setLoginId: 'SET_LOGIN_ID',
      setShowSub: 'SET_SHOW_SUB'
    }),
    ...mapActions(['GetOrder']),
    handleLogin () {
      if (this.loginIdInput) {
        const isEmail = this.$utils.regex.email.test(this.loginIdInput)
        let phoneNumber
        let isPhone = false
        try {
          phoneNumber = parsePhoneNumberFromString(this.loginIdInput, this.options[this.select].countryCode)
          console.log(phoneNumber, phoneNumber.country, phoneNumber.isValid())
          if (phoneNumber) {
            isPhone = phoneNumber.isValid()
          }
        } catch (err) {
          isPhone = false
        }

        // const isPhone = this.$utils.regex.isPhone.test(this.loginIdInput)
        // const isChinaPhone = this.$utils.regex.china.test(this.loginIdInput)
        // const isAmericalPhone = this.$utils.regex.americal.test(this.loginIdInput)
        if (!isEmail && !isPhone) {
          this.validate = '请输入有效的邮箱或者手机号'
          return
        }

        this.GetOrder(this.loginIdInput).then(() => {
          this.setLoginId(this.loginIdInput)
          this.setShowSub(false)
          this.validate = ''
          this.$router.push('/transition')
        })
      }
    },

    handleChange (value) {
      if (value === 0) {
        this.placeholder = '请输入邮箱账号'
      } else if (value === 1) {
        this.placeholder = '请输入中国手机号'
      } else {
        this.placeholder = '请输入美国手机号'
      }
    }
  }
}
</script>

<style scoped lang="less">

.lay{

    height:90%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p{
        margin:0
    }

    .layWrap{
        width:80%;
        background:#fff;
        padding:0 16px;
        border-radius: 4px;
        position:relative;
        .close{
            position:absolute;
            top:10px;
            right:10px;
            font-size:18px;
        }
        .layTop{
            display:flex;
            margin-top:20px;
            justify-content: center;
            align-items:center;
            margin-bottom:15px;
            i{
                font-size:20px;
                margin-right:10px;
                color:@primary-color
            }
            p{
                color:#333;
                font-weight:bold;
                color:@primary-color;
                font-size:18px;
            }
        }
        .con{
            margin-bottom:15px;
        }
        .selectWrap{
            display:flex;
            .select{
                width:103px;
            }
        }
         .valTips{
            margin:10px 0 0;
            color:red
        }
        .btn{
            margin:15px 0 20px;
        }
    }
}
.handleSub{
    z-index:100;
}
</style>
