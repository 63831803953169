<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <slot name="top" v-if="list.length"></slot>
    <van-list
      :offset="0"
      v-model="loading"
      :finished="finished"
      :finished-text="showFinish ? '没有更多了' : ''"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      @load="onLoad"
    >
      <div v-for="(item, index) in list" :key="item.name" :class="{ sep: sep }" class="list-wrap">
        <slot :itemData="item" :myIndex="index"></slot>
      </div>
    </van-list>
    <slot name="noData" v-if="!list.length"></slot>
  </van-pull-refresh>
</template>

<script>
import { NewsItem } from '@/components';
export default {
  components: {
    NewsItem
  },
  props: {
    params: {
      default: () => {},
      type: Object
    },
    url: {
      default: '',
      type: String
    },
    sep: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      error: false,
      finished: false,
      refreshing: false,
      pageNum: 0
    };
  },
  computed: {
    myParams() {
      return {
        ...this.params,
        limit_start: this.pageNum
      };
    },
    showFinish() {
      return this.list.length > this.params.limit_page_length;
    }
  },

  watch: {},
  created() {},
  mounted() {},
  methods: {
    onLoad() {
      this.$api[this.url](this.myParams)
        .then(res => {
          if (res.message) {
            if (this.refreshing) {
              this.list = [];
              this.refreshing = false;
            }
            if (res.message.length < this.params.limit_page_length) {
              this.finished = true;
            }
            this.list = [].concat(this.list, res.message);
            this.pageNum += this.params.limit_page_length;

            this.$emit('handleData', this.list);
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.error = true;
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pageNum = 0;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    }
  }
};
</script>

<style scoped lang="less">
.sep {
  padding: 15px 0;
  border-bottom: 1px solid #efefef;
}
</style>
