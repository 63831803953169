export default [
  {
    name: 'expertView',
    method: 'GET',
    desc: '阅读',
    path: '/api/method/soa.ncov.api.pv2cache'
  },
  {
    name: 'expertLike',
    method: 'GET',
    desc: '点赞',
    path: '/api/method/soa.ncov.api.like2cache'
  },
  {
    name: 'comment',
    method: 'GET',
    desc: '评论列表',
    path: '/api/method/soa.ncov.api.coronavirus_comments'
  },
  {
    name: 'addComment',
    method: 'POST',
    desc: '添加评论',
    path: '/api/method/soa.ncov.api.coronavirus_comments',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  },
  {
    name: 'addReply',
    method: 'PUT',
    desc: '评论回复',
    path: '/api/method/soa.ncov.api.coronavirus_comments',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  },
  {
    name: 'relation',
    method: 'GET',
    desc: '中美关系',
    path: '/api/method/soa.www.cms_adp.get_details?slug=wholeren-company-about'
  },
  {
    name: 'qrcode',
    method: 'GET',
    desc: '获取字典二维码',
    path: '/api/method/soa.www.cms_adp.get_details?slug=customer-service-qrcode'
  }
];
