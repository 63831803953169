<template>
  <div class="notice">
    <van-tag type="danger">重要</van-tag>
    <div class="noticeContent">
      <ul
        class="notice_list"
        :class="noticeList.length > 1 ? { notice_top: animate } : ''"
      >
        <li
          v-for="(item, index) in noticeList"
          :key="index"
          @click="goToNotices"
        >
          <p>{{ item.highlight_title||item.cn_content }} <a-icon type="right"></a-icon> </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  components: {},
  props: {},
  data () {
    return {
      isShowModal: false,
      modalContent: '',
      noticeList: [],
      animate: false
    }
  },
  computed: {},
  watch: {},
  created () {
    const t = this
    setInterval(t.showNotice, 3000)
    this.params = {
      limit_start: 0,
      limit_page_length: 6,
      // eslint-disable-next-line quotes
      filters: { recommended: "Index Top Slide" }
    }

    this.getNews()
  },
  mounted () {},
  methods: {
    ...mapMutations({
      setNewsSlideData: 'SET_NEWS_SLIDE_DATA'
    }),
    showModal (con) {
      this.modalContent = con
      this.isShowModal = true
    },
    showNotice () {
      const t = this
      t.animate = true
      setTimeout(() => {
        t.noticeList.push(t.noticeList[0])
        t.noticeList.shift()
        t.animate = false
      }, 500)
    },
    getNews () {
      this.$api['home/news'](this.params)
        .then(res => {
          console.log(res, 'noticeList')
          if (res) {
            this.noticeList = res.message
            console.log(res.message, 'setNewsSlideData')
            this.setNewsSlideData([].concat(res.message))
          }
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    goToNotices () {
      this.$router.push({
        path: '/newsSlide'

      })
    }

  }
}
</script>

<style scoped lang="less">
.notice {
  width: 100%;

  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.noticeContent {
  // margin-top: 10px;
  display: block;
  position: relative;
  border-radius: 4px;
  width: 80%;
  height: 50px; // 控制高度以达到控制显示条数的目的
  overflow: hidden;
  // background: #fff;
  .notice_list {
    width: 100%;
    padding: 0 10px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    li {
      text-align: left;
      list-style-type: none;
      height: 50px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        flex: 1;
        padding:5px 20px 5px 10px;
        border-radius:4px;
        color: #333;
        background:#fff;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position:relative;
        i{
          position:absolute;
          right:5px;
          color:#666;
          top:9px;
        }
      }
    }
  }
  .notice_top {
    transition: all 1s;
    margin-top: -55px;
  }
}

</style>
