<template>
  <div>
    <ul :class="[{ tabFix: tabFix }, { tab: true }]">
      <li :class="{ active: active === index }" @click="goToPages(item)" v-for="(item, index) in tabData" :key="index">
        <div v-if="!tabFix">
          <img :src="item.icon" alt="" />
          <span class="label"
            >{{ item.label }}
            <van-tag class="tag" v-if="newsCount[item.key]" type="danger">+{{ newsCount[item.key] }}</van-tag>
          </span>
          <span v-if="item.totalCount" class="totalCount">{{ item.totalCount }}</span>
        </div>
        <div v-else>
          <img :src="item.iconBai" alt="" />
          <span class="label"
            >{{ item.label }}
            <van-tag class="tag" v-if="newsCount[item.key]" type="danger">+{{ newsCount[item.key] }}</van-tag>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'App',
  components: {},
  data() {
    return {
      active: 0,

      tabFix: false,
      tabData: [
        {
          label: '热点新闻',
          icon: require('@/assets/home/new/new.png'),
          iconBai: require('@/assets/home/new/new-bai.png'),
          route: 'news',
          totalCount: '',
          key: 'all'
        },
        {
          label: '专家专栏',
          icon: require('@/assets/home/new/expert.png'),
          iconBai: require('@/assets/home/new/expert-bai.png'),
          route: '/expert',
          totalCount: '',
          key: this.$utils.newsCategory.expert
        },
        // {
        //   label: '公益讲座',
        //   icon: require('@/assets/home/new/welfare.png'),
        //   iconBai: require('@/assets/home/new/welfare-bai.png'),
        //   route: '/welfare',
        //   totalCount: '',
        //   key: this.$utils.newsCategory.welfare
        // },
        {
          label: '州列表',
          icon: require('@/assets/home/new/state.png'),
          iconBai: require('@/assets/home/new/state-bai.png'),
          route: '/stateList',
          totalCount: '',
          key: 'states'
        },
        {
          label: '学校列表',
          icon: require('@/assets/home/new/school.png'),
          iconBai: require('@/assets/home/new/school-bai.png'),
          route: '/schoolList',
          totalCount: '',
          key: 'schools'
        }

        // {
        //   label: '小视频',
        //   icon: require('@/assets/home/xiaoshipin.png'),
        //   iconBai: require('@/assets/home/xiaoshipin-bai.png'),
        //   route: 'videoDetail',
        //   key: this.$utils.newsCategory.video,
        //   totalCount: ''
        // },

        // {
        //   label: '留学论坛',
        //   icon: require('@/assets/home/liuxueluntan.png'),
        //   iconBai: require('@/assets/home/liuxueluntan-bai.png'),
        //   route: 'https://www.goodliuxue.com/t/coronavirus?from=coronavirus-update',
        //   totalCount: ''
        // }

        // {
        //   label: '热门问答',
        //   icon: require('@/assets/home/remenwenda.png'),
        //   iconBai: require('@/assets/home/remenwenda-bai.png'),
        //   route: 'hotAsk',
        //   key: '常见问答-Q&A',
        //   totalCount: ''
        // }
      ]
    };
  },
  computed: {
    ...mapState({
      newsCount: state => state.searchArea.newsCount
    })
  },
  created() {
    this.bannerHeight = 80 + 80;
    this.tabHeight = 60;
  },
  mounted() {
    this.getData();
    this.getAllData();
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    ...mapMutations({
      setNewsCount: 'SET_NEWS_COUNT'
    }),
    async getAllData() {
      const res = await this.$api['home/newsAllCount']();
      let num = 0;
      if (res.message) {
        const allNode = this.$utils.newsCategory.all[1];

        this.tabData.forEach((item, index) => {
          if (index === 0) {
            Object.keys(res.message).forEach(key => {
              if (allNode.includes(key)) {
                num += res.message[key];
              }
            });
            this.tabData[0].totalCount = num;
          } else {
            this.tabData[index].totalCount = res.message[item.key];
          }
        });
      }
    },
    async getData() {
      const allNode = this.$utils.newsCategory.all[1];
      const res = await this.$api['home/newsCount']();
      let num = 0;
      if (res.message) {
        Object.keys(res.message).forEach(item => {
          if (allNode.includes(item)) {
            num += res.message[item];
          }
        });
        this.setNewsCount({
          ...res.message,
          all: num
        });
      }
    },
    goToPages(item) {
      if (item.route.includes('http')) {
        if (item.label === '留学论坛') {
          this.track();
        }
        window.open(item.route);
        return;
      }
      this.$router.push(item.route);
    },
    onScroll() {
      // // 获取所有锚点元素
      // const domAme = document.getElementById('Ame')
      // const domFang = document.getElementById('Fang')
      // const domAsk = document.getElementById('Ask')
      // const domMore = document.getElementById('More')
      // // 所有锚点元素的 offsetTop
      // const offsetTopArr = []
      // offsetTopArr.push(domAme.offsetTop)
      // offsetTopArr.push(domFang.offsetTop)
      // offsetTopArr.push(domAsk.offsetTop)
      // offsetTopArr.push(domMore.offsetTop)

      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.refCon');
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });

      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop > this.bannerHeight) {
        this.tabFix = true;
      } else {
        this.tabFix = false;
      }
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= offsetTopArr[n] - this.tabHeight - 50) {
          navIndex = n;
        }
      }
      // 把下标赋值给 vue 的 data
      this.active = navIndex;
    },
    track() {
      this.$gtag.event('go_coronavirus_update_forum', {
        event_category: 'CoronavirusUpdateForum',
        event_label: '留学论坛'
      });
    }
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll);
  }
};
</script>

<style scoped lang="less">
.tab {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  li {
    flex: 1;

    position: relative;
    font-weight: bold;
    line-height: 30px;
    list-style-type: none;

    div {
      margin-top: 10px;
      height: 85px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    img {
      width: 35px;
      margin-bottom: 5px;
    }
    .totalCount {
      position: absolute;
      bottom: 0;
      color: #999;
      font-size: 10px;
      font-weight: 400;
    }
    .label {
      font-size: 12px;
      line-height: 20px;
      position: relative;
      .tag {
        position: absolute;
        border-radius: 7px;
        right: -20px;
        bottom: 15px;
        font-size: 10px;
      }
    }

    .tips {
      position: absolute;
      top: 0;
    }
  }
}
.tabFix {
  background: #4169e2;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  li {
    color: #fff442;
    div {
      margin-top: 0;
      height: 60px;
      justify-content: center;
    }
    img {
      width: 22px;
    }
    .label {
      color: #fff;
      font-size: 12px;
      line-height: 14px;
      position: relative;
      .tag {
        position: absolute;
        border-radius: 7px;
        right: -20px;
        bottom: 15px;
        font-size: 10px;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .tab li .label {
    font-size: 12px;
  }
}
</style>
