<template>
  <div v-if="imgObj.imgUrl" class="imgWrap">
    <a :href="imgObj.imgUrl" target="_blank">
      <img :src="imgObj.img" alt="" class="img" />
    </a>
    <div class="ad-tag">广告</div>
  </div>
  <div class="adItem" v-else-if="adData.ncov_ad_slides.length">
    <van-swipe class="my-swipe" :autoplay="3000" ref="vanSwipe" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in adData.ncov_ad_slides" :key="index">
        <div class="imgWrap">
          <a :href="item.click_url" target="_blank" v-if="!item.content">
            <img :src="item.image_url" alt="" class="img" />
          </a>
          <img v-else :src="item.image_url" alt="" class="img" @click="showModal(item)" />
          <div class="ad-tag">广告</div>
        </div>
      </van-swipe-item>
    </van-swipe>

    <van-popup v-model="isShowModal" closeable position="bottom">
      <div class="modal">
        <p class="title">{{ modalData.subject }}</p>
        <div class="con" v-html="modalData.content">
          <!-- <img src="@/assets/test.jpeg" alt=""> -->
        </div>
        <div class="btn">
          <!-- <van-button @click="goToDetail" class="detail" color="linear-gradient(to right, #f6cd4c, #eb8f3b)">查看更多</van-button> -->
          <van-button @click="goToContact" class="contact" color="linear-gradient(to right, #e76542, #dc3333)">免费咨询</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    adData: {
      type: Object,
      default: () => {}
    },
    imgObj: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      isShowModal: false,

      modalData: {}
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    showModal(item) {
      this.isShowModal = true;

      this.modalData = {
        ...item,
        content: item.content
      };
      this.track(item);
    },
    goToDetail() {
      window.location.href = this.modalData.click_url;
    },
    goToContact() {
      this.$utils.goToLiveChat('ad');
    },
    track(item) {
      this.$gtag.event('click_coronavirus_update_ad', {
        event_category: 'CoronavirusUpdateAd',
        event_label: `${this.adData.name}：：${this.adData.place_name}`
      });
      this.$gtag.event('show_coronavirus_update_ad', {
        event_category: 'CoronavirusUpdateAd',
        event_label: item.slide_name
      });
    }
  }
};
</script>

<style scoped lang="less">
.adItem {
}
.imgWrap {
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
  .ad-tag {
    position: absolute;
    width: 50px;
    height: 30px;
    right: 0;
    top: 0;
    background: red;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-weight: bold;
  }
}
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  p {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
    max-width: 80%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .con {
    flex: 1;
    overflow: auto;
    padding: 0 30px;
    text-align: left;
    /deep/ img {
      width: 90% !important;
      position: relative;
      left: 5%;
    }
  }
  .btn {
    margin: 15px 0 20px;
    button {
      width: 150px;
      height: 44px;
      &.detail {
        border-top-left-radius: 22px;
        border-bottom-left-radius: 22px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &.contact {
        border-top-left-radius: 22px;
        border-bottom-left-radius: 22px;
        border-top-right-radius: 22px;
        border-bottom-right-radius: 22px;
      }
    }
  }
}

/deep/ .van-popup {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 80%;
}
</style>
