import Search from '@/views/searchArea/Search';
import Letter from '@/views/searchArea/Letter';
import Report from '@/views/searchArea/Report';
import ReportDetail from '@/views/searchArea/ReportDetail';
import Practice from '@/views/searchArea/Practice';
import PracticeDetail from '@/views/searchArea/PracticeDetail';
import Embassy from '@/views/searchArea/Embassy';
import Vaccine from '@/views/searchArea/Vaccine';
// import OfficialWebsite from '@/views/searchArea/OfficialWebsite/index'

export default [
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: { title: '搜索' }
  },
  {
    path: '/letter',
    name: 'Letter',
    component: Letter,
    meta: { title: '一封信' }
  },
  {
    path: '/report',
    name: 'report',
    component: Report,
    meta: { title: '留学日报' }
  },
  {
    path: '/reportDetail',
    name: 'reportDetail',
    component: ReportDetail,
    meta: { title: '留学日报详情' }
  },
  {
    path: '/practice',
    name: 'practice',
    component: Practice,
    meta: { title: '实习项目' }
  },
  {
    path: '/practiceDetail',
    name: 'practiceDetail',
    component: PracticeDetail,
    meta: { title: '实习项目详情' }
  },
  {
    path: '/embassy',
    name: 'embassy',
    component: Embassy,
    meta: { title: '使馆信息' }
  },
  {
    path: '/vaccine',
    name: 'vaccine',
    component: Vaccine,
    meta: { title: '疫苗进展' }
  },
  {
    path: '/officialWebsite',
    name: 'officialWebsite',
    component: () => import('@/views/searchArea/OfficialWebsite'),
    meta: { title: '厚仁官网' }
  },
  // {
  //   path: '/offer',
  //   name: 'offer',
  //   component: () => import('@/views/searchArea/Offer'),
  //   meta: { title: 'Offer来了' }
  // },
  {
    path: '/site',
    name: 'site',
    component: () => import('@/views/searchArea/Site'),
    meta: { title: '厚仁全服务' }
  },
  {
    path: '/relation',
    name: 'relation',
    component: () => import('@/views/searchArea/Relation'),
    meta: { title: '中美厚仁关系说明' }
  }
];
