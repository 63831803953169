/** @format */

// import api from '@/plugins/api'

export default {
  state: {
    practiceTab1: [],
    practiceTab2: [],
    practiceTab3: [],
    practiceTab4: [],
    newsCount: {}

  },
  mutations: {
    // 设置用户信息
    SET_PRACTICE_TAB1: (state, data) => {
      state.practiceTab1 = data
    },
    SET_PRACTICE_TAB2: (state, data) => {
      state.practiceTab2 = data
    },
    SET_PRACTICE_TAB3: (state, data) => {
      state.practiceTab3 = data
    },
    SET_PRACTICE_TAB4: (state, data) => {
      state.practiceTab4 = data
    },
    SET_NEWS_COUNT: (state, data) => {
      state.newsCount = data
    }

  },
  actions: {

  }
}
