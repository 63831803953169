<template>
  <div class="visa-item">
    <div class="item-title">{{ item.title }}</div>
    <div class="item-right">
      <div class="label-wrap">
        <span class="label">硕博学生</span>
        <span class="label-value" v-if="item.grad">{{ item.grad | moment('YYYY年MM月DD日') }}</span>
        <span class="label-label" v-else>暂无可选预约</span>
      </div>
      <div class="label-wrap">
        <span class="label">其他学生</span>
        <span class="label-value" v-if="item.time">{{ item.time | moment('YYYY年MM月DD日') }}</span>
        <span class="label-label" v-else>暂无可选预约</span>
      </div>

      <!-- <div class="item-label" v-if="item.time">{{ item.time | moment('YYYY年MM月DD日') }}</div>
      <div class="item-label" v-else>暂无可选预约</div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {}
};
</script>

<style scoped lang="less">
.visa-item {
  border-radius: 6px;
  width: 100%;
  display: flex;

  .item-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 50px;
    background: @primary-color;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #fff;
    font-weight: bold;
  }
  .item-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    padding: 0 10px;
    .label-wrap {
      flex: 1;
      padding-left: 10px;
      width: calc(100% - 10px);
      text-align: left;
      line-height: 25px;
      border-bottom: 1px solid #f1f1f1;
      &:nth-child(2) {
        border: none;
      }
      .label {
        color: @primary-color;
        margin-right: 5px;
      }
    }
  }
}
</style>
