<template>
  <div class="search">
    <van-nav-bar
      fixed
      title="搜索"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="searchFixWrap">
      <a-input-search
        placeholder="搜索校园疫情"
        @search="onSearch"
        enter-button="搜索"
      />
      <div class="tags">
        <!-- <div class="tagItem" :class="{activeTag:activeTag===0}" @click="clickTag()">全部</div> -->
        <div class="tagItem" :class="{activeTag:activeTag===index}" v-for="(item,index) in hotSearchTag" :key="item" @click="clickTag(item,index)">{{ item }}</div>
      </div>
    </div>

    <a-timeline v-if="isShow" class="timeLine">
      <flow-list :url="url" :params="params">
        <template slot-scope="row">
          <div>
            <a-timeline-item>
              <div slot="dot" class="dot">
                <div></div>
              </div>
              <news-item :item-data="row.itemData" :search="params.search"></news-item>
            </a-timeline-item>
            <a-timeline-item v-if="row.myIndex===1">
              <div slot="dot" class="dot">
                <div></div>
              </div>
              <news-item :item-data="newsAd[0]"></news-item>
            </a-timeline-item>
            <a-timeline-item v-if="row.myIndex===6">
              <div slot="dot" class="dot">
                <div></div>
              </div>
              <news-item :item-data="newsAd[1]"></news-item>
            </a-timeline-item>
          </div>
        </template>
      </flow-list>
    </a-timeline>
  </div>
</template>

<script>
import { FlowList, NewsItem } from '@/components'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    FlowList, NewsItem
  },
  props: {

  },
  data () {
    return {
      isShow: true,
      url: 'home/search',
      activeTag: -1,
      params: {
        limit_start: 0,
        limit_page_length: 10,
        search: '',
        doctype: 'nCov Node',
        filters: { node_category: this.$utils.newsCategory.news }
      }
    }
  },
  computed: {
    ...mapState({
      newsAd: state => state.app.newsAd
    }),
    ...mapGetters(['hotSearch']),
    hotSearchTag () {
      if (this.hotSearch.block_execcode) {
        return this.hotSearch.block_execcode.split(',')
      } else {
        return []
      }
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.search = true
      }

      console.log('fromvm stae', from, vm)
    })
  },
  methods: {
    onSearch (value) {
      value = value.replace(/(^\s*)|(\s*$)/g, '')
      this.params.limit_start = 0
      this.isShow = false
      this.activeTag = -1
      if (value) {
        console.log(value, 'value')
        this.params.search = value
        this.url = 'home/search'
        this.track(value)
      } else {
        this.params.search = ''
        this.url = 'home/search'
      }
      this.$nextTick(() => {
        this.isShow = true
      })
    },
    clickTag (name, index) {
      console.log('name,', name)
      this.params.limit_start = 0
      this.isShow = false
      this.activeTag = index
      if (name) {
        this.params.search = name
        this.url = 'home/search'
        this.track(name)
      } else {
        this.params.search = ''
        this.url = 'home/news'
      }
      this.$nextTick(() => {
        this.isShow = true
      })
    },

    onClickLeft () {
      console.log(this.$router)
      if (this.search) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    },
    track (keyword) {
      if (keyword) {
        this.$gtag.event('search_coronavirus_update_keyword', {
          event_category: 'CoronavirusUpdateSearch',
          event_label: keyword
        })
      }
    }
  }
}
</script>

<style scoped lang="less">

.search{
    padding-top:154px;

    .searchFixWrap{
        position:fixed;
        top:46px;
        left:0;
        right:0;
        padding: 16px;
        background:#fff;
        z-index:2

    }
    .tags{
        display:flex;
        flex-wrap:wrap;
        justify-content: flex-start;
        align-items:center;
        margin-top:10px;
        .tagItem{
            width:fit-content;
            background:#f1f1f1;
            margin-right:8px;
            margin-bottom:10px;
            padding:3px 5px;
            font-size:12px;
            border-radius:4px;
            &.activeTag{
                background:@primary-color;
                color:#fff
        }
        }
  }
    .timeLine{
        margin-top:10px;
        padding:0 16px;
    }
}

.dot{
  width:14px;
  height:14px;
  border-radius:7px;
  background:#c7d4f7;
  display:flex;
  justify-content: center;
  align-items: center;
  div{
    width:6px;
    height:6px;
    border-radius:3px;
    background:#3f69e2;
  }
}
/deep/ .van-pull-refresh{
  overflow:visible
}
/deep/ .ant-timeline-item-tail{
  border-left-color:#c7d4f7
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail{
  display:block
}

</style>
