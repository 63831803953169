<template>
  <div class="">
    <a-input-search
      placeholder="搜索您感兴趣的州名称"
      @search="onSearch"
      enter-button="搜索"
    />
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    onSearch (value) {
      this.$emit('search', value)
    }
  }
}
</script>

<style scoped lang="less">

</style>
