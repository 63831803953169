<template>
  <div class="more" id="More">
    <div v-if="group" class="group">
      <h2 class="title">加入讨论群</h2>
      <div v-html="group.block_content_list[0].block_content"></div>
    </div>
    <!-- <div v-if="more" class="message">
      <h2 class="title" style="padding-top:17px;">更多信息</h2>
      <div v-html="more.block_content_list[0].block_content"></div>
    </div> -->
    <div v-if="banquan" class="banquan">
      <p class="banquan">版权说明</p>
      <div v-html="banquan.block_content_list[0].block_content"></div>
    </div>
    <ad-item v-if="ad['NCOV-AD-PL-00003']" :ad-data="ad['NCOV-AD-PL-00003']" style="margin-bottom:20px"></ad-item>
    <!-- <div class="recommend">
      <h2 class="title">推荐阅读</h2>
      <div v-for="(item,index) in recommendImg" :key="index" class="imgList">
        <a :href="item.href" target="_blank"><img :src="item.url" alt=""></a>

      </div>

    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdItem from '@/components/AdItem'
export default {
  components: { AdItem },
  props: {

  },
  data () {
    return {

      recommendData: []

    }
  },
  computed: {
    ...mapGetters(['group', 'more', 'banquan', 'ad']),
    recommendImg () {
      return this.recommendData.map(item => {
        return {
          href: item.original_url,
          url: item.attach_image_url || `${item.attach_image}`
        }
      })
    }
  },
  watch: {},
  created () {
    this.params = {
      limit_start: 0,
      limit_page_length: 20,
      filters: { node_category: this.$utils.newsCategory.recommend }
    }
    this.getNews()
  },
  mounted () {},
  methods: {

    getNews () {
      this.$api['home/news'](this.params)
        .then(res => {
          console.log(res, 'recommend')
          if (res.message) {
            this.recommendData = res.message
          }
        })
        .catch(err => {
          console.log('err', err)
        })
    }

  }
}
</script>

<style scoped lang="less">

.title {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
  text-align: left;
}
.group{
  padding:0 16px;
  margin-top:25px;
  strong{
    color: #44c477
  }

  /deep/ img{
    width:100%;
  }

}
.message{
  padding:0 16px;
}
.banquan{
  padding:0 16px;
  div{
   /deep/ span{
     display:block;
      text-align:left;
    }
  }
}

.recommend{
  padding:0 16px;
  .imgList{
    margin-bottom:15px;
    img{
    width:100%
  }
  }

}

</style>
