/** @format */

export default {
  user: state => state.user.user,
  banner: state => {
    const block = state.app.block
    return block.filter(item => {
      return item.block_title === '顶部图片'
    })[0]
  },
  group: state => {
    const block = state.app.block

    return block.filter(item => {
      return item.block_title === '加入讨论群'
    })[0]
  },
  more: state => {
    const block = state.app.block
    return block.filter(item => {
      return item.block_title === '更多信息'
    })[0]
  },
  banquan: state => {
    const block = state.app.block
    return block.filter(item => {
      return item.block_title === '版权说明'
    })[0]
  },
  shareData: state => {
    const block = state.app.block
    const fil = block.filter(item => {
      return item.block_title === '分享图片'
    })[0]
    if (fil) {
      return fil.block_content_list[0]
    } else {
      return {}
    }
  },
  shareGroup: state => {
    const block = state.app.block
    const fil = block.filter(item => {
      return item.block_title === '底部加入讨论群按钮'
    })[0]
    if (fil) {
      return fil.block_content_list[0]
    } else {
      return {}
    }
  },
  shareAbout: state => {
    const block = state.app.block
    const fil = block.filter(item => {
      return item.block_title === '底部关于按钮'
    })[0]
    if (fil) {
      return fil.block_content_list[0]
    } else {
      return {}
    }
  },
  hotSearch: state => {
    const block = state.app.block
    const fil = block.filter(item => {
      return item.block_title === '热搜'
    })[0]
    if (fil) {
      return fil.block_content_list[0]
    } else {
      return {}
    }
  },
  loginId: state => state.user.loginId,
  stateData: state => state.app.stateData,
  schoolData: state => state.app.schoolData,
  orderState: state => state.app.orderState,
  orderSchool: state => state.app.orderSchool,
  orderAuthor: state => state.app.orderAuthor,
  authorData: state => state.app.authorData,
  ad: state => state.app.ad
}
