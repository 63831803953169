import Vue from 'vue'
import Router from 'vue-router'
import home from './home'
import state from './state'
import school from './school'
import auth from './auth'
import searchArea from './searchArea'
import {
  routerBeforeEachFunc,
  routerAfterEachFunc
} from '@/config/router'

Vue.use(Router)

const routerInstance = new Router({
  mode: 'hash',

  routes: [
    ...home, ...state, ...school, ...auth, ...searchArea
  ]
})

// 注入拦截器
routerInstance.beforeEach(routerBeforeEachFunc)
routerInstance.afterEach(routerAfterEachFunc)
export default routerInstance
