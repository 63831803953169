/** @format */

export default [
  {
    name: 'news',
    method: 'GET',
    desc: '校园疫情新闻',
    path: '/api/method/soa.ncov.api.coronavirus_news'
  },
  {
    name: 'map',
    method: 'GET',
    desc: '地图',
    path: '/api/method/soa.ncov.api.coronavirus_state'
  },
  {
    name: 'measure',
    method: 'GET',
    desc: '校园防疫措施',
    path: '/api/method/soa.ncov.api.coronavirus_school'
  },
  {
    name: 'count',
    method: 'GET',
    desc: '人数统计',
    path: '/api/method/soa.ncov.api.coronavirus_count'
  },
  {
    name: 'block',
    method: 'GET',
    desc: '页面block',
    path: '/api/method/soa.ncov.api.coronavirus_block_content'
  },
  {
    name: 'blockAll',
    method: 'GET',
    desc: '页面block',
    path: '/api/method/soa.ncov.api.coronavirus_block_code'
  },
  {
    name: 'pageCount',
    method: 'GET',
    desc: '页面浏览量',
    path: '/api/method/soa.ncov.api.pageviews_count'
  },
  {
    name: 'askTags',
    method: 'GET',
    desc: '热门问答的tag',
    path: '/api/method/soa.ncov.api.coronavirus_news_tags'
  },
  {
    name: 'mapLine',
    method: 'GET',
    desc: '折线图数据',
    path: '/api/method/soa.ncov.api.coronavirus_timeline'
  },
  {
    name: 'ad',
    method: 'GET',
    desc: '广告',
    path: '/api/method/soa.ncov.api.coronavirus_ad'
  },
  {
    name: 'newsAd',
    method: 'GET',
    desc: '信息流广告',
    path: '/api/method/soa.ncov.api.coronavirus_news_ads'
  },
  {
    name: 'search',
    method: 'GET',
    desc: '搜索',
    path: '/api/method/soa.ncov.api.coronavirus_wsearch'
  },

  {
    name: 'pie',
    method: 'GET',
    desc: '首页饼图',
    path: '/api/method/soa.ncov.api.coronavirus_chart'
  },
  {
    name: 'newsCount',
    method: 'GET',
    desc: '新增新闻数量',
    path: '/api/method/soa.ncov.api.coronavirus_news_addcount'
  },
  {
    name: 'newsAllCount',
    method: 'GET',
    desc: '新闻数量',
    path: '/api/method/soa.ncov.api.coronavirus_news_count'
  },
  {
    name: 'author',
    method: 'GET',
    desc: '老师专栏',
    path: '/api/method/soa.ncov.api.coronavirus_insight_author'
  },
  {
    name: 'authorPost',
    method: 'GET',
    desc: '老师专栏文章',
    path: '/api/method/soa.ncov.api.coronavirus_insight_posts'
  },
  {
    name: 'authorCategory',
    method: 'GET',
    desc: '老师分类',
    path: '/api/method/soa.ncov.api.coronavirus_author_groups'
  },
  {
    name: 'blogCategory',
    method: 'GET',
    desc: '文章分类',
    path: '/api/method/soa.ncov.api.coronavirus_insight_category'
  },
  {
    name: 'blogDetailSummary',
    method: 'GET',
    desc: '文章详情其他',
    path: '/api/method/soa.ncov.api.coronavirus_insight_postmisc'
  },
  {
    name: 'visa',
    method: 'GET',
    desc: '文章分类',
    path: '/api/method/soa.ncov.api.coronavirus_visa'
  },
  {
    name: 'site',
    method: 'GET',
    desc: '首页全服务',
    path: '/api/method/soa.ncov.api.coronavirus_wr_service'
  }
];
