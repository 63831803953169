<!-- @format -->

<!--@format
 by: wk 2018/12/18
 name: 页面组件——首页地图
 备注:
-->
<template>
  <div class="mapWrap">
    <!-- <top-header :padding="true" title="全美校园疫情地图" v-if="!isShareDom"></top-header> -->
    <div class="btnWrap" v-if="!isShareDom">
      <van-tag class="btnTag" :class="{ active: active === 2 }" type="primary" @click="handleMap(2)">风险评级</van-tag>
      <van-tag class="btnTag" :class="{ active: active === 0 }" type="primary" @click="handleMap(0)">确诊数据</van-tag>
      <van-tag class="btnTag" :class="{ active: active === 1 }" type="primary" @click="handleMap(1)">复工状态</van-tag>
    </div>

    <div :id="domId" :class="isShareDom ? 'shareDomMap' : 'map'"></div>
    <img alt="" v-if="active === 0" class="img" src="@/assets/mapDuring.png" />
    <img alt="" v-if="active === 1" class="img" src="@/assets/mapDuring_1.png" />
    <img alt="" v-if="active === 2" class="img" src="@/assets/mapDuring_2.png" />
    <p class="tag" v-if="!isShareDom">{{ tips }}</p>
    <!-- <p class="tips" v-if="!isShareDom">{{ tips }}</p> -->
  </div>
</template>

<script>
import usa from '@/assets/usa.json';
import { Toast } from 'vant';
import { TopHeader } from '@/components';
import { mapMutations } from 'vuex';
const echarts = require('echarts/lib/echarts');

require('echarts/lib/chart/map');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/dataZoom');
require('echarts/lib/component/visualMap');

require('echarts/lib/component/title');

export default {
  components: { TopHeader },

  props: {
    isShareDom: {
      default: false,
      type: Boolean
    },
    domId: {
      default: 'container',
      type: String
    }
  },

  data() {
    return {
      schoolData: [],
      tips: '在地图上点击感兴趣的州，查看学校疫情和措施详情',
      active: 2
    };
  },

  computed: {},

  created() {
    this._getSchoolData();
  },

  mounted() {
    const that = this;
    window.goToState = function(name, shortName) {
      that.$router.push({
        path: '/state', // 需要这样跳转
        query: {
          name: name,
          short_name: shortName
        }
      });
    };
    this.$bus.$on('dataFrom', tips => {
      this.tips = tips;
    });
  },

  methods: {
    ...mapMutations({
      setStateList: 'SET_STATE_LIST'
    }),
    goToState(data) {
      console.log(data);
    },
    handleMap(index) {
      this.active = index;
      if (index === 0) {
        this.tips = '在地图上点击感兴趣的州，查看学校疫情和措施详情';

        this._drawMap();
      } else if (index === 1) {
        this.tips = '在地图上点击感兴趣的州，查看各州复工详情';
        this._drawMapStatus();
      } else {
        this.tips = '每10万人新增确诊<1为安全，1-9为社区传播，10-24为传播加速，>25为危险';
        this._drawMapStatus();
      }
    },
    /**
     * @description: 拿到map学校数据
     * @param {type}
     * @return:
     */
    async _getSchoolData() {
      this.$api['home/map']({
        with_school: 1,
        with_school_length: 10,
        fields:
          'reopen_status,cn_state,state,name,short_name,new_case_status,ncov_confirmed,ncov_recovered,ncov_deaths,ncov_increase_confirmed,ncov_increase_recovered,ncov_increase_deaths,ncov_risk_level,vaccinated_current_population,ncov_vaccinations,vaccinated_all_populations',
        with_school_fields: 'school_cn,name,school_state_short,ncov_confirmed'
      })
        .then(res => {
          console.log(res, 'maper');
          if (res.message) {
            this.schoolData = res.message;
            this.setStateList(res.message);
            // this._drawMap();
            this.handleMap(2);
          }
        })
        .catch(err => {
          console.log(err);
          Toast('服务器开小差了，请稍后再试');
        });
    },

    /**
     * @description: 绘制地图
     */
    _drawMap() {
      var dom = document.getElementById(this.domId);
      var myChart = echarts.init(dom);
      myChart.showLoading();
      var mapData = this.schoolData.map(item => {
        return {
          value: item.ncov_confirmed,
          name: item.state,
          short_name: item.short_name,
          school: item.school.map(sitem => {
            return {
              name: sitem.school_cn,
              value: sitem.ncov_confirmed
            };
          })
        };
      });
      console.log(mapData, 'mapData');
      echarts.registerMap('USA', usa, {
        Alaska: {
          // 把阿拉斯加移到美国主大陆左下方
          left: -131,
          top: 25,
          width: 15
        },
        Hawaii: {
          left: -110, // 夏威夷
          top: 28,
          width: 5
        },
        'Puerto Rico': {
          // 波多黎各
          left: -76,
          top: 26,
          width: 2
        }
      });
      myChart.hideLoading();
      var option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'click',
          enterable: true,
          extraCssText: 'text-align:left;z-index:9',
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: params => {
            console.log(params);
            const data = params.data;

            let html = '';
            if (data) {
              html += `<span style="color:#fff442;font-size:bold;">${data.name}: ${data.value} <br/></span>`;

              let num = 0;
              data.school.forEach(item => {
                if (item.value) {
                  num += 1;

                  html += ` <span style='font-weight:bold;'>${item.name} : <span style='color:#d56255;'>${item.value}</span></span><br/> `;
                }
              });
              data.school.forEach(item => {
                if (!item.value) {
                  if (num <= 6) {
                    num += 1;
                    html += item.name + ':' + item.value + '<br/>';
                  }
                }
              });
              if (data.school.length > 6) {
                html += '<p style="color:#fddd31">该州其他学校暂无确诊</p>';
              }
            }

            html += `<div onclick='goToState("${data.name}", "${data.short_name}")' style="background:#4169e2;color:#fff442;display:flex;justify-content:center;align-items:center;width:100%;height:30px;">查看详情>></div>`;

            return html;
          }
        },
        layoutCenter: ['50%', '50%'],
        // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
        layoutSize: '100%',

        visualMap: {
          type: 'piecewise',

          show: false,
          pieces: [
            // {
            //   value: 0,
            //   label: '0',
            //   color: '#fff'
            // },
            {
              gt: 1,
              lte: 100000,
              label: '',
              color: '#faebd2'
            },
            {
              gt: 100000,
              lte: 300000,
              label: '',
              color: '#f5c0af'
            },
            {
              gt: 300000,
              lte: 500000,
              label: '',
              color: '#f69677'
            },
            // {
            //   gt: 25000,
            //   lte: 50000,
            //   label: '',
            //   color: '#D66256'
            // },
            {
              gt: 500000,
              lte: 1000000,
              label: '',
              color: '#e76251'
            },

            {
              gt: 1000000,
              color: '#a12f2f',
              label: ''
            }
          ],

          text: ['High', 'Low'], // 文本，默认为数值文本
          calculable: true,
          formatter: function(value, value2) {
            return 'aaaa' + value + 'bbbb' + value2; // 范围标签显示内容。
          }
        },

        series: [
          {
            name: 'USA PopEstimates',
            type: 'map',
            label: {
              show: true,
              fontSize: 8,

              formatter: function(params) {
                let html = '';
                if (params.data) {
                  html = params.data.short_name;
                  if (params.data.value >= 50000) {
                    return '{a|' + params.data.short_name + '}';
                  }
                }

                return html; // 范围标签显示内容。
              },
              rich: {
                a: {
                  color: '#fff',
                  fontSize: 8
                }
              }
            },
            roam: true,
            scaleLimit: {
              min: 1,
              max: 20
            },
            map: 'USA',
            emphasis: {
              label: {
                show: true
              }
            },
            // 文本位置修正
            textFixed: {
              Alaska: [20, -20]
            },
            data: mapData
          }
        ]
      };
      myChart.setOption(option);
    },
    /**
     * @description: 绘制地图
     */
    _drawMapStatus() {
      var dom = document.getElementById(this.domId);
      var myChart = echarts.init(dom);
      myChart.showLoading();
      const stragety1 = {
        已复工: '#02A7F0',
        逐步复工: '#81D3F8',
        暂缓复工: '#D7D7D7',
        再次隔离: '#F4B4BB'
      };
      const stragety2 = {
        安全: '#56d184',
        社区传播: '#fbef6a',
        传播加速: '#fcba52',
        危险: '#f97c7c'
      };
      var mapData = this.schoolData.map(item => {
        return {
          name: item.state,
          short_name: item.short_name,
          value: this.active === 1 ? item.reopen_status : item.ncov_risk_level,
          itemStyle: {
            areaColor: this.active === 1 ? stragety1[item.reopen_status] : stragety2[item.ncov_risk_level]
          }
        };
      });
      console.log(mapData, 'mapData');
      echarts.registerMap('USA', usa, {
        Alaska: {
          // 把阿拉斯加移到美国主大陆左下方
          left: -131,
          top: 25,
          width: 15
        },
        Hawaii: {
          left: -110, // 夏威夷
          top: 28,
          width: 5
        },
        'Puerto Rico': {
          // 波多黎各
          left: -76,
          top: 26,
          width: 2
        }
      });
      myChart.hideLoading();
      var option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'click',
          enterable: true,
          extraCssText: 'text-align:left;z-index:9',
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: params => {
            const data = params.data;
            let html = `<span style="color:#fff;font-size:bold;">${data.name}: ${data.value} <br/></span>`;

            html += `<div onclick='goToState("${data.name}", "${data.short_name}")' style=" margin-top:10px;background:#4169e2;color:#fff442;display:flex;justify-content:center;align-items:center;width:100%;height:30px;">查看详情>></div>`;
            return html; // 范围标签显示内容。
          }
        },

        layoutCenter: ['50%', '50%'],
        // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
        layoutSize: '100%',

        series: [
          {
            name: 'USA PopEstimates',
            type: 'map',

            label: {
              show: true,
              fontSize: 8,

              formatter: function(params) {
                let html = '';
                if (params.data) {
                  html = params.data.short_name;
                  if (params.data.value >= 1000) {
                    return '{a|' + params.data.short_name + '}';
                  }
                }

                return html; // 范围标签显示内容。
              },
              rich: {
                a: {
                  color: '#fff',
                  fontSize: 8
                }
              }
            },
            roam: true,
            scaleLimit: {
              min: 1,
              max: 20
            },
            map: 'USA',
            emphasis: {
              label: {
                show: true
              },
              itemStyle: {
                areaColor: '#fc8300'
              }
            },
            // 文本位置修正
            textFixed: {
              Alaska: [20, -20]
            },
            data: mapData
          }
        ]
      };
      myChart.setOption(option);
    }
  }
};
</script>
<style lang="less" scoped>
.mapWrap {
  background: #f3f3f3;
}

.tag {
  font-size: 13px;
  width: 100%;
  text-align: left;
  color: #666;
  background: #f3f3f3;
  padding: 10px 16px 20px;
  margin: 0;
}
.shareDomMap {
  width: 90vw;

  height: 30vh;
  @media screen and (min-width: 768px) {
    width: 355px;
  }
}
.map {
  width: 100vw;
  height: 35vh;
  background: #f3f3f3;
  @media screen and (min-width: 768px) {
    width: 768px;
  }
}
.img {
  width: 100%;
}
.tips {
  font-size: 12px;
  margin: 10px 0 0 16px;
}
.btnWrap {
  display: flex;
  margin-bottom: 10px;
  margin-left: 16px;
  justify-content: flex-start;
  align-items: center;
  background: #f3f3f3;
  position: relative;
  top: 15px;
  z-index: 2;
  .btnTag {
    margin-right: 15px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 15px;
    background: #fff;
    color: #666;
    border: 1px solid #ccc;
    &.active {
      background: @primary-color;
      border: none;
      color: #fff;
    }
  }
}
</style>
