/** @format */

import api from '@/plugins/api';

export default {
  state: {
    detailSummary: {}
  },
  mutations: {
    // 设置用户信息
    SET_DETAIL_SUMMARY: (state, data) => {
      state.detailSummary = data;
    }
  },
  actions: {
    /**
     * @msg:拿到用户信息
     * @param {type}
     * @return: promise
     */
    GetDetailSummary({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        api['home/blogDetailSummary'](params)
          .then(res => {
            console.log(res, 'wechat');
            if (!res.message) {
              reject(new Error('error'));
            }

            commit('SET_DETAIL_SUMMARY', res.message);

            resolve(res.message);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
