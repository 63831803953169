<template>
  <div class="schoolWrap">
    <van-nav-bar fixed :title="title" left-text="返回" left-arrow @click-left="onClickLeft"> </van-nav-bar>
    <Pie from="school" v-show="false"></Pie>
    <div style="padding:10px 0">
      <Search></Search>
    </div>
    <!-- <top-header :padding="true" title="学校列表"></top-header> -->
    <div class="btn-wrap">
      <a-button @click="active = 0" shape="round" :type="active === 0 ? 'primary' : ''">确诊&复学安排</a-button>
      <a-button @click="active = 1" shape="round" :type="active === 1 ? 'primary' : ''">薪资排行榜</a-button>
    </div>
    <div class="img-wrap">
      <img v-if="active === 0" src="@/assets/school/1.jpg" alt="" />
      <img v-if="active === 1" src="@/assets/school/2.jpg" alt="" />
    </div>
    <div class="myTable" id="schoolListTable">
      <div v-show="tableFix" class="tableHeader"></div>
      <div class="tableHeader" :class="{ tableFix: tableFix }" :style="{ top: tabHeight + 'px' }">
        <p class="name">学校名称</p>
        <p class="confirm" @click="handleSort('latest_ranking_2022')">
          排名
          <i class="iconfont icon-sort"></i>
        </p>
        <template v-if="active === 1">
          <p class="money-1" @click="handleSort('early_career_pay')">
            毕业起薪
            <i class="iconfont icon-sort"></i>
          </p>
          <!-- <p class="online">结束</p> -->
          <p class="money-2" @click="handleSort('mid_career_pay')">事业中期年薪 <i class="iconfont icon-sort"></i></p>
        </template>
        <template v-else>
          <p class="course" @click="handleSelect">
            复学安排
            <i class="iconfont icon-sort"></i>
          </p>
          <!-- <p class="online">结束</p> -->
          <p class="detail">确诊数字</p>
        </template>
      </div>
      <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
      <van-list
        :offset="0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="_getSchoolData"
      >
        <div v-for="item in tableData" :key="item.name" class="list-wrap">
          <div class="tableItem">
            <p class="name" @click="goToSchool(item)">
              <span class="detail-text">
                {{ item.school_cn }}
                <van-tag class="tips " v-if="item.news_count">+{{ item.news_count }}</van-tag>
              </span>
            </p>
            <p class="confirm">{{ item.latest_ranking_2022 || 0 }}</p>
            <template v-if="active === 1">
              <p class="money-1">
                <span v-if="item.early_career_pay">${{ item.early_career_pay | NumberQian }}</span>
                <span v-else>--</span>
              </p>
              <!-- <p class="online"><span v-if="item.online_course_end">{{ item.online_course_end | moment('MM/DD') }}</span><span v-else>暂无</span> </p> -->
              <p class="money-2">
                <span v-if="item.mid_career_pay">${{ item.mid_career_pay | NumberQian }}</span> <span v-else>--</span>
              </p>
            </template>
            <template v-else>
              <p class="course">
                <span v-if="item.fall_2020_policy_category">{{ item.fall_2020_policy_category }}</span>
                <span v-else>暂无</span>
                <a-tooltip v-if="item.fall_2020_policy_cn">
                  <template slot="title">{{ item.fall_2020_policy_cn }}</template>
                  <i class="iconfont icon-question"></i>
                </a-tooltip>
              </p>
              <!-- <p class="online"><span v-if="item.online_course_end">{{ item.online_course_end | moment('MM/DD') }}</span><span v-else>暂无</span> </p> -->
              <p class="detail">{{ item.ncov_confirmed || '暂无' }}</p>
            </template>
          </div>
        </div>
      </van-list>
      <!-- </van-pull-refresh> -->
    </div>
    <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" close-on-click-action @cancel="onCancel" @select="onSelect" />
  </div>
</template>

<script>
import { TopHeader, FlowList } from '@/components';
import Pie from '@/views/home/item/Pie';

import Search from '../home/components/Search';
export default {
  name: 'SchoolList',
  components: {
    TopHeader,
    Search,
    FlowList,
    Pie
  },
  props: {},
  data() {
    return {
      title: '重点关注——学校',
      tableFix: false,
      tableData: [],
      sort: {
        latest_ranking_2022: true,
        early_career_pay: true,

        mid_career_pay: true
      },

      loading: false,
      error: false,
      finished: false,

      refreshing: false,

      show: false,
      actions: [
        { name: '全部' },
        { name: '待决定' },
        { name: '计划网课' },
        { name: '面授+网课' },
        { name: '考虑多种计划' },
        { name: '计划面授' }
      ],
      loadMoreType: 'latest_ranking_2022',
      active: 0
    };
  },
  computed: {},
  watch: {},
  created() {
    if (this.$route.query.type === 'money') {
      this.active = 1;
    }
    this.params = {
      limit_start: 0,
      limit_page_length: 20,

      filters: {},
      fields:
        'name,school_cn,news_count,early_career_pay,mid_career_pay,latest_ranking_2022,fall_2020_policy_category,fall_2020_policy_cn,ncov_confirmed',

      order_by: 'isnull(latest_ranking_2022),length(latest_ranking_2022),latest_ranking_2022 asc,ncov_confirmed asc'
    };
    const category = this.$route.query.category;
    if (category) {
      this.params.filters.fall_2020_policy_category = category;
      this.title = `学校——${category}`;
    }
    this.tabHeight = 46;
    this._getSchoolData();
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.addEventListener('scroll', this.onScroll);
  },

  destroyed() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.schoolList = true;
      }

      console.log('fromvm stae', from, vm);
    });
  },
  methods: {
    onScroll() {
      // 获取所有锚点元素
      const tableContent = document.getElementById('schoolListTable');
      // 所有锚点元素的 offsetTop
      const offsetTop = tableContent.offsetTop;
      const offsetHeight = tableContent.offsetHeight;

      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //   console.log(123, scrollTop, offsetTop, offsetHeight)
      if (scrollTop > offsetTop - this.tabHeight) {
        this.tableFix = true;
        if (scrollTop > offsetTop + offsetHeight - this.tabHeight - 40) {
          this.tableFix = false;
        }
      } else {
        this.tableFix = false;
      }
    },
    handleSort(type) {
      this.loadMoreType = type;

      this.sort[type] = !this.sort[type];

      const flag = this.sort[type] ? 1 : 0;

      this.paramsStragety = {
        latest_ranking_2022: {
          1: 'isnull(latest_ranking_2022),length(latest_ranking_2022) asc,latest_ranking_2022 asc',
          0: 'isnull(latest_ranking_2022),length(latest_ranking_2022) desc,latest_ranking_2022 desc'
        },
        early_career_pay: {
          1: 'isnull(early_career_pay) asc,length(early_career_pay) asc,early_career_pay asc',
          0: 'isnull(early_career_pay) asc,length(early_career_pay) desc,early_career_pay desc'
        },

        mid_career_pay: {
          1: 'isnull(mid_career_pay) asc,length(mid_career_pay) asc,mid_career_pay asc',
          0: 'isnull(mid_career_pay) asc,length(mid_career_pay) desc,mid_career_pay desc'
        }
      };

      this.params.order_by = this.paramsStragety[type][flag];
      this.params.limit_start = 0;

      this._getSchoolData();
    },
    handleSelect() {
      this.show = true;
    },
    onCancel() {
      this.show = false;
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.params.limit_start = 0;

      this.params.filters.fall_2020_policy_category = item.name;
      this.refreshing = true;
      this.finished = false;

      if (item.name === '全部') {
        delete this.params.filters.fall_2020_policy_category;
      }
      this._getSchoolData();
    },
    loadMore() {
      this.params.limit_start += this.params.limit_page_length;
      this._getSchoolData();
    },
    _getSchoolData() {
      if (this.params.limit_start === 0) {
        this.tableData = [];
      }
      this.loading = true;
      this.$api['home/measure'](this.params)
        .then(res => {
          if (res.message) {
            if (this.refreshing) {
              this.tableData = [];
              this.refreshing = false;
            }
            if (res.message.length < this.params.limit_page_length) {
              this.finished = true;
            }
            const tableData = [].concat(
              this.tableData,
              res.message.map(item => {
                if (!item[this.loadMoreType] || item[this.loadMoreType] === '9999') {
                  return {
                    ...item,
                    [this.loadMoreType]: '--'
                  };
                } else {
                  return item;
                }
              })
            );

            const uselessData = tableData.filter(item => item[this.loadMoreType] === '--');
            const usefulData = tableData.filter(item => item[this.loadMoreType] !== '--');
            usefulData.sort((a, b) => {
              if (b[this.loadMoreType] === '--') {
                return 1;
              }
              if (this.sort[this.loadMoreType]) {
                return a[this.loadMoreType] - b[this.loadMoreType];
              } else {
                return b[this.loadMoreType] - a[this.loadMoreType];
              }
            });

            this.tableData = [...usefulData, ...uselessData];
            this.params.limit_start += this.params.limit_page_length;

            this.$emit('handleData', this.list);
          }
          this.loading = false;
        })
        .catch(err => {
          console.log('err', err);
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.params.limit_start = 0;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    goToSchool(item) {
      console.log(item);
      this.$router.push({
        path: '/school',
        query: {
          name: item.name,
          state: item.school_state_short
        }
      });
    },
    onClickLeft() {
      console.log(this.$router);
      if (this.schoolList) {
        this.$router.back();
      } else {
        this.$router.push('/');
      }
    }
  }
};
</script>

<style scoped lang="less">
.schoolWrap {
  margin-top: 50px;
}
.red1 {
  color: red;
}
.green1 {
  color: rgb(40, 183, 163);
}
.gray1 {
  color: rgb(93, 112, 146);
}
.btn-wrap {
  padding-left: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  .ant-btn {
    margin-right: 15px;
  }
  .ant-btn-primary {
    font-weight: bold;
  }
}
.img-wrap {
  margin-top: 15px;
  img {
    width: 100%;
    height: auto;
  }
}
.myTable {
  .tableHeader {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    height: 0.8rem;
    &.tableFix {
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      z-index: 10;
    }
    p {
      height: 100%;
      margin: 0;
      font-size: 0.24rem;
      line-height: 0.8rem;
      text-align: center;
      font-weight: bold;
      color: #333;
    }
    .name {
      background: #e3e7f3;
      width: 35%;
    }
    .confirm {
      flex: 1;
      background: #e69a8d;
    }
    .course {
      width: 30%;
      background: #95db9a;
    }
    .online {
      flex: 1;
      background: #b4c0d5;
    }
    .detail {
      width: 20%;
      background: #e3e7f3;
    }
    .money-1 {
      width: 22%;
      background: #95db9a;
    }
    .money-2 {
      width: 25%;
      background: #e3e7f3;
      white-space: nowrap;
    }
  }
  .tableItem {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    border-bottom: 1px solid #e8e8e8;
    min-height: 0.8rem;
    p {
      height: 100%;
      margin: 0;
      font-size: 0.24rem;
      text-align: center;
      font-weight: bold;
      background: #f7f7f7;
    }
    .name {
      width: 35%;
      cursor: pointer;
      .detail-text {
        position: relative;
        right: 10px;
        color: @primary-color;
      }
      .tips {
        position: absolute;
        right: -30px;
        top: 0;
        background: #ecf0fc;
        color: #4169e2;
        margin-left: 10px;
        border-radius: 5px;
      }
    }
    .confirm {
      flex: 1;
    }
    .course {
      // font-weight:normal;
      width: 30%;
    }
    .online {
      font-weight: normal;
      flex: 1;
    }
    .detail {
      width: 20%;
    }
    .money-1 {
      width: 22%;
    }
    .money-2 {
      width: 25%;
      white-space: nowrap;
    }
  }
}
.icon-question {
  color: #fc8300;
  font-size: 12px;
}
.icon-sort {
  font-size: 0.2rem;
}
</style>
