<template>
  <div class="stateMap">
    <div v-if="showMap" :id="domId" :class="isShareDom ? 'shareDomMap' : 'map'" />
    <img alt="" class="img" src="@/assets/stateMapDuring.png" />
  </div>
</template>

<script>
import { TopHeader } from '@/components';
import { mapState, mapMutations } from 'vuex';
const echarts = require('echarts/lib/echarts');

require('echarts/lib/chart/map');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/visualMap');

require('echarts/lib/component/title');
export default {
  name: 'StateMap',
  components: {
    TopHeader
  },
  props: {
    domId: {
      default: 'stateMap',
      type: String
    },
    isShareDom: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      short_name: '',
      showMap: true
    };
  },
  computed: {
    ...mapState({
      stateMapGeo: state => state.shareDom.stateMapGeo,
      stateMapData: state => state.shareDom.stateMapData
    })
  },
  watch: {},
  created() {
    this.url = '';

    this.params = {
      filters: {
        state_name: ''
      }
    };
  },
  mounted() {
    this.initCheck();
  },
  activated() {
    this.showMap = true;
    this.initCheck();
  },
  deactivated() {
    this.showMap = false;
  },
  methods: {
    ...mapMutations({
      setStateMapGeo: 'SET_STATE_MAP_GEO',
      setStateMapData: 'SET_STATE_MAP_DATA'
    }),
    initCheck() {
      this.short_name = this.$route.query.short_name;
      this.params.filters.state_name = this.$route.query.name;
      this.url = `https://erp.wholerengroup.com/res/map/us/${this.short_name}.json`;

      this.getMap();
    },
    getMap() {
      Promise.all([this.$api['state/map'](null, { url: this.url }), this.$api['state/mapData'](this.params)]).then(
        ([res1, res2]) => {
          const mapData = res2.message.map(item => {
            return {
              value: item.ncov_confirmed,
              death: item.ncov_deaths,
              recovered: item.ncov_recovered,
              name: item.county_name,
              short_name: item.short_name
            };
          });
          console.log(res1, res2, 'res1res2');
          this.setStateMapGeo(res1);
          this.setStateMapData(mapData);
          this._drawMap();
        }
      );
    },
    /**
     * @description: 绘制地图
     */
    _drawMap() {
      var dom = document.getElementById(this.domId);
      var myChart = echarts.init(dom);
      console.log(myChart, 'myChart', this.domId);
      myChart.showLoading();

      echarts.registerMap(this.shortName, this.stateMapGeo);
      myChart.hideLoading();
      var option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'click',
          enterable: true,
          extraCssText: 'text-align:left;z-index:9',
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: params => {
            console.log(params);
            const data = params.data;

            let html = '';
            if (data) {
              html += `<span style="color:#fff442;font-size:bold;">${data.name} <br/></span>`;

              html += ` <span style='font-weight:bold;'>确诊 : <span style='color:red;'>${data.value}</span></span><br/> `;
              html += ` <span style='font-weight:bold;'>治愈 : <span style='color:#27b7a3;'>${data.recovered}</span></span><br/> `;
              html += ` <span style='font-weight:bold;'>死亡 : <span style='color:#fff;'>${data.death}</span></span><br/> `;
            }

            return html;
          }
        },
        layoutCenter: ['50%', '50%'],
        // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
        layoutSize: '120%',

        visualMap: {
          type: 'piecewise',

          show: false,
          pieces: [
            {
              value: 0,
              label: '0',
              color: '#fff'
            },
            {
              gt: 1,
              lte: 10,
              label: '',
              color: '#faebd2'
            },
            {
              gt: 10,
              lte: 100,
              label: '',
              color: '#e8a188'
            },
            {
              gt: 100,
              lte: 500,
              label: '',
              color: '#F28B6A'
            },
            {
              gt: 500,
              lte: 1000,
              label: '',
              color: '#D66256'
            },
            {
              gt: 1000,
              lte: 3000,
              label: '',
              color: '#D04937'
            },

            {
              gt: 3000,
              color: '#A12F2F',
              label: ''
            }
          ],

          text: ['High', 'Low'], // 文本，默认为数值文本
          calculable: true
        },

        series: [
          {
            name: 'USA PopEstimates',
            type: 'map',
            label: {
              show: true,
              fontSize: 6,

              formatter: function(params) {
                let html = '';
                if (params.data) {
                  html = params.data.name;
                  if (params.data.value >= 1000) {
                    return '{a|' + params.data.name + '}';
                  }
                }

                return html; // 范围标签显示内容。
              },
              rich: {
                a: {
                  color: '#fff',
                  fontSize: 8
                }
              }
            },
            roam: true,
            scaleLimit: {
              min: 1,
              max: 20
            },
            map: this.shortName,
            emphasis: {
              label: {
                show: false
              }
            },
            // 文本位置修正
            textFixed: {
              Alaska: [20, -20]
            },
            data: this.stateMapData
          }
        ]
      };
      myChart.setOption(option);
    }
  }
};
</script>

<style scoped lang="less">
.shareDomMap {
  width: 90vw;

  height: 30vh;
  @media screen and (min-width: 768px) {
    width: 355px;
  }
}
.map {
  width: 100vw;
  height: 35vh;
  background: #f7f7f7;
  @media screen and (min-width: 768px) {
    width: 768px;
  }
}
.img {
  width: 100%;
}
</style>
