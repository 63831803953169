<template>
  <div id="home">
    <Banner></Banner>
    <Search></Search>
    <Notice></Notice>

    <div class="ct">
      <Tab></Tab>
      <!-- <Sticky :top="-30"></Sticky> -->
    </div>

    <NewsTag></NewsTag>
    <Tips></Tips>
    <AllSite></AllSite>
    <!-- <Visa></Visa> -->
    <MyOrder></MyOrder>
    <div id="scroll">
      <div class="refCon countWrap">
        <Count></Count>
        <Map></Map>
      </div>
      <MapLine></MapLine>
      <Pie v-show="false"></Pie>
      <ad-item :img-obj="imgObj"></ad-item>
      <!-- <a :href="imgUrl" target="_blank" class="report"><img src="@/assets/report.gif"/></a> -->

      <Focus></Focus>

      <ad-item v-if="ad['NCOV-AD-PL-00001']" :ad-data="ad['NCOV-AD-PL-00001']" style="margin-top:20px"></ad-item>
      <!-- <Video></Video> -->
      <News></News>

      <More></More>
    </div>
  </div>
</template>

<script>
import {
  Count,
  Map,
  Video,
  Fang,
  Ask,
  More,
  Tab,
  Banner,
  News,
  Protect,
  Notice,
  Focus,
  MyOrder,
  MapLine,
  Search,
  Pie,
  NewsTag,
  Visa,
  AllSite,
  Tips
} from './item';
import { AdItem, Sticky } from '@/components';
import { mapGetters } from 'vuex';
export default {
  name: 'App',
  components: {
    Count,
    Map,
    Video,
    Fang,
    Ask,
    More,
    Tab,
    Banner,
    News,
    Protect,
    Notice,
    Focus,
    MyOrder,
    MapLine,
    AdItem,
    Sticky,
    Search,
    Pie,
    NewsTag,
    Visa,
    AllSite,
    Tips
  },
  data() {
    return {
      active: 0
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['ad', 'loginId']),
    imgObj() {
      if (this.loginId) {
        return {
          imgUrl: `https://erp.wholerengroup.com/wholeren-service-questionnaire/-7025418009546371403?email=${this.loginId}`,
          img: require('@/assets/report.gif')
        };
      } else {
        return {
          imgUrl: 'https://erp.wholerengroup.com/wholeren-service-questionnaire/-7025418009546371403',
          img: require('@/assets/report.gif')
        };
      }
    }
  },
  methods: {
    handleMap(index) {
      this.active = index;
    }
  }
};
</script>

<style scoped lang="less">
.countWrap {
  position: relative;
  background: #f3f3f3;
  overflow: hidden;
}
.ct {
  position: relative;
}
.report {
  display: block;

  img {
    width: 100%;
  }
}
.school-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 16px;
  padding: 0 10px;
  border: 1px solid #ededed;
  border-radius: 6px;
  box-shadow: 3px 1px 9px rgba(0, 0, 0, 0.2);

  height: 35px;
  font-size: 14px;
  .icon-university {
    color: rgb(174, 33, 44);
    margin-right: 5px;
  }
  span {
    margin-right: 5px;
  }
  .school {
    color: @primary-color;
  }
}
</style>
