import home from './home'
import state from './state'
import auth from './auth'
import searchArea from './searchArea'
import wechat from './wechat'
export default {
  auth,
  home,
  state,
  searchArea,
  wechat
}
