/**
 * 将秒数转为时间格式
 *
 * @format
 * @param {number} second
 */
import _ from 'lodash';
import moment from 'moment-timezone';
import 'moment/locale/zh-cn';
// import * as CustomerSDK from '@livechat/customer-sdk'
moment.locale('zh-cn');

export * from './constant';

export function formatTime(second) {
  let dd, hh, mm, ss;

  if (!second || second < 0) {
    return;
  }

  dd = (second / (24 * 3600)) | 0;
  second = Math.round(second) - dd * 24 * 3600;
  hh = (second / 3600) | 0;
  second = Math.round(second) - hh * 3600;
  mm = (second / 60) | 0;
  ss = Math.round(second) - mm * 60;

  if (Math.round(dd) < 10) {
    dd = dd > 0 ? '0' + dd : '';
  }

  if (Math.round(hh) < 10) {
    hh = '0' + hh;
  }

  if (Math.round(mm) < 10) {
    mm = '0' + mm;
  }

  if (Math.round(ss) < 10) {
    ss = '0' + ss;
  }

  return hh === '00' ? mm + ':' + ss : hh + ':' + mm + ':' + ss;
}

/**
 * 将时间戳转为时间格式
 * @param {number} data 数据
 * @param {String} format 格式样式
 */
export function timeFormat(data, format) {
  format = format || 'yyyy-MM-dd';
  const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
  const date = new Date(data);
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours() % 12,
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    'S+': date.getMilliseconds(),
    'W+': week[date.getDay()]
  };
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return format;
}
export function myMoment(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern);
}
/**
 * 异常处理
 * @param {boolean} condition
 * @param {string} msg
 */
export function assert(condition, msg) {
  if (!condition) throw new Error(`[Apior] ${msg}`);
}

export function sizeChange(size) {
  const len = size.toString().length;
  console.log('len', len);
  if (len < 4) {
    return size + 'B';
  }
  if (len >= 4 && len < 7) {
    return _.ceil(size / 1024, 2) + 'KB';
  }
  if (len >= 7 && len < 10) {
    return _.ceil(size / 1024 / 1024, 2) + 'M';
  }
  if (len >= 10 && len < 13) {
    return _.ceil(size / 1024 / 1024 / 1024, 2) + 'G';
  }
  if (len >= 13 && len < 16) {
    return _.ceil(size / 1024 / 1024 / 1024 / 1024, 2) + 'T';
  }
}

/**
 * 默认图片，用于拼接图片地址&填充默认图片
 * @data {string} 图片地址
 * @isPic {boolean} 是否是图片模式，默认为头像模式
 */
export function checkImg(item, hasFix = true) {
  let remoteUrl = '';
  let prefix = '';
  if (!item) {
    return '';
  }

  if (typeof item === 'object') {
    remoteUrl = item.attach_image_url || item.attach_image;
  } else {
    remoteUrl = item;
  }
  if (!remoteUrl) {
    return '';
  }

  if (!remoteUrl.includes('http')) {
    remoteUrl = `https://erp.wholerengroup.com${remoteUrl}`;
  }

  if (remoteUrl.includes('wr-cdn.wholeren.cn') || remoteUrl.includes('res.accspeed.com')) {
    return remoteUrl;
  }

  if (hasFix) {
    const arr = remoteUrl.split('.');
    prefix = arr[arr.length - 1];
    remoteUrl = `${remoteUrl}_360-.${prefix}`;
  }

  return remoteUrl;
}

export function checkTime(time) {
  const startDate = moment.tz(time, 'America/New_York').utc();
  const endDate = moment().utc();

  // const ms = endDate.diff(startDate) // 返回毫秒数

  const month = endDate.diff(startDate, 'months'); // 0
  const weeks = endDate.diff(startDate, 'weeks'); // 1
  const days = endDate.diff(startDate, 'days'); // 7
  const hours = endDate.diff(startDate, 'hours'); // 7
  const minutes = endDate.diff(startDate, 'minutes'); // 7
  // console.log(ms, month, weeks, days, hours, minutes, startDate, endDate, 'startDate')
  if (month) {
    return '';
  } else if (weeks) {
    return '';
  } else if (days) {
    return '';
  } else if (hours) {
    return hours + '小时前';
  } else if (minutes) {
    return minutes + '分钟前';
  } else {
    return '';
  }
}

/**
 * 拿缓存数据
 * @param {String} key
 */
export function getStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

/**
 * 设置缓存数据
 * @param {String} key
 * @param {all} val
 */
export function setStorage(key, val) {
  return localStorage.setItem(key, JSON.stringify(val));
}
/**
 * 搜索字符高亮
 * @param {String} key
 * @param {all} val
 */
export function highLight(str, search) {
  if (search) {
    search = search.replace(/(^\s*)|(\s*$)/g, '');
    const mySearch = `<span style="color:#333;background:#fff442;padding:1px 3px;">${search}</span>`;
    return str.replace(search, mySearch);
  } else {
    return str;
  }
}

export async function goToLiveChat(name) {
  // window.open(`https://direct.lc.chat/9055235/1?name=${name}`)
  // // window.open('https://lc.chat/now/9055235/1')
  // const customerSDK = CustomerSDK.init({
  //   licenseId: 9055235,
  //   clientId: '593d4e1deede71888e49d81f03ef4d61',
  //   redirectUri: 'https://erp.wholerengroup.com'
  // })
  // console.log('custormerDSK', customerSDK)
  // const a = await customerSDK.auth.getToken()
  // console.log('token', a)

  // customerSDK.on('connected', payload => {
  //   console.log('connected', payload)
  //   const chatRoom = payload.chatsSummary[0]
  //   const chatId = chatRoom && chatRoom.id
  //   customerSDK
  //     .sendEvent(chatId, {
  //       type: 'message',
  //       text: `您好！我通过查看${name}的专家专栏进入`
  //     })
  //     .then(response => {
  //       console.log(response)
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // })
  const wx = window.wx;

  wx.miniProgram.switchTab({
    url: `/pages/contact/index?name=${name}`,
    success: function() {
      console.log('success');
    },
    fail: function() {
      console.log('fail');
    },
    complete: function() {
      console.log('complete');
    }
  });
}
