<template>
  <article class="share-card" id="shareDomNews" v-show="isShowShareDom">
    <h1 class="title"># 美国校园疫情动态 实时更新 #</h1>
    <div class="content">
      <div class="mainWrap">
        <p class="autoline title">{{ itemData.title }}</p>
        <p class=" autoline">{{ itemData.cn_content }}</p>

        <p class="location" v-if="(itemData.state_cn||itemData.ncov_school_cn)">
          <a-icon class="icon" type="environment"></a-icon>
          <span class="stateName">{{ itemData.state_cn }}</span>
          <span class="sep" v-if="itemData.state_cn&&itemData.ncov_school_cn">|</span>
          <span class="schoolName">{{ itemData.ncov_school_cn }}</span>
        </p>
        <div class="tagWrap">
          <van-tag class="tagCategory">{{ category }}</van-tag>
        </div>

        <p class="link" v-if="itemData.original_url">
          来源：
          <a :href="itemData.original_url" target="_blank">{{ itemData.original_url }}</a>
        </p>
      </div>
    </div>
    <footer class="footer">
      <div class="right">
        <img src="@/assets/web-qrcode.png" class="qr-code">
        <div class=" qr-help">扫码 查看详情</div>
      </div>
      <div class="left">
        <div class="leftConWrap">
          <div class=" title1">全美、各州、定点学校</div>
          <div class=" title1">疫情趋势及应对措施</div>
          <div class=" title2">更多热点：使馆信息｜疫苗进展｜联邦新闻｜身份/出入境｜机票行情｜实习就业新闻</div>
        </div>
        <div class="logo">
          <img src="@/assets/logo-white.png">
          <div>美国厚仁教育</div>
        </div>
      </div>
    </footer>
  </article>
</template>

<script>
import { mapState } from 'vuex'
import ReadMore from '@/components/ReadMore'
export default {
  components: {
    ReadMore
  },
  props: {

  },
  data () {
    return {
      isShowShareDom: true,
      tableData: []

    }
  },
  computed: {
    ...mapState({
      itemData: state => state.app.shareDomNews
    }),
    category () {
      if (this.itemData.node_category) {
        return `#${this.itemData.node_category.split('-')[0]}#`
      } else {
        return '# #'
      }
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {
    this.$bus.$on('isShowShareDomNews', (data) => {
      this.isShowShareDom = data.show
    })

    this.isShowShareDom = false
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.share-card{
    display: flex;
    background-image: linear-gradient( to right,#593ae2,#499fe7);
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 50%;
    transform:translate(-50%);
    z-index: -2;
    // background: rgb(73, 123, 137);
    // background: rgb(28, 145, 177);

    padding: 10px;
    .title{
        color:#fff;
        font-size:18px;
    }
    @media screen and (min-width: 576px){
        article {
            width: 375px;
        }
    }
    .content{
        width: 100%;
        background: rgb(255, 255, 255);
        border-radius: 6px;
        padding: 0 5px;
        .mainWrap{
          background:#f7f7f7;
          padding:0 12px;
          text-align:left;
        .tagWrap{
          display:flex;
          justify-content: space-between;
        button{
          background:#fc8300;
          border:none
        }
      }
  .tagCategory{
    margin-top:10px;
    background:#ddd;
    padding:3px 5px;
    color:#333;
    font-size:14px;
  }
}
 .link{
    font-weight:400;
    font-size:12px;
    margin-top:12px;
    color:#b4b4b4;
    display:-webkit-box;
    word-break: break-all;
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-box-orient:vertical;
    -webkit-line-clamp: 2;

    a{
        color:#b4b4b4
    }

}
.location{
  font-weight:400;
  font-size:12px;
  margin-top:12px;
  margin-bottom:0;
  .icon{
    margin-right:10px;
    color:#999;
    font-size:14px;
    position:relative;
    top:1px;
  }
  .stateName{
    font-weight:bold;
    color:@primary-color;
  }
  .sep{
    margin:0 10px;
    color:#333;

  }
  .schoolName{
    font-weight:bold;
    color:#f27355
  }
}
.autoline {
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size:14px;
    color:#555;
    margin-bottom:12px;
    &.title{
       font-size:15px;
       font-weight:450
    }

}

    }
    .footer{
        display: flex;
        font-size: 12px;
        color: rgb(255, 255, 255);
        width: 100%;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-top: 20px;
        height: 118px;
        .left{
            font-weight: bold;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: justify;
            justify-content: space-between;
            text-align:left;
            align-items:center;
            margin-right:20px;
            position:relative;
            .leftConWrap{
              margin-left:15px;
            }
            .logo{

                position:absolute;
                left:50%;
                transform:translate(-50%);
                bottom:5px;
                display:flex;
                img{
                  width:20px;
                  height:20px;
                }
            }

            .title1{
                font-size:14px;
                color:#fff;

            }
            .title2{
                font-size:12px;
                margin-top:5px;
            }
        }
        .right{
            img{
                width:80px;
                height:80px;
                margin-bottom:10px;
            }
            .qr-help{
                text-align:center;
            }
        }
    }
}

</style>
