<template>
  <div class="listItem">
    <h4 class="school" @click="goToSchool(item)"><a>{{ item.school_cn }}</a></h4>
    <p class="tagWrap">
      <span class="tag">确诊：{{ item.ncov_confirmed }}</span>
      <span class="time">更新时间：{{ item.modified | dayjs }}</span>
    </p>
    <div class="text-box">
      <div :class="['txt', { 'over-hidden': !unfold }]" ref="textBox">
        <span ref="spanBox" class="des" v-html="item.ncov_response||'暂无'"></span>
      </div>
      <div class="btn" v-if="ifOver" @click="unfold = !unfold">
        <span>{{ unfold ? '收起' : '展开' }}</span>
        <a-icon :type="unfold?'up':'down'"></a-icon>
      </div>
    </div>
    <!-- <p class="des" v-html="item.ncov_response||'暂无'"></p> -->
    <p class="link">
      <van-tag>来源</van-tag> <a :href="item.ncov_url" target="_blank">{{ item.ncov_url }}</a>
    </p>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      unfold: false // 文本是否是展开状态 默认为收起
    }
  },
  computed: {
    // 文本是否超出三行，默认否
    ifOver () {
      if (this.$refs.spanBox) {
        console.log(this.$refs.spanBox.offsetHeight, this.$refs.textBox.offsetHeight, 'offset')
        return this.$refs.spanBox.offsetHeight > this.$refs.textBox.offsetHeight
      } else {
        return true
      }
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {
  },
  methods: {
    goToSchool (item) {
      this.$router.push({
        path: '/school',
        query: {
          name: item.school,
          state: item.school_state_short
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.listItem {
  width:100%;
  p {
    text-align: left;
  }
  .tagWrap{
    display:flex;
    justify-content: space-between;
    .tag{
    font-size: 14px;
    font-weight:500;
    color:red;

    }
    .time{
      font-weight: 400;
      color:#999
    }
  }

  .des {
    font-size:14px;
    color: #666;
  }
  .link {
    font-size: 12px;
    span{
        background: #f2f2f2;
        color:#8D99A6;
        font-weight:bold;
    }
    a {
      color: #666;
    //   font-weight: 700;
    }
  }
}
.text-box{
    text-align:left;
    .txt {
        font-size: 16px;
        color: #333;
        margin-bottom: 5px;
    }
    .over-hidden {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
    }
    .des {
        font-size:14px;
        color: #666;
        display:block;
    }

    .btn {
        color: #fc8300;
        margin:10px 0;
        font-weight:bold;
        text-align:right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        i{
            margin-left:5px;
        }
    }

}
</style>
