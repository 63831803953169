<!-- @format -->

<!--@format
 by: wk 2018/12/18
 name: 页面组件——首页地图
 备注:
-->
<template>
  <div class="pieWrap">
    <top-header :padding="true" title="学校秋季安排" label="美国大学秋季最新授课规划"></top-header>
    <search style="margin-top:10px;"></search>
    <div id="pie" class="pie"></div>
    <div class="tips">数据来源：学校官网及美国高等教育纪事报(同步更新)</div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import TopHeader from '@/components/TopHeader';
import Search from '../components/Search';
import { mapMutations, mapState } from 'vuex';
const echarts = require('echarts/lib/echarts');

require('echarts/lib/chart/pie');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/legend');
require('echarts/lib/component/visualMap');

require('echarts/lib/component/title');

export default {
  components: { TopHeader, Search },

  props: {
    from: {
      default: 'home',
      type: String
    }
  },

  computed: {
    ...mapState({
      schoolList: state => state.app.schoolList
    })
  },

  data() {
    return {
      pieData: [
        {
          name: '全部网课',
          school: [],
          value: 0
        },
        {
          name: '网课为主',
          school: [],
          value: 0
        },
        {
          name: '面授+网课',
          school: [],
          value: 0
        },
        {
          name: '面授为主',
          school: [],
          value: 0
        },
        {
          name: '全部面授',
          school: [],
          value: 0
        },
        {
          name: '待决定',
          school: [],
          value: 0
        },
        {
          name: '其他',
          school: [],
          value: 0
        }
      ],
      tips: ''
    };
  },

  created() {
    this._getSchoolData();
  },

  mounted() {
    const that = this;
    window.goToSchoolList = function(name) {
      that.$router.push({
        path: '/schoolList', // 需要这样跳转
        query: {
          category: name
        }
      });
    };
  },

  methods: {
    ...mapMutations({
      setSchoolList: 'SET_SCHOOL_LIST'
    }),

    /**
     * @description: 拿到map学校数据
     * @param {type}
     * @return:
     */
    _getSchoolData() {
      this.$api['home/measure']({ limit_page_length: 0 })
        .then(res => {
          console.log(res, 'mapererer');
          if (res.message) {
            const schoolList = res.message.map(item => {
              return {
                cn_name: item.school_cn,
                name: item.school_cn + item.name,
                id: item.name,
                state: item.school_state_short,
                status: item.fall_2020_policy_category
              };
            });
            this.setSchoolList(schoolList);
            this._drawMap();
          }
        })
        .catch(err => {
          console.log(err);
          Toast('服务器开小差了，请稍后再试');
        });
    },

    /**
     * @description: 绘制地图
     */
    _drawMap() {
      const newPieData = [].concat(this.pieData);
      this.schoolList.forEach((item, index) => {
        this.pieData.forEach((t, i) => {
          if (t.name === item.status) {
            newPieData[i].school.push(item);
            newPieData[i].value += 1;
          }
        });
      });
      console.log(newPieData, 'newPieData');
      this.pieData = newPieData;
      var dom = document.getElementById('pie');
      var myChart = echarts.init(dom);

      const option = {
        tooltip: {
          // 提示
          trigger: 'item', // 触发方式
          textStyle: {
            fontWeight: 'bold'
          },
          triggerOn: 'click',
          enterable: true,
          extraCssText: 'text-align:left;z-index:99',
          showDelay: 0,

          transitionDuration: 0.2,
          formatter: params => {
            console.log(params, 'paras');
            const data = params.data;

            let html = '';
            if (data) {
              html += `<span style="color:#fff442;font-size:bold;">${data.name}: ${data.value} <br/></span>`;
              html += `百分比：${params.percent}% <br/>`;
              let num = 0;
              data.school.forEach(item => {
                if (num < 5) {
                  num += 1;

                  html += ` <span style='font-weight:bold;'>${item.cn_name} </span><br/> `;
                }
              });
            }

            if (this.from === 'home') {
              html += `<div onclick='goToSchoolList("${data.name}")' style="background:#4169e2;color:#fff442;display:flex;justify-content:center;align-items:center;width:100%;height:30px;">更多学校>></div>`;
            } else {
              html += `<div style="background:#4169e2;color:#fff442;display:flex;justify-content:center;align-items:center;width:100%;height:30px;">下方学校列表筛选</div>`;
            }

            return html;
          }
        },
        legend: {
          // 图例
          orient: 'horizontal',
          itemWidth: 15,
          data: this.pieData.map(item => {
            return item.name;
          }),
          textStyle: {
            fontSize: '14'
          }
        },
        series: [
          {
            name: '访问来源',
            type: 'pie', // 图标的类型
            // bottom: 0,

            top: 40,
            avoidLabelOverlap: true,
            color: ['#276419', '#7FBC41', '#0570B0', '#DE77AE', '#C51B7D', '#D9D9D9', '#666666'],

            label: {
              normal: {
                show: true
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              normal: {
                show: true
              }
            },
            data: this.pieData
          }
        ]
      };

      myChart.setOption(option);
    }
  }
};
</script>
<style lang="less" scoped>
.pieWrap {
  background: #fff;
  padding: 16px 0;
  .pie {
    margin-top: 20px;
    width: 100%;
    height: 35vh;
  }
  .tips {
    color: #666;
  }
}
</style>
