<template>
  <div class="order">
    <van-nav-bar fixed title="我的订阅" left-text="返回" left-arrow @click-left="onClickLeft"> </van-nav-bar>
    <div class="topWrap">
      <p>订阅账号</p>
      <p class="title" @click="cancel">{{ loginId }}</p>
    </div>
    <div style="margin:10px 0;" v-if="orderAuthor.length">
      <top-header title="关注专家" :padding="true"></top-header>
      <div>
        <div v-for="author in orderAuthor" :key="author.name" style="margin:0 16px 16px;">
          <author-list-item :author="author"></author-list-item>
        </div>
      </div>
    </div>
    <a :href="imgUrl" target="_blank" class="report"><img src="@/assets/report.gif"/></a>
    <div v-if="hasData">
      <div style="margin-bottom:30px;" v-if="orderState.length">
        <top-header title="订阅州" :padding="true"></top-header>
        <order-state-table :tab-height="44"></order-state-table>
      </div>

      <div title="学校" v-if="orderSchool.length">
        <top-header title="订阅学校" :padding="true"></top-header>
        <order-school-table :tab-height="44"></order-school-table>
      </div>
    </div>

    <div v-else>
      <a-empty class="noData" description="暂无订阅信息，前往感兴趣的州或学校，开启我的订阅吧"></a-empty>
      <div class="emptyBtn">
        <a-button type="primary" @click="goToStateList">进入州列表</a-button>
        <a-button type="primary" ghost @click="goToSchoolList">进入学校列表</a-button>
      </div>
    </div>

    <van-overlay :show="isShow">
      <div class="lay">
        <div class="layWrap">
          <div class="layTop">
            <p>您确定要退出订阅吗？</p>
          </div>
          <p class="con">保持登录，快速获取您感兴趣的州和校园疫情数据。</p>
          <div class="btnWrap">
            <a-button class="btn" type="primary" @click="isShow = false">继续登录</a-button>
            <a-button class="btnCancel" @click="handleCancel">确定退出</a-button>
          </div>
        </div>
      </div>
    </van-overlay>

    <ad-item v-if="ad['NCOV-AD-PL-00008'] && hasData" :ad-data="ad['NCOV-AD-PL-00008']" style="margin:20px 0"></ad-item>

    <div v-if="hasData" class="news">
      <top-header title="订阅动态"></top-header>
      <a-timeline style="margin-top:10px;">
        <flow-list url="home/news" :params="params" :sep="false">
          <template slot-scope="row">
            <div>
              <a-timeline-item>
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="row.itemData"></news-item>
              </a-timeline-item>
              <a-timeline-item v-if="row.myIndex === 1">
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="newsAd[0]"></news-item>
              </a-timeline-item>
              <a-timeline-item v-if="row.myIndex === 6">
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="newsAd[1]"></news-item>
              </a-timeline-item>
            </div>
          </template>
        </flow-list>
      </a-timeline>
    </div>
  </div>
</template>

<script>
// import moment from 'moment-timezone'
import { FlowList, NewsItem } from '@/components';
import TopHeader from '@/components/TopHeader';
import AdItem from '@/components/AdItem';
import OrderSchoolTable from '../home/components/OrderSchoolTable';
import OrderStateTable from '../home/components/OrderStateTable';
import AuthorListItem from '@/components/AuthorListItem';
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  name: 'Subscription',
  components: {
    OrderSchoolTable,
    OrderStateTable,
    TopHeader,
    FlowList,
    NewsItem,
    AdItem,
    AuthorListItem
  },
  props: {},
  data() {
    return {
      active: 0,
      isShow: false
    };
  },
  computed: {
    ...mapState({
      newsAd: state => state.app.newsAd
    }),
    ...mapGetters(['loginId', 'orderSchool', 'orderState', 'orderAuthor', 'ad']),
    hasData() {
      if (this.orderSchool.length || this.orderState.length || this.orderAuthor.length) {
        return true;
      } else {
        return false;
      }
    },
    imgUrl() {
      if (this.loginId) {
        return `https://erp.wholerengroup.com/wholeren-service-questionnaire/-7025418009546371403?email=${this.loginId}`;
      } else {
        return 'https://erp.wholerengroup.com/wholeren-service-questionnaire/-7025418009546371403';
      }
    },
    params() {
      const schoolId = this.orderSchool.map(item => {
        return item.name;
      });
      const stateId = this.orderState.map(item => {
        return item.name;
      });
      const tt = {
        limit_start: 0,
        limit_page_length: 10,
        filters: JSON.stringify({ node_category: this.$utils.newsCategory.news }),
        or_filters: [{ ncov_school: ['in', schoolId] }, { state: ['in', stateId] }]
      };
      if (!schoolId.length) {
        tt.or_filters.shift();
      }
      if (!stateId.length) {
        tt.or_filters.pop();
      }
      tt.or_filters = JSON.stringify(tt.or_filters);
      // const day = moment().subtract('days', 3).format('YYYY-MM-DD')
      return tt;
    }
  },
  watch: {},
  created() {},
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.order = true;
      }

      console.log('fromvm schiool', from, vm);
    });
  },
  activated() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    ...mapMutations({
      setLoginId: 'SET_LOGIN_ID',
      setOrderState: 'SET_ORDER_STATE',
      setOrderSchool: 'SET_ORDER_SCHOOL'
    }),
    goToStateList() {
      this.$router.push('stateList');
    },
    goToSchoolList() {
      this.$router.push('schoolList');
    },
    onClickLeft() {
      console.log(this.$router);

      if (this.order) {
        this.$router.back();
      } else {
        this.$router.push('/');
      }
    },
    cancel() {
      this.isShow = true;
    },
    handleCancel() {
      this.setLoginId('');
      this.setOrderState([]);
      this.setOrderSchool([]);
      this.$router.push('/');
      this.isShow = false;
    }
  }
};
</script>

<style scoped lang="less">
.order {
  padding-top: 46px;
  .report {
    display: block;
    padding: 0 16px;
    margin-top: 10px;
    img {
      width: 100%;
    }
  }
  .topWrap {
    height: 40px;
    width: 100%;
    display: flex;
    padding: 0 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    p {
      margin: 0;
    }
    .title {
      font-size: 14px;
      color: @primary-color;
    }
  }
  .noData {
    margin-top: 20px;
  }
  .emptyBtn {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    button {
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
}
.lay {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  .layWrap {
    width: 80%;
    background: #fff;
    padding: 0 16px;
    border-radius: 4px;
    position: relative;
    .layTop {
      display: flex;
      margin-top: 15px;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;

      p {
        color: @primary-color;
        font-weight: bold;
        font-size: 18px;
      }
    }
    .con {
      margin-bottom: 15px;
    }
    .btnWrap {
      display: flex;
      justify-content: center;
    }
    .btn {
      margin: 10px;
    }
    .btnCancel {
      margin: 10px;
      border: 1px solid @primary-color;
      color: @primary-color;
    }
  }
}
/deep/ .ant-empty-description {
  text-align: center;
}
.news {
  padding: 0 16px;
  margin-top: 20px;
}
.dot {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #c7d4f7;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: #3f69e2;
  }
}
/deep/ .van-pull-refresh {
  overflow: visible;
}
/deep/ .ant-timeline-item-tail {
  border-left-color: #c7d4f7;
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail {
  display: block;
}
</style>
