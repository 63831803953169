<template>
  <div class="pdetailTab">
    <top-header color="#f5631e" title="往期学员分享"></top-header>
    <!-- <video-item :itemData="videoData"></video-item> -->
  </div>
</template>

<script>
import { VideoItem } from '@/components'
import TopHeader from '@/components/TopHeader'
export default {
  components: {
    TopHeader, VideoItem
  },
  props: {
    itemData: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      videoData: {
        original_url: 'https://www.jobupper.com/wp-content/uploads/2019/12/【JobUpper职尚行】行业导师规划和美国企业实习-_-听听学生怎么说.mp4',
        attach_image: 'https://www.jobupper.com/wp-content/uploads/2020/02/JU-Video-Cover01.jpg'
      }
    }
  },
  computed: {

  },
  watch: {

  },
  created () {
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.pdetailTab{
    margin-top:10px;
    padding:0 16px;

}
</style>
