<template>
  <div class="myTable">
    <div class="table" id="schoolTable">
      <div class="tableHeader" :class="{ tableFix: tableFix }">
        <p class="name">学校名称</p>
        <p class="confirm">排名</p>
        <p class="course">复学安排</p>
        <p class="detail">确诊数字</p>
      </div>
      <div class="tableItem" v-for="item in tableData" :key="item.name">
        <p class="name" @click="goToSchool(item)">
          <span class="detail-text">{{ item.school_cn }}</span>
          <van-tag class="tips" v-if="item.news_count">+{{ item.news_count }}</van-tag>
        </p>
        <p class="confirm">{{ item.latest_ranking_2022 }}</p>
        <p class="course">
          <span v-if="item.fall_2020_policy_category">{{ item.fall_2020_policy_category }}</span>
          <span v-else>暂无</span>
          <a-tooltip v-if="item.fall_2020_policy_cn">
            <template slot="title">{{ item.fall_2020_policy_cn }}</template>
            <i class="iconfont icon-question"></i>
          </a-tooltip>
        </p>
        <!-- <p class="online"><span v-if="item.online_course_end">{{ item.online_course_end | moment('MM/DD') }}</span><span v-else>暂无</span> </p> -->
        <p class="detail">{{ item.ncov_confirmed || '暂无' }}</p>
      </div>
    </div>

    <div style="padding:16px 16px 0;">
      <a-button class="btn" block type="primary" @click="loadMore" :loading="loading">更多学校</a-button>
    </div>
  </div>
</template>

<script>
import { LoadMore } from '@/components';
export default {
  name: 'MyTable',
  components: {
    LoadMore
  },
  props: {
    params: {
      default: () => {},
      type: Object
    },
    request: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      tableData: [],
      tableFix: false,
      hasMore: true,
      loading: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.tabHeight = 60;
    this._getSchoolData();
  },
  activated() {
    window.addEventListener('scroll', this.onScroll);
  },
  deactivated() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll);
  },
  mounted() {},
  methods: {
    onScroll() {
      // 获取所有锚点元素
      const tableContent = document.getElementById('schoolTable');
      // 所有锚点元素的 offsetTop
      const offsetTop = tableContent.offsetTop;
      const offsetHeight = tableContent.offsetHeight;
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //   console.log(123, scrollTop, offsetTop, offsetHeight)
      if (scrollTop > offsetTop - this.tabHeight) {
        this.tableFix = true;
        if (scrollTop > offsetTop + offsetHeight - this.tabHeight - 40) {
          this.tableFix = false;
        }
      } else {
        this.tableFix = false;
      }
    },
    loadMore() {
      this.$router.push('schoolList');
      // this.params.limit_start += this.params.limit_page_length
      // this._getSchoolData()
    },
    _getSchoolData() {
      this.loading = true;
      this.$api[this.request](this.params)
        .then(res => {
          console.log(res, 'schoolTable');
          if (res.message) {
            if (res.message.length < this.params.limit_page_length) {
              this.hasMore = false;
            }
            this.tableData = [].concat(this.tableData, res.message);
            this.tableData.sort((a, b) => {
              return a.latest_ranking_2022 - b.latest_ranking_2022;
            });
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    },
    goToSchool(item) {
      console.log(item);
      this.$router.push({
        path: '/school',
        query: {
          name: item.name,
          state: item.school_state_short
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.red1 {
  color: red;
}
.green1 {
  color: rgb(40, 183, 163);
}
.gray1 {
  color: rgb(93, 112, 146);
}
.btn {
  font-weight: bold;
}
.myTable {
  .tableHeader {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    height: 40px;
    &.tableFix {
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      z-index: 10;
    }
    p {
      height: 100%;
      margin: 0;
      line-height: 40px;
      text-align: center;
      font-weight: bold;
      color: #333;
    }
    .name {
      background: #e3e7f3;
      width: 35%;
    }
    .confirm {
      flex: 1;
      background: #e69a8d;
    }
    .course {
      width: 30%;
      background: #95db9a;
    }
    .online {
      flex: 1;
      background: #b4c0d5;
    }
    .detail {
      width: 20%;
      background: #e3e7f3;
    }
  }
  .tableItem {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    border-bottom: 1px solid #e8e8e8;
    min-height: 40px;
    p {
      height: 100%;
      margin: 0;
      font-size: 12px;
      text-align: center;
      font-weight: bold;
      background: #f7f7f7;
    }
    .name {
      width: 35%;
      cursor: pointer;
      position: relative;
      padding: 0 20px 0 10px;

      .detail-text {
        color: #4169e2;
      }
      .tips {
        position: absolute;
        right: -5px;
        top: 50%;
        transform: translateY(-50%);
        background: #ecf0fc;
        color: #4169e2;
        margin-left: 10px;
        border-radius: 5px;
      }
    }
    .confirm {
      flex: 1;
    }
    .course {
      // font-weight:normal;
      width: 30%;
    }
    .online {
      font-weight: normal;
      flex: 1;
    }
    .detail {
      width: 20%;
    }
  }
}
.icon-question {
  color: #fc8300;
  font-size: 12px;
}
</style>
