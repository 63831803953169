/** @format */
import store from '@/store'
/**
 * @msg:路由拦截
 * @param {Object} to 希望跳转的路由
 * @param {Object} from 从哪个路由跳
 * @param {Function} next 执行下一步操作
 * @return:
 */
export function routerBeforeEachFunc (to, from, next) {
  // 如果曾经登录过，登录id会存入localstorage
  store.dispatch('GetPageCount')
  console.log('routerBeforeEachFunc', to, from)

  // if (!store.state.wechat.authUser) {
  //   console.log(to)
  //   const fullPath = encodeURIComponent(to.name)

  //   store.dispatch('GetWechatRedirect', { redirect_uri: fullPath, scope: 'snsapi_userinfo' })
  // }
  next()
}

export function routerAfterEachFunc (to, from) {}
