<template>
  <div class="school">
    <ShareDom></ShareDom>
    <van-nav-bar fixed :title="school.school_cn" left-text="返回" left-arrow @click-left="onClickLeft">
      <!-- <template #right>
        <order-tag type="school" :name="school.name"></order-tag>
      </template> -->
    </van-nav-bar>

    <van-tabs
      v-model="active"
      :border="true"
      :offset-top="46"
      sticky
      scrollspy
      swipeable
      title-active-color="#4169e2"
      color="#4169e2"
      background="#f7f7f7"
    >
      <van-tab title="学校简介">
        <school-data :school="school"></school-data>
        <!-- <school-offer></school-offer> -->
      </van-tab>

      <van-tab title="申请与就业">
        <school-job></school-job>
      </van-tab>
      <van-tab title="学校动态">
        <ad-item v-if="ad['NCOV-AD-PL-00005']" :ad-data="ad['NCOV-AD-PL-00005']" style="margin:20px 0 10px"></ad-item>
        <div class="sep"></div>
        <school-measure :school="school"></school-measure>
        <SchoolNews></SchoolNews>
        <StateNews></StateNews>
      </van-tab>
    </van-tabs>
    <ad-item v-if="ad['NCOV-AD-PL-00006']" :ad-data="ad['NCOV-AD-PL-00006']" style="margin:0 0 20px"></ad-item>
  </div>
</template>

<script>
import { SchoolMeasure, SchoolNews, StateNews, SchoolData, SchoolOffer, SchoolJob } from './item';
import { OrderTag, AdItem, Sticky } from '@/components';
import { mapMutations, mapGetters } from 'vuex';
import ShareDom from './item/ShareDom';
import { backInitMixin } from '@/utils/mixin';
export default {
  name: 'School',
  components: {
    SchoolMeasure,
    SchoolNews,
    StateNews,
    SchoolData,
    OrderTag,
    AdItem,
    Sticky,
    ShareDom,
    SchoolOffer,
    SchoolJob
  },
  mixins: [backInitMixin],
  props: {},
  data() {
    return {
      school: {},
      active: 0
    };
  },
  computed: {
    ...mapGetters(['ad'])
  },

  watch: {},
  created() {
    this.params = {
      limit_start: 0,
      limit_page_length: 10,
      filters: { name: '' }
    };
  },

  activated() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.initCheck();
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setSchoolData: 'SET_SCHOOL_DATA'
    }),
    initCheck() {
      const name = this.$route.query.name;

      this.params.filters.name = name;
      this.school = {};
      this.params.limit_start = 0;
      this.getSchool();
    },
    getSchool() {
      this.loading = true;
      this.$api['home/measure'](this.params)
        .then(res => {
          console.log(res, 'ameTable');
          if (res.message) {
            this.school = res.message[0];
            this.setSchoolData(res.message[0]);
            this.track();
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    },

    goToTran(url) {
      window.open(url);
    },
    goToState(item) {
      this.$router.push({
        path: '/state',
        query: {
          name: item.school_state,
          short_name: item.school_state_short
        }
      });
    },
    track() {
      this.$gtag.event('go_coronavirus_update_school', {
        event_category: 'CoronavirusUpdateSchool',
        event_label: this.school.school
      });
    }
  }
};
</script>

<style scoped lang="less">
/deep/ .van-nav-bar__title {
  font-size: 20px;
}
.school {
  padding-top: 46px;
  .sep {
    background: #f1f1f1;
    height: 8px;
  }
}
</style>
