<template>
  <div class="myTable">
    <div class="table" :id="domId">
      <div v-show="tableFix" class="tableHeader"></div>
      <div class="tableHeader" :class="{ tableFix: tableFix }" :style="{ top: tabHeight + 'px' }">
        <p class="name">州</p>

        <p class="confirm">
          确诊
          <i class="iconfont icon-sort"></i>
        </p>
        <p class="cure">
          治愈
          <i class="iconfont icon-sort"></i>
        </p>
        <p class="death">
          死亡
          <i class="iconfont icon-sort"></i>
        </p>
        <p class="level">风险评级</p>
      </div>
      <div
        class="tableItem"
        v-for="(item,index) in tableData"
        :key="item.name"
        :class="{odd:index%2}"
      >
        <p class="name" @click="goToState(item)">
          <span class="name_1">{{ item.cn_state }}</span>
          <span class="name_2">{{ item.short_name }}</span>
        </p>
        <p class="confirm">
          <span class="confirm_1">+{{ item.ncov_increase_confirmed || '0' }}</span>
          <span class="confirm_2">{{ item.ncov_confirmed | NumberFormat }}</span>
        </p>
        <p class="cure">
          <span class="cure_1">+{{ item.ncov_increase_recovered || '0' }}</span>
          <span class="cure_2">{{ item.ncov_recovered | NumberFormat }}</span>
        </p>
        <p class="death">
          <span class="death_1">+{{ item.ncov_increase_deaths || '0' }}</span>
          <span class="death_2">{{ item.ncov_deaths | NumberFormat }}</span>
        </p>

        <div class="level">
          <span class="level_1">{{ item.ncov_risk_level }}</span>
          <img src="@/assets/table_1.png" class="level_img" v-if="item.ncov_risk_level==='安全'" />
          <img src="@/assets/table_2.png" class="level_img" v-if="item.ncov_risk_level==='社区传播'" />
          <img src="@/assets/table_3.png" class="level_img" v-if="item.ncov_risk_level==='传播加速'" />
          <img src="@/assets/table_4.png" class="level_img" v-if="item.ncov_risk_level==='危险'" />
        </div>
      </div>
    </div>
    <div style="padding:16px 16px 0;" v-if="!allData">
      <a-button class="btn" block type="primary" @click="loadMore" :loading="loading">更多州信息</a-button>
      <!-- <load-more slot="footer" @loadMore="loadMore" v-if="tableData.length" :hasMore="hasMore" :loading="loading"></load-more> -->
    </div>
  </div>
</template>

<script>
import { LoadMore } from '@/components'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'MyTable',
  components: {
    LoadMore
  },
  props: {

    allData: {
      default: false,
      type: Boolean
    },
    domId: {
      default: 'stateTable',
      type: String
    },
    tabHeight: {
      default: 60,
      type: Number
    }
  },
  data () {
    return {
      tableData: [],
      tableFix: false,
      hasMore: true,
      loading: false,
      isMinToMax: true,
      isConfirmMinToMax: true

    }
  },
  computed: {
    ...mapState({
      stateList: state => state.app.stateList
    })

  },
  watch: {
    stateList () {
      this.handleData()
    }
  },
  created () {
    this.$api['home/map']({
      limit_start: 0,
      limit_page_length: 100
    }).then(res => {
      console.log(res, 'maper')
      if (res.message) {
        this.setStateList(res.message)
      }
    }).catch(err => {
      console.log(err)
    })
  },
  activated () {
    window.addEventListener('scroll', this.onScroll)
  },
  deactivated () {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
  mounted () {
    this.handleData()
  },
  methods: {
    ...mapMutations({
      setStateList: 'SET_STATE_LIST'
    }),
    onScroll () {
      // 获取所有锚点元素
      const tableContent = document.getElementById(this.domId)
      // 所有锚点元素的 offsetTop

      const offsetTop = tableContent.offsetTop
      const offsetHeight = tableContent.offsetHeight

      // 获取当前文档流的 scrollTop
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      //   console.log(123, scrollTop, offsetTop, offsetHeight)
      if (scrollTop > offsetTop - this.tabHeight) {
        this.tableFix = true
        if (scrollTop > offsetTop + offsetHeight - this.tabHeight - 40) {
          this.tableFix = false
        }
      } else {
        this.tableFix = false
      }
    },
    loadMore () {
      this.$router.push('stateList')
    },
    handleData () {
      const tableData = [].concat(this.stateList)
      tableData.sort((a, b) => {
        if (!a.ncov_confirmed || !b.ncov_confirmed) {
          return 1
        }
        return Number(b.ncov_confirmed) - Number(a.ncov_confirmed)
      })

      if (this.allData) {
        this.tableData = tableData
      } else {
        this.tableData = tableData.slice(0, 5)
      }
    },
    handleSort () {
      this.isMinToMax = !this.isMinToMax

      const dataUp = this.stateList.filter(item => item.new_case_status === '新增攀升')
      const dataCenter = this.stateList.filter(item => item.new_case_status === '新增稳定')
      const dataDown = this.stateList.filter(item => item.new_case_status === '新增放缓')
      let tableData = []
      if (this.isMinToMax) {
        tableData = [...dataUp, ...dataCenter, ...dataDown]
      } else {
        tableData = [...dataDown, ...dataCenter, ...dataUp]
      }
      if (this.allData) {
        this.tableData = tableData
      } else {
        this.tableData = tableData.slice(0, 5)
      }
    },
    handleConfirmSort () {
      console.log('this.', this.tableData)
      this.tableData.sort((a, b) => {
        if (!a.ncov_confirmed || !b.ncov_confirmed) {
          return 1
        }

        if (this.isConfirmMinToMax) {
          return Number(a.ncov_confirmed) - Number(b.ncov_confirmed)
        } else {
          return Number(b.ncov_confirmed) - Number(a.ncov_confirmed)
        }
      })
      this.isConfirmMinToMax = !this.isConfirmMinToMax
    },

    goToState (item) {
      console.log(item)
      this.$router.push({
        path: '/state',
        query: {
          name: item.name,
          short_name: item.short_name
        }
      })
    }
  }

}
</script>

<style scoped lang="less">
.red1 {
  color: red;
}
.green1 {
  color: rgb(40, 183, 163);
}
.gray1 {
  color: rgb(93, 112, 146);
}
.btn {
  font-weight: bold;
}
.myTable {
  .tableHeader {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    height: 40px;
    &.tableFix {
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      z-index: 10;
    }
    p {
      height: 100%;
      margin: 0;
      line-height: 40px;
      text-align: center;
      font-weight: bold;
      color: #333;
    }
    .name {
      background: #ecf0fc;
      width: 30%;
    }
    .confirm {
      flex: 1;
      background: #e69a8d;
    }
    .cure {
      flex: 1;
      background: #95db9a;
    }
    .death {
      flex: 1;
      background: rgba(93, 112, 146, 0.5);
    }
    .level {
      flex: 1;
      background: #f5dfbc;
    }
  }
  .tableItem {
    display: flex;
    align-items: center;
    background: #f9f9f9;
    border-bottom: 1px solid #e8e8e8;
    min-height: 45px;
    position: relative;
    &.odd {
      background: #fff;
    }
    p {
      height: 100%;
      margin: 0;
      text-align: center;
      font-weight: bold;

      font-size: 12px;
    }
    .name {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .name_1 {
        color: #4169e2;
      }
      .name_2 {
        font-size: 10px;
      }
    }
    .confirm {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .confirm_2 {
        color: red;
        font-size: 12px;
      }
      .confirm_1 {
        color: red;
        font-size: 10px;
      }
    }
    .cure {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgb(40, 183, 163);
      .cure_2 {
        font-size: 12px;
      }
      .cure_1 {
        font-size: 10px;
      }
    }
    .death {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: rgb(93, 112, 146);
      .death_2 {
        font-size: 12px;
      }
      .death_1 {
        font-size: 10px;
      }
    }
    .level {
      flex: 1;
      color: #666;
      font-size: 12px;
      font-weight: bold;
      .level_img {
        width: 100%;
      }
    }
  }
}
.icon-sort {
  font-size: 10px;
}

</style>
