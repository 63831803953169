<template>
  <div class="videoDetail">
    <van-nav-bar fixed title="后疫情时代之留美解析" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="titleWrap">
      <p class="label">
        这是「陈航说留美」小视频栏目，为大家带来特别解读，话题征集自群里家长反馈，包括留美申请注意事项、美国选举对留学的影响、解读入境限令、学生如何保护自己、如何保持学业不中断、后疫情时代求职技巧、远程学习的注意事项等。
      </p>
    </div>

    <div v-for="item in videoDataFilter" :key="item.name" class="listItem">
      <video-bili-item ref="video" :item-data="item"></video-bili-item>

      <p>{{ item.title }}</p>
    </div>
  </div>
</template>

<script>
import VideoBiliItem from '@/components/VideoBiliItem';
export default {
  name: 'VideoDetail',
  components: {
    VideoBiliItem
  },
  props: {},
  data() {
    return {
      videoData: []
    };
  },
  computed: {
    videoDataFilter() {
      return this.videoData.filter(item => {
        return item.bilibili_src_uri;
      });
    }
  },
  watch: {},
  created() {
    this.params = {
      limit_start: 0,
      limit_page_length: 0,
      filters: { node_category: this.$utils.newsCategory.shortVideo }
    };
    this.getNews();
  },
  mounted() {},
  methods: {
    getNews() {
      this.loading = true;
      this.$api['home/news'](this.params)
        .then(res => {
          console.log(res, 'video');
          if (res.message) {
            this.videoData = res.message;
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    },
    onClickLeft() {
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="less">
.videoDetail {
  padding-top: 50px;
  .titleWrap {
    padding: 10px 16px;
  }
  .title {
    margin: 0;
  }
  .label {
    font-size: 12px;
    margin-bottom: 8px;
  }
}
.listItem {
  padding: 0 30px;
  margin-bottom: 20px;
  p {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    margin-top: 10px;
  }
}
</style>
