<template>
  <div class="report">
    <van-nav-bar
      fixed
      title="留学日报"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <van-tabs v-model="active" sticky :offset-top="46" color="#4169e2" title-active-color="#4169e2">
      <van-tab title="全部">
        <flow-list url="home/news" :params="paramsTab1" :sep="false">
          <template slot-scope="row">
            <report-item :item-data="row.itemData"></report-item>
          </template>
        </flow-list>
      </van-tab>
      <van-tab title="求职日报">
        <flow-list url="home/news" :params="paramsTab2" :sep="false">
          <template slot-scope="row">
            <report-item :item-data="row.itemData"></report-item>
          </template>
        </flow-list>
      </van-tab>
      <van-tab title="转升日报">
        <flow-list url="home/news" :params="paramsTab3" :sep="false">
          <template slot-scope="row">
            <report-item :item-data="row.itemData"></report-item>
          </template>
        </flow-list>
      </van-tab>
      <van-tab title="学术日报">
        <flow-list url="home/news" :params="paramsTab4" :sep="false">
          <template slot-scope="row">
            <report-item :item-data="row.itemData"></report-item>
          </template>
        </flow-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { FlowList, ReportItem } from '@/components'
export default {
  components: {
    FlowList, ReportItem
  },
  props: {

  },
  data () {
    return {
      active: 0
    }
  },
  computed: {

  },
  watch: {

  },
  created () {
    this.paramsTab1 = {
      limit_start: 0,
      limit_page_length: 10,
      order_by: 'publish_date desc',
      filters: { node_category: this.$utils.newsCategory.report }
    }
    this.paramsTab2 = {
      ...this.paramsTab1,
      filters: { node_category: this.$utils.newsCategory.report, report_name: '求职日报' }
    }
    this.paramsTab3 = {
      ...this.paramsTab1,
      filters: { node_category: this.$utils.newsCategory.report, report_name: '转升日报' }
    }
    this.paramsTab4 = {
      ...this.paramsTab1,
      filters: { node_category: this.$utils.newsCategory.report, report_name: '学术日报' }
    }
  },
  mounted () {

  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.report = true
      }

      console.log('fromvm stae', from, vm)
    })
  },
  methods: {
    onClickLeft () {
      console.log(this.$router)
      if (this.report) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped lang="less">
.report{
    padding-top:46px;
}
</style>
