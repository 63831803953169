<template>
  <div class="letter">
    <van-nav-bar
      fixed
      title="厚仁教育"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <h4>致厚仁学生与家长的一封信</h4>
    <div class="topWrap">
      <div>Andrew Chen</div>
      <p>2020年03月31日</p>
    </div>
    <div class="con">
      {{ letter.cn_content }}
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {

  },
  data () {
    return {
      letter: {}
    }
  },
  computed: {

  },
  watch: {

  },
  created () {
    this.params = {
      limit_start: 0,
      limit_page_length: 10,
      filters: { node_category: this.$utils.newsCategory.letter }
    }
  },
  activated () {
    this.getNews()
  },
  mounted () {

  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.letter = true
      }
    })
  },
  methods: {
    getNews () {
      this.$api['home/news'](this.params)
        .then(res => {
          if (res.message) {
            this.letter = res.message[0]
          }
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    onClickLeft () {
      console.log(this.$router)
      if (this.letter) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped lang="less">
.letter{
    padding:46px 16px 0;

    h4{
        margin-top:15px;
    }
    p{
        margin:0
    }
    .topWrap{
        display:flex;
        justify-content: space-between;
        align-items: center;
        height:40px;
        p{
            color:#666
        }
    }
    .con{
        text-align:left;
        word-wrap: break-word;
        white-space: pre-wrap;
        font-size:14px;
    }
}
</style>
