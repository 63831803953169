<!-- @format -->

<!--@format
 by: wk 2018/12/18
 name: 页面组件——首页地图
 备注:
-->
<template>
  <div class="mapLine">
    <top-header :padding="true" :title="isShareDom?'全美疫情新增趋势图':'全美疫情趋势图'"></top-header>
    <div class="btnWrap" v-if="!isShareDom">
      <van-tag class="tag" :class="{active:active===0}" type="primary" @click="handleConfirm">确诊</van-tag>
      <van-tag class="tag" :class="{active:active===1}" type="primary" @click="handleDeadth">死亡</van-tag>
      <van-tag class="tag" :class="{active:active===2}" type="primary" @click="handleCure">治愈</van-tag>
    </div>
    <div class="map-wrap">
      <div :id="domId" :class="isShareDom?'shareDomMap':'map'"></div>
    </div>
  </div>
</template>

<script>

import { Toast } from 'vant'
import TopHeader from '@/components/TopHeader'
const echarts = require('echarts/lib/echarts')
require('echarts/lib/component/dataZoom')
require('echarts/lib/chart/line')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/legend')
require('echarts/lib/component/visualMap')

require('echarts/lib/component/title')

export default {
  components: { TopHeader },

  props: {
    domId: {
      default: 'mapLine',
      type: String
    },
    isShareDom: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      mapLineData: [],
      active: 0
    }
  },

  computed: {},

  created () {},

  mounted () {
    this._getMapLineData()
  },

  methods: {
    handleConfirm () {
      this.active = 0
      this._drawMap()
    },
    handleDeadth () {
      this.active = 1
      this._drawMap()
    },
    handleCure () {
      this.active = 2
      this._drawMap()
    },
    /**
     * @description: 拿到map学校数据
     * @param {type}
     * @return:
     */
    async _getMapLineData () {
      this.$api['home/mapLine']().then(res => {
        console.log(res, 'mapline')
        if (res.message) {
          this.mapLineData = {
            ...res.message,
            ncov_dates: res.message.ncov_dates.map(sitem => {
              return this.$utils.myMoment(sitem, 'MM-DD')
            })
          }

          this._drawMap()
        }
      }).catch(err => {
        console.log(err)
        Toast('服务器开小差了，请稍后再试')
      })
    },

    /**
     * @description: 绘制地图
     */
    _drawMap () {
      var dom = document.getElementById(this.domId)
      var myChart = echarts.init(dom)

      // const option = {

      //   tooltip: {
      //     trigger: 'axis',
      //     extraCssText: 'text-align:left;z-index:999'
      //   },
      //   legend: {
      //     data: this.active === 0 ? ['累计确诊', '新增确诊'] : this.active === 1 ? ['累计死亡', '新增死亡'] : ['累计治愈', '新增治愈'],
      //     type: 'plain',
      //     right: 10,
      //     top: 10
      //   },
      //   grid: {
      //     left: '3%',
      //     right: '4%',
      //     bottom: '3%',
      //     top: 40,
      //     containLabel: true
      //   },
      //   toolbox: {
      //     feature: {
      //       saveAsImage: {}
      //     }
      //   },
      //   xAxis: {
      //     type: 'category',
      //     boundaryGap: true,
      //     data: this.mapLineData.ncov_dates
      //   },
      //   yAxis: [
      //     {
      //       name: this.active === 0 ? '累计确诊' : this.active === 1 ? '累计死亡' : '累计治愈',
      //       type: 'value',
      //       nameTextStyle: {
      //         align: 'right'
      //       }
      //     },
      //     {
      //       name: this.active === 0 ? '新增确诊' : this.active === 1 ? '新增死亡' : '新增治愈',
      //       type: 'value',
      //       nameTextStyle: {
      //         align: 'left'
      //       }
      //     }],

      //   series: [
      //     {
      //       name: this.active === 0 ? '累计确诊' : this.active === 1 ? '累计死亡' : '累计治愈',
      //       type: 'line',
      //       yAxisIndex: 0,
      //       itemStyle: {
      //         color: '#af222e'
      //       },
      //       data: this.active ? this.mapLineData.ncov_confirmed_daily_new : this.mapLineData.ncov_confirmed
      //     },
      //     {
      //       name: this.active ? '新增治愈' : '累计治愈',
      //       type: 'line',
      //       itemStyle: {
      //         color: '#2ab7a4'
      //       },
      //       data: this.active ? this.mapLineData.ncov_recovered_daily_new : this.mapLineData.ncov_recovered
      //     },
      //     {
      //       name: this.active ? '新增死亡' : '累计死亡',

      //       type: 'line',
      //       itemStyle: {
      //         color: '#5d7091'
      //       },
      //       data: this.active ? this.mapLineData.ncov_deaths_daily_new : this.mapLineData.ncov_deaths
      //     }
      //   ]
      // }
      const option = {

        tooltip: {
          trigger: 'axis',
          extraCssText: 'text-align:left;z-index:999'
        },
        legend: {
          data: this.active === 0 ? ['累计确诊', '新增确诊'] : this.active === 1 ? ['累计死亡', '新增死亡'] : ['累计治愈', '新增治愈'],
          type: 'plain',
          x: 'center',

          top: 0

        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: 40,
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.mapLineData.ncov_dates.slice(this.myIndex)
        },
        yAxis: [
          {
            name: this.active === 0 ? '累计确诊' : this.active === 1 ? '累计死亡' : '累计治愈',
            type: 'value',
            nameTextStyle: {
              align: 'right'
            }
          },
          {
            name: this.active === 0 ? '新增确诊' : this.active === 1 ? '新增死亡' : '新增治愈',
            type: 'value',
            nameTextStyle: {
              align: 'left'
            }
          }
        ],
        series: [
          {
            name: this.active === 0 ? '累计确诊' : this.active === 1 ? '累计死亡' : '累计治愈',
            type: 'line',
            yAxisIndex: 0,
            itemStyle: {
              color: '#af222e'
            },
            data: this.active === 0 ? this.mapLineData.ncov_confirmed : this.active === 1 ? this.mapLineData.ncov_deaths : this.mapLineData.ncov_recovered
          },
          {
            name: this.active === 0 ? '新增确诊' : this.active === 1 ? '新增死亡' : '新增治愈',
            type: 'line',
            yAxisIndex: 1,
            itemStyle: {
              color: this.active === 0 ? '#fc8300' : this.active === 1 ? 'rgb(93, 112, 146)' : 'rgb(40, 183, 163)'
            },
            data: this.active === 0 ? this.mapLineData.ncov_confirmed_daily_new : this.active === 1 ? this.mapLineData.ncov_deaths_daily_new : this.mapLineData.ncov_recovered_daily_new
          }

        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>
<style lang="less" scoped>
// .mapWrap{
//   display:flex;
//   flex-direction:column;
//   justify-content: center;
//   align-items:center;
// }
.mapLine{
  margin-top:16px;
  margin-bottom:16px;
}
.map-wrap{
  padding:10px 0;
  background:#f7f7f7;
}
.shareDomMap{
  width:90vw;

  height:30vh;
  @media screen and (min-width: 768px){
    width:355px;

  }
}
.map {
  width: 100vw;
  height: 35vh;
  background: #f7f7f7;
  @media screen and (min-width: 768px){
    width:768px
  }

}
.img{
  width:100%;

}
.tips{
  font-size:12px;
  margin:10px 0 0 16px;
}
.btnWrap{
  display:flex;
  margin-bottom:10px;
  margin-left:16px;
  width:200px;
  justify-content: space-between;
  .tag{
    padding:5px 10px;
    font-size:14px;
    font-weight:bold;
    border-radius:15px;
    background:#fff;
    color:#666;
    border:1px solid #ccc;
    &.active{
      background:@primary-color;
      border:none;
      color:#fff
    }
  }
}
</style>
