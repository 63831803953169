<template>
  <div class="auth">
    <van-nav-bar
      fixed
      title="账户"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form label-align="left" input-align="right">
      <van-field
        v-model="form.username"
        name="用户名"
        label="用户名"
        placeholder="请输入用户名"
      />
      <van-field
        v-model="form.email"
        name="邮箱"
        label="邮箱"
        placeholder="请输入邮箱"
      />
      <van-field
        v-model="form.phone"
        type="tel"
        name="手机"
        label="手机"
        placeholder="请输入手机"
      />
      <div class="seperation"></div>
      <van-field
        v-model="form.firstName"
        name="姓"
        label="姓"
        placeholder="请输入姓"
      />
      <van-field
        v-model="form.lastName"
        name="名"
        label="名"
        placeholder="请输入名"
      />
      <van-field
        v-model="form.birth"
        name="出生日期"
        label="出生日期"
        placeholder="请输入出生日期"
      />
      <van-field
        v-model="form.location"
        name="所在地"
        label="所在地"
        placeholder="请输入所在地"
      />
    </van-form>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {

  },
  data () {
    return {
      form: {
        username: '',
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        birth: '',
        location: ''
      },

      showCalendar: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2020, 12, 31)
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.auth = true
      }

      console.log('fromvm schiool', from, vm)
    })
  },
  methods: {
    onClickLeft () {
      console.log(this.$router)
      if (this.auth) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    },
    onConfirm (value) {
      this.birth = value
      this.showCalendar = false
    }
  }
}
</script>

<style scoped lang="less">
.auth{
    padding-top:50px;
    .seperation{
        height:10px;
        background:#f1f1f1
    }
}
/deep/ .van-field__label{
    text-align:left;
}
</style>
