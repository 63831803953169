import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import plugin from '@/plugins'
import 'ant-design-vue/dist/antd.less'
import { List, Button, Icon, Spin, Select, Input, Empty, Tooltip, Timeline, Popover, Table } from 'ant-design-vue'
import { ActionSheet, Swipe, SwipeItem, ImagePreview, NavBar, Overlay, Tab, Tabs, Tag, Collapse, CollapseItem, Loading, Toast, Form, Field, Calendar, Sticky, List as vanList, PullRefresh, Popup, Button as vanButton, Lazyload } from 'vant'

import VueGtag from 'vue-gtag'
import './utils/filter'

// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  loading: require('@/assets/loading.jpeg'),
  error: require('@/assets/blog.png')
})
const Base64 = require('js-base64').Base64

Vue.use(ActionSheet)
Vue.use(Popup)
Vue.use(vanButton)

Vue.use(PullRefresh)

Vue.use(vanList)

Vue.use(Sticky)

Vue.use(Calendar)

Vue.use(Field)

Vue.use(Form)

Vue.prototype.$toast = Toast
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Loading)
Vue.use(Table)
// vant
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(ImagePreview)
Vue.use(NavBar)
Vue.use(Overlay)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Tag)

// antdesign
Vue.use(List)
Vue.use(Popover)

Vue.use(Button)
Vue.use(Timeline)

Vue.use(Select)
Vue.use(Spin)
Vue.use(Input)
Vue.use(Empty)
Vue.use(Tooltip)
Vue.use(Icon)

Vue.use(plugin)
Vue.config.productionTip = false

Vue.prototype.$enc = function (ainfo) {
  const data = Base64.encode(ainfo)

  return data
}

// 解密
Vue.prototype.$dec = function (aresdata) {
  var resData = Base64.decode(aresdata)

  return resData
}

Vue.use(VueGtag, {
  config: { id: 'UA-46217273-1' },
  pageTrackerTemplate (to) {
    return {
      page_title: 'coronavirus update ' + to.meta.title || '',
      page_path: location.pathname + location.hash
    }
  }
}, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
