/** @format */

import $api from '@/plugins/api';
const app = {
  state: {
    block: [],
    ad: {},
    stateList: [],
    stateData: {},
    schoolData: {},
    schoolList: [],
    authorData: {},
    orderState: [],
    orderSchool: [],
    orderAuthor: [],
    askTags: [],
    pageCount: '',
    newsAd: [],
    showSub: false,
    shareDomNews: {},
    welfareItem: {}
  },
  mutations: {
    SET_BLOCK(state, data) {
      state.block = data;
    },
    SET_AD(state, data) {
      state.ad = data;
    },
    SET_STATE_LIST(state, data) {
      state.stateList = data;
    },
    SET_STATE_DATA(state, data) {
      state.stateData = data;
    },
    SET_SCHOOL_LIST(state, data) {
      state.schoolList = data;
    },
    SET_SCHOOL_DATA(state, data) {
      state.schoolData = data;
    },
    SET_AUTHOR_DATA(state, data) {
      state.authorData = data;
    },
    SET_ORDER_STATE(state, data) {
      state.orderState = data;
    },
    SET_ORDER_SCHOOL(state, data) {
      state.orderSchool = data;
    },
    SET_ORDER_AUTHOR(state, data) {
      state.orderAuthor = data;
    },
    SET_ASK_TAGS(state, data) {
      state.askTags = data;
    },
    SET_PAGE_COUNT(state, data) {
      state.pageCount = data;
    },
    SET_NEWS_AD(state, data) {
      state.newsAd = data;
    },
    SET_SHOW_SUB(state, data) {
      state.showSub = data;
    },
    SET_SHARE_DOM_NEWS(state, data) {
      state.shareDomNews = data;
    },
    SET_WELFARE_ITEM(state, data) {
      state.welfareItem = data;
    }
  },
  actions: {
    GetBlock({ commit, state }) {
      return new Promise((resolve, reject) => {
        $api['home/block']()
          .then(res => {
            if (!res.message) {
              reject(new Error('error'));
            }
            commit('SET_BLOCK', res.message);
            resolve(res.message);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetAd({ commit, state }) {
      return new Promise((resolve, reject) => {
        $api['home/ad']()
          .then(res => {
            if (!res.message) {
              reject(new Error('error'));
            }
            commit('SET_AD', res.message);
            resolve(res.message);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    GetOrder({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        console.log(rootState, 'rootState');
        const loginId = rootState.user.loginId;
        $api['auth/subscript']({ subscriber: loginId })
          .then(res => {
            if (!res.message) {
              reject(new Error('error'));
            }
            console.log('thi.s', res);
            commit('SET_ORDER_STATE', res.message.State);
            commit('SET_ORDER_SCHOOL', res.message['nCov School Data']);
            commit('SET_ORDER_AUTHOR', res.message['Author Profile']);
            resolve(res.message);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetAskTags({ commit, state }, loginId) {
      return new Promise((resolve, reject) => {
        $api['home/askTags']()
          .then(res => {
            if (!res.message) {
              reject(new Error('error'));
            }
            commit('SET_ASK_TAGS', res.message);
            resolve(res.message);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetPageCount({ commit, state }, loginId) {
      return new Promise((resolve, reject) => {
        $api['home/pageCount']()
          .then(res => {
            if (!res.message) {
              reject(new Error('error'));
            }
            commit('SET_PAGE_COUNT', res.message.pageviews);
            resolve(res.message);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetNewsAd({ commit, state }) {
      return new Promise((resolve, reject) => {
        $api['home/newsAd']()
          .then(res => {
            if (!res.message) {
              reject(new Error('error'));
            }
            commit('SET_NEWS_AD', res.message);
            resolve(res.message);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetWelfareItem({ commit, state }) {
      const params = {
        limit_page_length: 1,
        filters: { node_category: '公益讲座-Seminar', seminar_poster: ['not in', [null, '']] }
      };
      return new Promise((resolve, reject) => {
        $api['home/news'](params)
          .then(res => {
            if (!res.message) {
              reject(new Error('error'));
            }
            if (res.message[0]) {
              commit('SET_WELFARE_ITEM', res.message[0]);
            }

            resolve(res.message);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};

export default app;
