<template>
  <div class="reportItem" @click="goToReportDetail">
    <div class="topWrap">
      <van-tag :color="tagType">{{ itemData.report_name }}</van-tag>
      <p>{{ itemData.publish_date }}</p>
    </div>
    <div class="con">
      <div v-html="itemData.rich_content"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    itemData: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {

    }
  },
  computed: {
    tagType () {
      const category = {
        求职日报: '#D84847',
        转升日报: '#2ea878',
        学术日报: '#e6a53d'
      }
      return category[this.itemData.report_name]
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    goToReportDetail () {
      this.$router.push({
        path: '/reportDetail',
        query: {
          item: this.itemData
        }

      })
    }
  }
}
</script>

<style scoped lang="less">
.reportItem{
    margin:0 16px;
    padding:16px 0;
    border-bottom:1px solid #ededed;
    .topWrap{
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:12px;
        p{
            margin:0;
            font-size:12px;
        }
        span{
            font-weight:bold;
            font-size:14px;
        }
    }
    .con{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        text-align:left;
        font-size:14px;
    }
}
</style>
