<template>
  <div class="ask refCon" id="Ask">
    <h2 class="title">热门问答</h2>
    <p class="label">各群里反复出现的热门讨论，我们已经把最佳答案收录到网页啦。</p>
    <!-- <div class="search">
      <a-input-search
        placeholder="输入想查询的问答"
        @search="onSearch"
        enterButton="搜索"

      />
    </div> -->

    <div class="tags">
      <div class="tagItem" :class="{activeTag:activeTag===0}" @click="clickTag()">全部</div>
      <div class="tagItem" :class="{activeTag:activeTag===index+1}" v-for="(item,index) in askTags" :key="item.name" @click="clickTag(item,index)">{{ item.name }}</div>
    </div>
    <van-collapse v-model="activeName" accordion @change="track(activeName)">
      <van-collapse-item
        :title="item.title"
        :name="index"
        v-for="(item,index) in listData"
        class="autoline"
        :key="item.name"
      >
        {{ item.cn_content }}
      </van-collapse-item>
    </van-collapse>
    <!-- <load-more @loadMore="loadMore" :length="listData.length" :hasMore="hasMore" :loading="loading"></load-more> -->
    <div style="padding:16px 16px 0;">
      <a-button
        class="btn"
        block
        type="primary"
        @click="loadMore"
        :loading="loading"
      >
        更多热门问答
      </a-button>
      <!-- <load-more slot="footer" @loadMore="loadMore" v-if="tableData.length" :hasMore="hasMore" :loading="loading"></load-more> -->
    </div>
  </div>
</template>

<script>
import { LoadMore } from '@/components'
import { mapMutations, mapState } from 'vuex'
export default {
  components: { LoadMore },
  props: {},
  data () {
    return {
      listData: [],

      activeTag: 0,
      activeName: '0',
      hasMore: true,
      loading: false

    }
  },
  computed: {
    ...mapState({
      askTags: state => state.app.askTags
    })
  },
  watch: {},
  created () {
    this.params = {
      limit_start: 0,
      limit_page_length: 5,
      filters: { node_category: this.$utils.newsCategory.ask }
    }
    this.getNews()
  },
  mounted () {},
  methods: {
    ...mapMutations({
      setAskTags: 'SET_ASK_TAGS'
    }),
    clickTag (item, index) {
      if (item) {
        this.activeTagName = item.name
        this.activeTag = index + 1
        this.params.limit_start = 0
        this.params.filters = { node_category: this.$utils.newsCategory.ask, tags: ['like', `%${item.name}%`] }
      } else {
        this.activeTagName = ''
        this.activeTag = 0
        this.params.filters = { node_category: this.$utils.newsCategory.ask }
      }

      this.getNews('tags')
    },
    async loadMore () {
      this.$router.push({
        path: '/hotAsk',
        query: {
          activeTag: this.activeTag,
          activeTagName: this.activeTagName
        }
      })
      // this.params.limit_start += this.params.limit_page_length
      // this.loading = true
      // await this.getNews()
      // this.loading = false
    },
    async getNews (type) {
      const res = await this.$api['home/news'](this.params)

      console.log(res, 'ask')
      if (res.message) {
        if (res.message.length < this.params.limit_page_length) {
          this.hasMore = false
        } else {
          this.hasMore = true
        }
        if (type) {
          this.listData = res.message
        } else {
          this.listData = [].concat(this.listData, res.message)
        }
      } else {
        this.listData = []
        this.hasMore = false
      }
    },

    track (activeName) {
      if (activeName) {
        this.$gtag.event('click_coronavirus_update_ask', {
          event_category: 'CoronavirusUpdateAsk',
          event_label: this.listData[activeName].title
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.ask{
  padding:0 16px;
  .label{
      font-size:12px;
      margin-bottom:8px;
    }
  .search{
    margin-bottom:10px;
  }
  .tags{
    display:flex;
    flex-wrap:wrap;
    justify-content: flex-start;
    align-items:center;
    .tagItem{
      width:fit-content;
      background:#f1f1f1;
      margin-right:8px;
      margin-bottom:10px;
      padding:3px 5px;
      font-size:12px;
      border-radius:4px;
      &.activeTag{
        background:@primary-color;
        color:#fff
      }
    }
  }
}
.btn{
  font-weight:bold;
}
/deep/ .van-cell__title {
  padding-left:10px;
}
/deep/ .van-cell--clickable{
  font-weight:bold;
  text-align:left;
  padding:10px 0;
}
/deep/ .van-collapse-item__title--expanded{
   background:#f7f7f7
}
/deep/ .van-collapse-item__content{
  color:#666;
  text-align:left;
  background:#f7f7f7
}
/deep/ .van-icon{
  color:#333;
  font-weight:bold;
}
.autoline {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
</style>
