<template>
  <div class="des">
    <p class="p">
      咱们留学生在美国信息资源和社会网络不足，<br />
      获得实习经历本就相对困难；<br />
      疫情下企业冻结招聘，机会更加稀缺。<br />
      缺实际经验，升学申请没有专业经历和推荐信......<br />
    </p>
    <h5 class="h5">  让简历出彩 · 打赢升学就业战役！</h5>
    <p class="p">
      此时的你，比任何时候都更需要一份实践机会。<br />
      到真实前沿企业实践，参与名校教授亲自指导的科研，<br />
      极速打造出彩的实践经历，事半功倍！
    </p>
    <h5 class="h5">疫情期间远程参与机会，安全、有效涨经验！</h5>
    <h6 class="h6">实训项目</h6>
    <p class="p">
      纽约世贸金融集团实训<br />
      曼哈顿投行全业务实训<br />
      精英会计审计实训<br />
      硅谷计算机及智能制造公司培训<br />
      国家实验室支持化工公司培训<br />
      新媒体广告传媒公司实训<br />
      劳伦斯自动驾驶展台设计<br />
      Google Teachable Machine课程设计项目<br />
      MIT 人工智能课程设计项目<br />
      建筑及土木工程管理培训
    </p>
    <h6 class="h6">科研项目</h6>
    <p class="p">
      CMU教授指导商业智能分析科研<br />
      CMU教授指导智慧工程科研<br />
      匹大医学中心生物医疗信息科研<br />
      约翰霍普金斯大学国际关系研究<br />
      社科类研究领域学习<br />
    </p>
    <div class="foot">
      <p>立即预约免费咨询</p><br />
      <p>早日收获重量级推荐信和出彩简历！</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.des{
    padding:0 16px;
    margin:16px 0 10px;
    h5{
        font-size:16px;
        font-weight:bold;
    }
    h6{
        background:#d6efd6;
        display:inline-block;
        font-size:14px;
    }
    p{
        color:#333;
        font-size:14px;
        margin-bottom:10px;
    }
    .foot{
        text-align:center;
        p{
            margin-bottom:5px;
            display:inline-block;
            background:#d6efd6;
            color:#333;

        }
    }
}
</style>
