export default [
  {
    name: 'subscript',
    method: 'GET',
    desc: '我的订阅',
    path: '/api/method/soa.ncov.api.coronavirus_my_subscriptions'
  },
  {
    name: 'handleSub',
    method: 'POST',
    desc: '单个订阅的增',
    path: '/api/method/soa.ncov.api.coronavirus_subscription',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  {
    name: 'cancelSub',
    method: 'DELETE',
    desc: '单个订阅的删',
    path: '/api/method/soa.ncov.api.coronavirus_subscription'
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    // }
  },
  {
    name: 'location',
    method: 'GET',
    desc: '地理位置获取',
    path: '/api/method/soa.ncov.api.locations_parse'
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    // }
  }
]
