<template>
  <div class="text-box">
    <div :class="['txt', 'over-hidden']" :style="{webkitLineClamp:line}" ref="textBox">
      <van-tag type="danger" class="tag" v-if="item.recommended">重要</van-tag>
      <span ref="spanBox" class="des" v-if="!search">{{ item.cn_content }}</span>
      <span v-else ref="spanBox" v-html="cn_content"></span>
    </div>
    <!-- <div class="btn" v-if="ifOver" @click="goToDetail">
      <span>更多</span>
      <i class="iconfont icon-62"></i>
    </div>-->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    search: {
      type: String,
      default: ''
    },

    line: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      ifOver: false
    }
  },
  computed: {
    cn_content () {
      const mySearch = `<span style="color:#333;background:#fff442;padding:1px 3px;">${this.search}</span>`
      return this.item.cn_content.replace(this.search, mySearch)
    }
  },
  watch: {},
  created () {},
  mounted () {
    // if (this.$refs.spanBox) {
    //   console.log(this.$refs.spanBox.offsetHeight, this.$refs.textBox, 'offset');
    //   if (this.$refs.spanBox.offsetHeight > this.$refs.textBox.offsetHeight) {
    //     this.ifOver = true;
    //   }
    // }
  },
  methods: {
    goToDetail () {
      this.$router.push({
        path: `/news/detail/${this.item.name}`
      })
    }
  }
}
</script>

<style scoped lang="less">
.text-box {
  text-align: left;
  .txt {
    color: #333;
  }
  .over-hidden {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }
  .des {
    font-size: 14px;
    color: #666;
  }

  .btn {
    color: #fc8300;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    i {
      margin-left: 5px;
    }
  }
}
.tag {
  margin-right: 5px;
  position: relative;
  top: -2px;
}
</style>
