<template>
  <div class="fang refCon" id="Fang">
    <h2 class="title">全美校园防疫措施</h2>
    <p class="des">
      美国的学校对新型肺炎疫情都有持续关注，也都和美国疾病防控中心CDC紧密合作。对于每所学校的具体情况与措施，都有发内部邮件给全体师生，提示、警告、更新信息。如果留学生已经在美国学校读书，请国内家长不要过分担心。如果学校对中国留学生的支持不足，请及时联系美国厚仁教育的顾问，我们的专家团会帮助学生保护自身权益。以下为美国部分高校的防疫措施，为方便阅读，我们为您做了中文概述。
    </p>
    <div class="list">
      <a-list :data-source="listData">
        <a-list-item slot="renderItem" slot-scope="item">
          <fang-item :item="item"></fang-item>
        </a-list-item>
        <load-more slot="footer" @loadMore="loadMore" v-if="listData.length" :has-more="hasMore" :loading="loading"></load-more>
      </a-list>
    </div>
  </div>
</template>

<script>
import { LoadMore } from '@/components';
import FangItem from '@/components/FangItem';
import { Toast } from 'vant';
export default {
  components: { LoadMore, FangItem },
  props: {},
  data() {
    return {
      listData: [],
      hasMore: true,
      loading: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.params = {
      limit_start: 0,
      limit_page_length: 5,
      order_by: 'isnull(latest_ranking_2022),length(latest_ranking_2022),latest_ranking_2022 asc'
      // filters: { node_category: '学校措施' }
    };
    this.getNews();
  },
  mounted() {},
  methods: {
    loadMore() {
      this.params.limit_start += this.params.limit_page_length;
      this.getNews();
    },
    getNews() {
      this.loading = true;
      this.$api['home/measure'](this.params)
        .then(res => {
          console.log(res, 'ameTable');
          if (res.message) {
            if (res.message.length < this.params.limit_page_length) {
              this.hasMore = false;
            }
            this.listData = [].concat(this.listData, res.message);
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(err => {
          console.log(err);
          Toast('服务器开小差了,防疫措施暂时无法获得，请稍后再试');
        });
    }
  }
};
</script>

<style scoped lang="less">
/deep/ .ant-list-item-content {
  width: 100%;
}
.fang {
  padding: 0 16px;
}

.des {
  text-align: left;
  font-size: 14px;
  color: #666;
}
</style>
