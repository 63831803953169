<template>
  <div class="stateWrap">
    <van-nav-bar
      fixed
      title="重点关注——州"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <Map></Map>
    <div style="padding:10px 0">
      <search-state></search-state>
    </div>

    <state-table :tab-height="46" :params="paramsState" request="home/map" :all-data="true" dom-id="stateListTable"></state-table>
  </div>
</template>

<script>
import { TopHeader } from '@/components'
import Map from '@/views/home/item/Map'
import StateTable from '../home/components/StateTable'
import SearchState from '../home/components/SearchState'
export default {
  components: {
    TopHeader, StateTable, SearchState, Map
  },
  props: {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created () {
    this.paramsState = {
      limit_start: 0,
      limit_page_length: 100
    }
  },
  mounted () {

  },
  activated () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.stateList = true
      }

      console.log('fromvm stae', from, vm)
    })
  },
  methods: {
    onClickLeft () {
      console.log(this.$router)
      if (this.stateList) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped lang="less">
.stateWrap{
    margin-top:50px;
}
</style>
