
<template>
  <div class="banner">
    <div style="padding:0 20px">
      <van-swipe class="my-swipe" :autoplay="3000" ref="vanSwipe" @change="change" :show-indicators="false">
        <van-swipe-item v-for="(item,index) in bannerData" :key="index" class="imgWrap" @click="showModal(index)">
          <img :src="$utils.checkImg(item)" alt="" class="img">
        </van-swipe-item>
        <div class="custom-indicator" slot="indicator">
          {{ current + 1 }}/{{ bannerData.length }}
        </div>
      </van-swipe>
    </div>
    <div class="iconWrap">
      <div @click="handlePrev">
        <a-icon type="left"></a-icon>
      </div>
      <div @click="handleNext">
        <a-icon type="right"></a-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { TopHeader } from '@/components'
import { ImagePreview } from 'vant'
export default {
  components: {
    TopHeader
  },
  props: {
    bannerData: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {

      current: 0
    }
  },
  computed: {
    imgData () {
      return this.bannerData.map(item => {
        return item.attach_image_url
      })
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    change (index) {
      this.cuurrent = index
    },
    handlePrev () {
      this.$refs.vanSwipe.prev()
    },
    handleNext () {
      this.$refs.vanSwipe.next()
    },
    showModal (index) {
      ImagePreview({
        images: this.imgData,
        startPosition: index

      })
    }
  }
}
</script>

<style scoped lang="less">
.banner{

    position:relative;
}
.imgWrap{
    background:#555;
    img{
        width:100%;
        object-fit:cover;

    }
}
.custom-indicator{
  position:absolute;
  bottom:0;
  left: 50%;
  transform: translate(-50%);
  color:#fff;
  font-weight:bold;
  font-size:16px;
  background:rgba(0,0,0,.4);
  padding:3px 5px;
}
.iconWrap{
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding:0 16px;
  width:100%;
  justify-content: space-between;
  align-items: center;
  display:flex;
  div{
    background:rgba(0,0,0,.4);
    width:40px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
    i{
      font-size:20px;
      color:#fff;
      font-weight:bold;
    }
  }
}
</style>
