<template>
  <div class="videoItem">
    <iframe
      :src="videoSrc"
      scrolling="no"
      border="0"
      frameborder="no"
      framespacing="0"
      allowfullscreen="true"
    > </iframe>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    itemData: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      src: ''
    }
  },
  computed: {
    videoSrc () {
      return this.itemData.bilibili_src_uri
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    pause () {

    }
  }
}
</script>

<style scoped lang="less">
.videoItem{
  width:100%;
  height:0;
  padding-top:57%;
  position:relative;
  iframe{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
  }
}
</style>
