/** @format */

import api from '@/plugins/api'

export default {
  state: {
    authorUser: null

  },
  mutations: {
    // 设置用户信息
    SET_AUTHOR_USER: (state, user) => {
      state.authorUser = user
    }

  },
  actions: {
    /**
     * @msg:拿到用户信息
     * @param {type}
     * @return: promise
     */
    GetWechatRedirect ({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        api['wechat/redirect'](params)
          .then(res => {
            console.log(res, 'wechat')
            if (!res.data.display) {
              reject(new Error('error'))
            }

            commit('SET_AUTHOR_USER', res.data.display)

            resolve(res.data.display)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
