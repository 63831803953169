<template>
  <article class="share-card" id="shareDom" v-show="isShowShareDom">
    <h1 class="title"># 美国校园疫情动态 实时更新 #</h1>
    <div class="content">
      <Count :is-share-dom="true"></Count>
      <div class="table">
        <div class="tableHeader">
          <p class="name">学校名称</p>
          <p class="confirm">排名</p>
          <p class="course">复学安排</p>
          <!-- <p class="online">结束</p> -->
          <p class="detail">确诊数字</p>
        </div>
        <div class="tableItem" v-for="item in tableData" :key="item.name">
          <p class="name" @click="goToSchool(item)">
            <span class="detail-text"
              >{{ item.school_cn }}<van-tag class="tips " v-if="item.news_count">+{{ item.news_count }}</van-tag></span
            >
          </p>
          <p class="confirm">{{ item.latest_ranking_2022 || 0 }}</p>
          <p class="course">
            <span v-if="item.fall_2020_policy_category">{{ item.fall_2020_policy_category }}</span
            ><span v-else>暂无</span>
            <a-tooltip v-if="item.fall_2020_policy_cn">
              <template slot="title">
                {{ item.fall_2020_policy_cn }}
              </template>
              <i class="iconfont icon-question"></i>
            </a-tooltip>
          </p>
          <!-- <p class="online"><span v-if="item.online_course_end">{{ item.online_course_end | moment('MM/DD') }}</span><span v-else>暂无</span> </p> -->
          <p class="detail">{{ item.ncov_confirmed || '暂无' }}</p>
        </div>
      </div>
      <div class="welfare">
        <top-header title="公益讲座"></top-header>

        <h3 class="welfare-title">{{ welfare.title }}</h3>
        <p class="welfare-label" v-if="welfare.seminar_date">{{ welfare.seminar_date }}</p>
      </div>
      <MapLine :is-share-dom="true" dom-id="shareDomMapLine"></MapLine>
      <!-- <Map :isShareDom="true" domId="shareDomMap"></Map> -->

      <!-- <News from="shareDom"></News> -->
    </div>
    <footer class="footer">
      <div class="right">
        <img src="@/assets/web-qrcode.png" class="qr-code" />
        <div class=" qr-help">扫码 查看详情</div>
      </div>
      <div class="left">
        <div class="leftConWrap">
          <div class=" title1">全美、各州、定点学校</div>
          <div class=" title1">疫情趋势及应对措施</div>
          <div class=" title2">更多热点：使馆信息｜疫苗进展｜联邦新闻｜身份/出入境｜机票行情｜实习就业新闻</div>
        </div>
        <div class="logo">
          <img src="@/assets/logo-white.png" />
          <div>美国厚仁教育</div>
        </div>
      </div>
    </footer>
  </article>
</template>

<script>
import { TopHeader } from '@/components';
import { Map, Count, News, MapLine } from '../views/home/item';

import WelfareItem from '../views/home/components/WelfareItem';
export default {
  components: {
    Map,
    Count,
    News,
    WelfareItem,
    TopHeader,
    MapLine
  },
  props: {},
  data() {
    return {
      isShowShareDom: true,
      tableData: [],
      welfare: {}
    };
  },
  computed: {},
  watch: {},
  created() {
    this.params = {
      limit_start: 0,
      limit_page_length: 5,
      // filters: { latest_ranking_2022: ['!=', ''] },

      order_by: 'isnull(latest_ranking_2022),length(latest_ranking_2022),latest_ranking_2022 asc,ncov_confirmed desc'
    };
    this.paramsWelfare = {
      limit_start: 0,
      limit_page_length: 1,
      filters: { node_category: this.$utils.newsCategory.welfare }
    };

    this._getSchoolData();
    this._getWelfareData();
  },
  mounted() {
    this.$bus.$on('isShowShareDom', data => {
      this.isShowShareDom = data.show;
    });
    this.isShowShareDom = false;
  },
  methods: {
    _getSchoolData() {
      this.$api['home/measure'](this.params)
        .then(res => {
          console.log(res, 'schoolTable');
          if (res.message) {
            this.tableData = res.message;
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    },
    _getWelfareData() {
      this.$api['home/news'](this.paramsWelfare)
        .then(res => {
          console.log(res, 'welfare');
          if (res.message) {
            this.welfare = res.message[0];
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }
};
</script>

<style scoped lang="less">
.share-card {
  display: flex;
  background-image: linear-gradient(to right, #593ae2, #499fe7);
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
  // background: rgb(73, 123, 137);
  // background: rgb(28, 145, 177);

  padding: 10px;
  .title {
    color: #fff;
    font-size: 18px;
  }
  @media screen and (min-width: 576px) {
    article {
      width: 375px;
    }
  }
  .content {
    width: 100%;
    background: rgb(255, 255, 255);
    border-radius: 6px;
    padding: 10px 5px 20px;
    .table {
      margin-top: 30px;
    }
    .tableHeader {
      display: flex;
      align-items: center;
      background: #f7f7f7;
      height: 40px;
      &.tableFix {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        z-index: 10;
      }
      p {
        height: 100%;
        margin: 0;
        line-height: 40px;
        text-align: center;
        font-weight: bold;
        color: #333;
      }
      .name {
        background: #e3e7f3;
        width: 35%;
      }
      .confirm {
        flex: 1;
        background: #e69a8d;
      }
      .course {
        width: 30%;
        background: #95db9a;
      }
      .online {
        flex: 1;
        background: #b4c0d5;
      }
      .detail {
        width: 20%;
        background: #e3e7f3;
      }
    }
    .tableItem {
      display: flex;
      align-items: center;
      background: #f7f7f7;
      border-bottom: 1px solid #e8e8e8;
      min-height: 40px;
      p {
        height: 100%;
        margin: 0;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
        background: #f7f7f7;
      }
      .name {
        width: 35%;
        cursor: pointer;
        .detail-text {
          position: relative;
          right: 10px;
          color: @primary-color;
        }
        .tips {
          position: absolute;
          right: -30px;
          top: 0;
          background: #ecf0fc;
          color: #4169e2;
          margin-left: 10px;
          border-radius: 5px;
        }
      }
      .confirm {
        flex: 1;
      }
      .course {
        // font-weight:normal;
        width: 30%;
      }
      .online {
        font-weight: normal;
        flex: 1;
      }
      .detail {
        width: 20%;
      }
    }
  }
  .footer {
    display: flex;
    font-size: 12px;
    color: rgb(255, 255, 255);
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 20px;
    height: 118px;
    .left {
      font-weight: bold;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: justify;
      justify-content: space-between;
      text-align: left;
      align-items: center;
      margin-right: 20px;
      position: relative;
      .leftConWrap {
        margin-left: 15px;
      }
      .logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 5px;
        display: flex;
        img {
          width: 20px;
          height: 20px;
        }
      }

      .title1 {
        font-size: 14px;
        color: #fff;
      }
      .title2 {
        font-size: 12px;
        margin-top: 5px;
      }
    }
    .right {
      img {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
      }
      .qr-help {
        text-align: center;
      }
    }
  }
}
.welfare {
  padding-left: 16px;
  margin-top: 10px;
  .welfare-title {
    text-align: left;
    font-weight: 400;
  }
}
.icon-question {
  color: #fc8300;
  font-size: 12px;
}
</style>
