<template>
  <div class="news">
    <van-nav-bar
      fixed
      title="疫苗进展"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- <Sticky :offsetTop="50"></Sticky> -->
    <div class="list">
      <a-timeline>
        <flow-list url="home/news" :params="params" :sep="false">
          <template slot-scope="row">
            <div>
              <a-timeline-item>
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="row.itemData"></news-item>
              </a-timeline-item>
              <a-timeline-item v-if="row.myIndex===1">
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="newsAd[0]"></news-item>
              </a-timeline-item>
              <a-timeline-item v-if="row.myIndex===6">
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="newsAd[1]"></news-item>
              </a-timeline-item>
            </div>
          </template>
        </flow-list>
      </a-timeline>
    </div>
  </div>
</template>

<script>

import { FlowList, Sticky, NewsItem } from '@/components'
import { mapState } from 'vuex'
import { backInitMixin } from '@/utils/mixin'
export default {
  components: {
    FlowList, Sticky, NewsItem
  },
  mixins: [backInitMixin],
  props: {

  },
  data () {
    return {
      name: ''
    }
  },
  computed: {
    ...mapState({
      newsAd: state => state.app.newsAd
    })
  },

  watch: {
  },
  created () {
    this.params = {
      limit_start: 0,
      limit_page_length: 10,
      filters: { node_category: this.$utils.newsCategory.vaccine }

    }
  },
  activated () {
    // this.track()
  },
  mounted () {

  },
  methods: {

    track () {
      this.$gtag.event('go_coronavirus_update_news', {
        event_category: 'CoronavirusUpdateNews',
        event_label: this.$utils.newsCategory.news
      })
    }
  }
}
</script>

<style scoped lang="less">
.news{
  padding-top:60px;
  position:relative;
}
.list{
  padding:0 16px;
}
.dot{
  width:14px;
  height:14px;
  border-radius:7px;
  background:#c7d4f7;
  display:flex;
  justify-content: center;
  align-items: center;
  div{
    width:6px;
    height:6px;
    border-radius:3px;
    background:#3f69e2;
  }
}
/deep/ .van-pull-refresh{
  overflow:visible
}
/deep/ .ant-timeline-item-tail{
  border-left-color:#c7d4f7
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail{
  display:block
}
</style>
