
<template>
  <div class="practiceTab" v-if="practiceTab4.length">
    <top-header color="#f5631e" :padding="true" title="实习项目"></top-header>
    <div class="itemWrap">
      <practice-item v-for="item in practiceTab4" :key="item.name" :item-data="item"></practice-item>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader'
import PracticeItem from '../components/PracticeItem'
import { mapState } from 'vuex'
export default {
  components: {
    TopHeader, PracticeItem
  },
  props: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      practiceTab4: state => state.searchArea.practiceTab4
    })
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.itemWrap{
  padding:0 16px;
  margin-top:10px;
}
</style>
