import $api from '@/plugins/api';
import * as $utils from '@/utils';
import moment from 'moment-timezone';
$api['home/ad']().then(res => {
  if (res.message) {
    openAdv(res.message['NCOV-AD-PL-00007']);
  }
});
require('./main.js');

function openAdv(res) {
  if (!res.ncov_ad_slides[0]) {
    return;
  }

  const lastDate = moment($utils.getStorage('date'));
  const nowDate = moment();
  const diff = nowDate.diff(lastDate, 'hours');

  if (diff <= 3) {
    return;
  } else {
    $utils.setStorage('date', nowDate);
  }

  const entryAdv = document.getElementById('entryAdv');
  const entryTim = document.getElementById('entryTim');

  entryAdv.style.display = 'block';
  entryAdv.style.background = `url(${res.ncov_ad_slides[0].image_url})`;
  entryAdv.style.backgroundSize = 'cover';
  // 阻止滑动执行
  document.body.ontouchmove = function(ev) {
    ev.preventDefault();
  };

  let time = 5;

  const timer = setInterval(() => {
    if (time <= 0) {
      clearInterval(timer);
      entryAdv.style.display = 'none';
      // document.body.style.overflowY = 'auto';
      document.body.ontouchmove = function(ev) {
        ev.stopPropagation();
      };
    }
    entryTim.innerHTML = '跳过 ' + time + 's';

    time--;
  }, 1000);
  entryTim.addEventListener(
    'click',
    function(ev) {
      ev.preventDefault();
      clearInterval(timer);
      entryAdv.style.display = 'none';

      // document.body.style.overflowY = 'auto';
      document.body.ontouchmove = function(ev) {
        ev.stopPropagation();
      };
    },
    false
  );
}
