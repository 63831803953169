<template>
  <div class="list refCon">
    <top-header title="热点新闻" :has-right="true" @handleRight="goToNews"></top-header>

    <a-timeline style="margin-top:20px">
      <a-timeline-item v-for="item in tableData" :key="item.name">
        <div slot="dot" class="dot">
          <div></div>
        </div>
        <news-item :item-data="item" :from="from"></news-item>
      </a-timeline-item>
    </a-timeline>
    <div style="padding:16px 16px 0;" v-if="from!=='shareDom'">
      <a-button
        class="btn"
        block
        type="primary"
        @click="goToNews"
      >
        更多新闻
      </a-button>
    </div>
  </div>
</template>

<script>
import { LoadMore } from '@/components'
import TopHeader from '@/components/TopHeader'
import NewsItem from '@/components/NewsItem'

import { Toast } from 'vant'
export default {
  components: {
    LoadMore, NewsItem, TopHeader
  },
  props: {
    from: {
      default: 'home',
      type: String
    }
  },
  data () {
    return {
      tableData: []

    }
  },
  computed: {

  },
  watch: {

  },
  created () {
    this.params = {
      limit_start: 0,
      limit_page_length: this.from === 'shareDom' ? 1 : 3,
      filters: this.from === 'home' ? { node_category: this.$utils.newsCategory.all } : { recommended: 'Index Top Slide' }
      // filters: { recommended: 'Index Top Slide' }
    }

    this.getNews()
  },
  activated () {

  },
  mounted () {

  },
  methods: {
    loadMore () {
      this.params.limit_start += this.params.limit_page_length
      this.getNews()
    },
    getNews () {
      this.$api['home/news'](this.params)
        .then(res => {
          console.log(res, 'ameTable')
          if (res) {
            if (res.message.length < this.params.limit_page_length) {
              this.hasMore = false
            }
            this.tableData = [].concat(this.tableData, res.message)
          }
        })
        .catch(err => {
          console.log(err)
          Toast('服务器开小差了,校园疫情暂时无法获得，请稍后再试')
        })
    },
    goToNews () {
      this.$router.push({
        path: 'news'
      })
    }

  }
}
</script>

<style scoped lang="less">
.list{
    padding:10px 16px 0;
    .autoline {
      word-wrap: break-word;
      white-space: pre-wrap;
    }
    .link{
      font-weight:bold;
      a{
         color:#44c477
      }

    }
}
.dot{
  width:14px;
  height:14px;
  border-radius:7px;
  background:#c7d4f7;
  display:flex;
  justify-content: center;
  align-items: center;
  div{
    width:6px;
    height:6px;
    border-radius:3px;
    background:#3f69e2;
  }
}
/deep/ .ant-timeline-item-tail{
  border-left-color:#c7d4f7
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail{
  display:block
}
</style>
