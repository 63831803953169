<template>
  <div class="visa">
    <div class="title-wrap">
      <h3>最新签证信息</h3>
      <span>更新</span>
    </div>
    <p class="label">每天为您更新各使馆最早可预约F1签证的日期</p>
    <div class="list-wrap">
      <div class="list-item" v-for="(item, index) in list" :key="index">
        <VisaItem :item="item"></VisaItem>
      </div>
      <div class="last-wrap">
        <div class="singapore-wrap">
          <div class="item-title">新加坡</div>
          <div class="label-wrap">
            <span class="label-value" v-if="visa.visa_singapore">{{ visa.visa_singapore | moment('YYYY年MM月DD日') }}</span>
            <span class="label-label" v-else>暂无可选预约</span>
          </div>
        </div>
        <div class=" more" @click="goToMore">
          <span>更多</span>
          <a-icon type="right"></a-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VisaItem from '../components/VisaItem';
export default {
  components: { VisaItem },
  props: {},
  data() {
    return {
      visa: {}
    };
  },
  computed: {
    list() {
      return [
        {
          title: '北京',
          time: this.visa.visa_beijing,
          grad: this.visa.visa_grad_beijing
        },
        {
          title: '广州',
          time: this.visa.visa_guangzhou,
          grad: this.visa.visa_grad_guangzhou
        },
        {
          title: '沈阳',
          time: this.visa.visa_shenyang,
          grad: this.visa.visa_grad_shenyang
        },
        // {
        //   title: '成都',
        //   time: this.visa.visa_chengdu
        // },
        {
          title: '上海',
          time: this.visa.visa_shanghai,
          grad: this.visa.visa_grad_shanghai
        }
      ];
    }
  },
  watch: {},
  created() {
    this.getVisa();
  },
  mounted() {},
  methods: {
    goToMore() {
      this.$router.push({
        path: '/news',
        query: {
          item: {
            name: '身份/出入境',
            tips: this.$utils.newsCategory.identity,
            key: 'identity'
          },
          index: 7
        }
      });
    },
    getVisa() {
      this.$api['home/visa']()
        .then(res => {
          console.log(res, 'video');
          if (res.message) {
            this.visa = res.message;
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }
};
</script>

<style scoped lang="less">
.visa {
  padding: 10px 16px;

  .title-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      margin-right: 10px;
      color: #333;
    }
    span {
      font-size: 12px;
      color: #999;
    }
  }
  .label {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  .list-wrap {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    .list-item {
      // width: 30%;
      border: 1px solid #ededed;
      margin-bottom: 15px;
      box-shadow: 2px 2px 5px #ededed;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(2n) {
        /deep/ .item-title {
          background: #4194e2;
        }
      }
    }
  }
  .last-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .singapore-wrap {
      border-radius: 6px;
      flex: 1;
      display: flex;
      box-shadow: 2px 2px 5px #ededed;
      border: 1px solid #ededed;
      .item-title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 30px;
        background: @primary-color;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        color: #fff;
        font-weight: bold;
      }

      .label-wrap {
        flex: 1;

        text-align: center;
        line-height: 30px;

        .label {
          color: @primary-color;
        }
      }
    }
    .more {
      flex-direction: row;
      margin-left: 20px;
      span {
        font-size: 14px;
        color: @primary-color;
        font-weight: bold;
      }
      i {
        color: @primary-color;
        font-weight: bold;
      }
    }
  }
}
</style>
