import Home from '@/views/home/Home';

import VideoDetail from '@/views/home/VideoDetail';
import HotAsk from '@/views/home/HotAsk';
import News from '@/views/home/News';
import NewsSlide from '@/views/home/NewsSlide';

import Protect from '@/views/home/Protect';
import Welfare from '@/views/home/Welfare';
import WelfareDetail from '@/views/home/WelfareDetail';

export default [
  {
    path: '/',
    name: 'index',
    component: Home,
    meta: { title: '首页' }
  },

  {
    path: '/videoDetail',
    name: 'videoDetail',
    component: VideoDetail,
    meta: { title: '视频详情' }
  },
  {
    path: '/hotAsk',
    name: 'hotAsk',
    component: HotAsk,
    meta: { title: '热门问答详情' }
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: { title: '疫情动态' }
  },
  {
    path: '/newsSlide',
    name: 'newsSlide',
    component: NewsSlide,
    meta: { title: '重要播报' }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('@/views/home/NewsDetail'),
    meta: { title: '新闻详情' }
  },

  {
    path: '/protect',
    name: 'protect',
    component: Protect,
    meta: { title: '辟谣与防护' }
  },
  {
    path: '/welfare',
    name: 'welfare',
    component: Welfare,
    meta: { title: '公益讲座' }
  },
  {
    path: '/welfareDetail',
    name: 'welfareDetail',
    component: WelfareDetail,
    meta: { title: '公益讲座详情' }
  },
  {
    path: '/expert',
    name: 'expert',
    component: () => import('@/views/home/expert/Expert'),
    meta: { title: '专家专栏' }
  },
  {
    path: '/expert/:id',
    name: 'expertDetail',
    component: () => import('@/views/home/expert/ExpertDetail'),
    meta: { title: '专家专栏详情' }
  },

  {
    path: '/author',
    name: 'author',
    component: () => import('@/views/home/expert/AuthorList'),
    meta: { title: '专家列表' }
  },
  {
    path: '/author/:id',
    name: 'authorDetail',
    component: () => import('@/views/home/expert/AuthorDetail'),
    meta: { title: '专家详情' }
  },
  {
    path: '/covid',
    name: 'covid',
    component: () => import('@/views/home/covid/Covid'),
    meta: { title: '疫情专区' }
  }
];
