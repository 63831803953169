<template>
  <div class="ask" id="Ask">
    <van-nav-bar
      fixed
      title="热门问答"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <p class="label">各群里反复出现的热门讨论，我们已经把最佳答案收录到网页啦。</p>
    <div style="margin-bottom:15px;">
      <a-input-search
        placeholder="搜索问答内容"
        @search="onSearch"
        v-model="paramsSearch.search"
        enter-button="搜索"
      />
    </div>

    <div class="tags">
      <div class="tagItem" :class="{activeTag:activeTag===0}" @click="clickTag()">全部</div>
      <div class="tagItem" :class="{activeTag:activeTag===index+1}" v-for="(item,index) in askTags" :key="item.name" @click="clickTag(item.name,index)">{{ item.name }}</div>
    </div>
    <van-collapse v-model="activeName" accordion @change="track(activeName)">
      <van-collapse-item
        :name="index"
        v-for="(item,index) in listData"
        class="autoline"
        :key="item.name"
      >
        <template #title>
          <div v-html="$utils.highLight(item.title,paramsSearch.search)"></div>
        </template>
        <template #default>
          <div v-html="$utils.highLight(item.cn_content,paramsSearch.search)"></div>
        </template>
      </van-collapse-item>
    </van-collapse>
    <load-more @loadMore="loadMore" :length="listData.length" :has-more="hasMore" :loading="loading"></load-more>
  </div>
</template>

<script>
import { LoadMore } from '@/components'
import { mapState } from 'vuex'
export default {
  name: 'HotAsk',
  components: { LoadMore },
  props: {},
  data () {
    return {
      listData: [],

      activeTag: 0,
      activeName: '0',
      hasMore: true,
      loading: false,
      paramsSearch: {
        limit_start: 0,
        limit_page_length: 100,
        search: '',
        doctype: 'nCov Node',
        filters: { node_category: this.$utils.newsCategory.ask }
      }
    }
  },
  computed: {
    ...mapState({
      askTags: state => state.app.askTags
    })
  },
  watch: {},
  created () {
    this.params = {
      limit_start: 0,
      limit_page_length: 100,
      filters: { node_category: this.$utils.newsCategory.ask }
    }
  },
  mounted () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0

    const activeTag = this.$route.query.activeTag
    const activeTagName = this.$route.query.activeTagName
    this.activeTag = activeTag
    this.clickTag(activeTagName, activeTag - 1)
  },
  methods: {
    async onSearch (value) {
      this.paramsSearch.limit_start = 0
      this.activeTag = -1
      if (value) {
        this.paramsSearch.search = value
      } else {
        this.paramsSearch.search = ''
      }

      await this.getSearch()
    },
    clickTag (item, index) {
      this.paramsSearch.search = ''
      if (item) {
        this.activeTag = index + 1
        this.params.limit_start = 0
        this.params.filters = { node_category: this.$utils.newsCategory.ask, tags: ['like', `%${item}%`] }
      } else {
        this.activeTag = 0
        this.params.filters = { node_category: this.$utils.newsCategory.ask }
      }

      this.getNews('tags')
    },
    async loadMore () {
      this.params.limit_start += this.params.limit_page_length
      this.loading = true
      await this.getNews()
      this.loading = false
    },
    async getSearch () {
      const res = await this.$api['home/search'](this.paramsSearch)
      if (res.message) {
        this.listData = res.message
      }
    },
    async getNews (type) {
      const res = await this.$api['home/news'](this.params)

      console.log(res, 'ask')
      if (res.message) {
        if (res.message.length < this.params.limit_page_length) {
          this.hasMore = false
        } else {
          this.hasMore = true
        }
        if (type) {
          this.listData = res.message
        } else {
          this.listData = [].concat(this.listData, res.message)
        }
      } else {
        this.listData = []
        this.hasMore = false
      }
    },

    track (activeName) {
      if (activeName) {
        this.$gtag.event('click_coronavirus_update_ask', {
          event_category: 'CoronavirusUpdateAsk',
          event_label: this.listData[activeName].title
        })
      }
    },
    onClickLeft () {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="less">
.ask{
  padding:0 16px;
  margin-top:50px;
  .label{
      font-size:12px;
      margin-bottom:15px;
      padding-top:15px;
    }
  .search{
    margin-bottom:10px;
  }
  .tags{
    display:flex;
    flex-wrap:wrap;
    justify-content: flex-start;
    align-items:center;
    .tagItem{
      width:fit-content;
      background:#f1f1f1;
      margin-right:8px;
      margin-bottom:10px;
      padding:3px 5px;
      font-size:12px;
      border-radius:4px;
      &.activeTag{
        background:@primary-color;
        color:#fff
      }
    }
  }
}

/deep/ .van-cell__title {
  padding-left:10px;
}
/deep/ .van-cell--clickable{
  font-weight:bold;
  text-align:left;
  padding:10px 0;
}
/deep/ .van-collapse-item__title--expanded{
   background:#f7f7f7
}
/deep/ .van-collapse-item__content{
  color:#666;
  text-align:left;
  background:#f7f7f7
}
/deep/ .noMore{
  text-align:center;
  margin-top:20px;
}

.autoline {
    word-wrap: break-word;
    white-space: pre-wrap;
    /deep/ .van-icon{
        color:#333;
        font-weight:bold;
    }
  }
</style>
