<template>
  <div class="backWork">
    <top-header title="复工通知" />
    <div class="topWrap">
      <div class="topTitleWrap">
        <p class="topTitle">
          居家令/复工安排
        </p>
        <van-tag v-if="stateData.reopen_status === '复工中'" class="topTips tip1">
          {{ stateData.reopen_status }}
        </van-tag>
        <van-tag v-if="stateData.reopen_status === '即将复工'" class="topTips tip2">
          {{ stateData.reopen_status }}
        </van-tag>
        <van-tag v-if="stateData.reopen_status === '居家隔离'" class="topTips tip3">
          {{ stateData.reopen_status }}
        </van-tag>
      </div>
      <p class="topCon autoline">
        {{ stateData.state_policy }}
      </p>
    </div>
    <div v-if="stateData.reopened_industry" class="centerWrap">
      <p class="centerTitle">
        已复工行业
      </p>
      <p class="centerCon autoline">
        {{ stateData.reopened_industry }}
      </p>
    </div>
    <div v-if="stateData.reopen_soon_industry" class="bottomWrap">
      <p class="bottomTitle">
        即将复工行业
      </p>
      <p class="bottomCon autoline">
        {{ stateData.reopen_soon_industry }}
      </p>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import { mapGetters } from 'vuex';
export default {
  components: {
    TopHeader
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['stateData'])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="less">
p {
  margin: 0;
}
.backWork {
  padding: 0 16px;
  .topWrap {
    padding: 10px 0 16px;
    border-bottom: 1px solid #f1f1f1;
    .topTitleWrap {
      display: flex;
      flex-direction: row;
      .topTitle {
        font-weight: bold;
        color: #333;
        margin-right: 20px;
      }
      .topTips {
        background: #e1e1e1;
        color: #333;
        font-size: 14px;
        &.tip1 {
          background: #02a7f0;
        }
        &.tip2 {
          background: #81d3f8;
        }
        &.tip3 {
          background: #fddd52;
        }
      }
    }
    .topCon {
      margin-top: 16px;
    }
  }
  .centerWrap {
    padding: 16px 0;
    border-bottom: 1px solid #f1f1f1;
    .centerTitle {
      font-weight: bold;
      color: #333;
    }
    .centerCon {
      color: #666;
      margin-top: 16px;
    }
  }
  .bottomWrap {
    padding: 16px 0;
    .bottomTitle {
      font-weight: bold;
      color: #333;
    }
    .bottomCon {
      color: #666;
      margin-top: 16px;
    }
  }
}
</style>
