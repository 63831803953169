var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('ShareDomNews'),_c('div',{staticStyle:{"min-height":"70vh","background":"#fff"}},[_c('keep-alive',{attrs:{"exclude":[
        'StateMap',

        'VideoDetail',
        'HotAsk',
        'Subscription',
        'ReportDetail',
        'SchoolList',
        'WelfareDetail',
        'ExpertDetail',
        'AuthorDetail',
        'NewsDetail',
        'AuthorList'
      ]}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),_c('foot'),_c('handle-sub'),_c('show-welfare',{ref:"welfare"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }