<template>
  <div class="tableWrap">
    <top-header :padding="true" title="校园列表"></top-header>

    <div class="table" id="stateTable">
      <div v-show="tableFix" class="tableHeader"></div>
      <div class="tableHeader" :class="{ tableFix: tableFix }">
        <p class="name">学校名称</p>
        <p class="confirm">排名</p>
        <p class="course">复学安排</p>
        <p class="detail">确诊数字</p>
      </div>
      <div class="tableItem" v-for="item in handleSchoolData" :key="item.name">
        <p class="name" @click="goToSchool(item)">
          <span class="detail-text">
            {{ item.school_cn }}<van-tag class="tips " v-if="item.news_count">+{{ item.news_count }}</van-tag>
          </span>
        </p>
        <p class="confirm">{{ item.latest_ranking_2022 }}</p>
        <p class="course">
          <span v-if="item.fall_2020_policy_category">{{ item.fall_2020_policy_category }} </span><span v-else>暂无</span>
          <a-tooltip v-if="item.fall_2020_policy_cn">
            <template slot="title">
              {{ item.fall_2020_policy_cn }}
            </template>
            <i class="iconfont icon-question"></i>
          </a-tooltip>
        </p>
        <!-- <p class="online"><span v-if="item.online_course_end">{{ item.online_course_end | moment('MM/DD') }}</span><span v-else>暂无</span> </p> -->
        <p class="detail">{{ item.ncov_confirmed || '暂无' }}</p>
      </div>
    </div>

    <div v-if="allSchoolData.length > 5 && !isShareDom" class="bottomWrap">
      <div v-if="isShowMore" @click="showMore" class="show">查看全部</div>
      <div v-else @click="hideMore" class="hide">隐藏</div>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import { mapMutations } from 'vuex';
export default {
  components: {
    TopHeader
  },
  props: {
    isShareDom: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      stateData: {},
      isShowMore: true,
      schoolData: [],
      loading: false,
      tableFix: false
    };
  },
  computed: {
    handleSchoolData() {
      if (this.schoolData.length > 0) {
        return this.schoolData.map(item => {
          return {
            ...item
          };
        });
      } else {
        return [];
      }
    }
  },
  watch: {},
  created() {
    this.params = {
      with_school: 1,
      filters: { name: '' }
    };
    this.topHeight = 46;
    this.allSchoolData = [];
  },
  mounted() {},
  activated() {
    window.addEventListener('scroll', this.onScroll);
    this.initCheck();
  },
  deactivated() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    ...mapMutations({
      setStateData: 'SET_STATE_DATA'
    }),
    initCheck() {
      const name = this.$route.query.name;

      if (name !== this.params.filters.name) {
        this.params = {
          with_school: 1,
          filters: { name: name }
        };
        this.isShowMore = true;
        this._getSchoolData();
      }
    },
    _getSchoolData() {
      this.loading = true;
      this.$api['home/map'](this.params).then(res => {
        console.log(res, 'statemap');
        if (res.message) {
          this.stateData = res.message[0];
          this.setStateData(res.message[0]);
          this.$bus.$emit('getStateName', { name: res.message[0].cn_state, id: res.message[0].name });
          if (res.message[0].school.length <= 5) {
            this.schoolData = res.message[0].school;
          } else {
            this.allSchoolData = res.message[0].school;
            if (this.isShareDom) {
              this.schoolData = res.message[0].school.slice(0, 2);
            } else {
              this.schoolData = res.message[0].school.slice(0, 5);
            }
          }
        }
        this.loading = false;
      });
    },
    goToSchool(school) {
      console.log(school);
      this.$router.push({
        path: '/school',
        query: {
          name: school.name,
          state: this.stateData.short_name
        }
      });
    },
    showMore() {
      this.isShowMore = false;
      this.schoolData = this.allSchoolData;
    },
    hideMore() {
      this.isShowMore = true;
      this.tableFix = false;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.schoolData = this.allSchoolData.slice(0, 5);
    },
    onScroll() {
      // 获取所有锚点元素
      const tableContent = document.getElementById('stateTable');
      // 所有锚点元素的 offsetTop
      const offsetTop = tableContent.offsetTop;
      const offsetHeight = tableContent.offsetHeight;

      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(123, scrollTop, offsetTop, offsetHeight)
      const startHeight = offsetTop - this.topHeight;
      const endHeight = offsetTop + offsetHeight - this.topHeight - 40;
      if (scrollTop > startHeight && scrollTop < endHeight) {
        this.tableFix = true;
      } else {
        this.tableFix = false;
      }
    }
  }
};
</script>

<style scoped lang="less">
.red1 {
  color: red;
}
.green1 {
  color: rgb(40, 183, 163);
}
.gray1 {
  color: rgb(93, 112, 146);
}

.bottomWrap {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 4px;
    height: 30px;
    width: 100%;
    margin: 0 16px;
  }
  .show {
    background: #4169e2;
    color: #fff;
  }
  .hide {
    border: 1px solid #ccc;
    background: #fff;
    color: #4169e2;
  }
}
.operation {
  font-weight: bold;
  color: #4169e2;
}
/deep/ .ant-table-thead th:nth-child(1) {
  background: #e3e7f3;
  color: #333;
}
/deep/ .ant-table-thead th:nth-child(2) {
  background: #e69a8d;
  color: #333;
}
/deep/ .ant-table-thead th:nth-child(3) {
  background: #95db9a;
  color: #333;
}
/deep/ .ant-table-thead th:nth-child(4) {
  background: #b4c0d5;
  color: #333;
}
/deep/ .ant-table-thead th:nth-child(5) {
  background: #e3e7f3;
  color: #333;
}
/deep/ .ant-table-body td {
  background: #f7f7f7;
  font-weight: bold;
  span {
    font-size: 12px;
  }
}

.tableHeader {
  display: flex;
  align-items: center;
  background: #f7f7f7;
  height: 40px;
  &.tableFix {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 10;
  }
  p {
    height: 100%;
    margin: 0;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    color: #333;
  }
  .name {
    background: #e3e7f3;
    width: 35%;
  }
  .confirm {
    flex: 1;
    background: #e69a8d;
  }
  .course {
    width: 30%;
    background: #95db9a;
  }
  .online {
    flex: 1;
    background: #b4c0d5;
  }
  .detail {
    width: 20%;
    background: #e3e7f3;
  }
}
.tableItem {
  display: flex;
  align-items: center;
  background: #f7f7f7;
  border-bottom: 1px solid #e8e8e8;
  min-height: 40px;
  p {
    height: 100%;
    margin: 0;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    background: #f7f7f7;
  }
  .name {
    width: 35%;
    cursor: pointer;
    .detail-text {
      position: relative;
      right: 10px;
      color: @primary-color;
    }
    .tips {
      position: absolute;
      right: -30px;
      top: 0;
      background: #ecf0fc;
      color: #4169e2;
      margin-left: 10px;
      border-radius: 5px;
    }
  }
  .confirm {
    flex: 1;
  }
  .course {
    // font-weight:normal;
    width: 30%;
  }
  .online {
    font-weight: normal;
    flex: 1;
  }
  .detail {
    width: 20%;
  }
}
.icon-question {
  color: #fc8300;
  font-size: 12px;
}
</style>
