
<template>
  <div class="practiceItem" @click="goToDetail" v-if="itemData.attach_image_url||itemData.attach_image">
    <img :src="$utils.checkImg(itemData)" alt="">
    <div class="tagWrap">
      <p>类型：<span>{{ itemData.ju_promotion_category }}</span></p>
      <p>地点：<span>{{ itemData.ju_positions }}</span></p>
      <div>{{ online }}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    itemData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {

    }
  },
  computed: {
    online () {
      return this.itemData.online ? '报名中' : '暂不开放'
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    goToDetail () {
      this.$router.push({
        path: '/practiceDetail',
        query: {
          name: this.itemData.name
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.practiceItem{
    margin-bottom:15px;
    img{
        width:100%;
        border-radius:10px;
    }
    .tagWrap{
        margin-top:10px;
        display:flex;
        justify-content: space-between;
        align-items:center;
        p{
            margin:0;
            color:#999;
            font-weight:bold;
        }
        div{
            margin-right:10px;
            padding:5px 10px;
            background:rgba(245,99,30,.2);
            border-radius:17px;
            color:#f5631e;
            font-weight:bold;
        }
    }
}
</style>
