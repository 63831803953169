<template>
  <div class="lineWrap">
    <div class="webWrap" @click="goToWeb">
      <img src="@/assets/logo.png" alt="" />
      <p>关于厚仁</p>
    </div>
    <!-- <div class="webWrap" @click="goToRelation">
      <img src="@/assets/logo.png" alt="" />
      <p>中美厚仁关系说明</p>
    </div> -->
    <div class="practiceWrap" @click="goTopractice">
      <i class="iconfont icon-fire"></i>
      <p>实习科研</p>
    </div>

    <!-- <div class="practiceWrap" @click="goToOffer">
      <p>🎊录取喜报</p>
    </div> -->
    <div class="searchWrap" @click="goToSearch">
      <a-icon type="search"></a-icon>
      <p>搜索</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    goToWeb() {
      this.$router.push('/officialWebsite');
    },
    goToRelation() {
      this.$router.push('/relation');
    },
    goToSearch() {
      this.$router.push('/search');
    },
    goToLetter() {
      this.$router.push('/letter');
    },
    goToOffer() {
      this.$router.push('/offer');
    },
    goTopractice() {
      this.$router.push('/practice');
    },
    goToEmbassy() {
      this.$router.push('/embassy');
    },
    goToVaccine() {
      this.$router.push('/vaccine');
    }
  }
};
</script>

<style scoped lang="less">
p {
  margin: 0;
  font-size: 0.22rem;
}
.lineWrap {
  margin: 15px 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  margin-top: 10px;
  .webWrap {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #2ea878;
    padding: 1px 8px;
    border-radius: 4px;
    font-size: 0.22rem;
    img {
      color: #2ea878;
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
    p {
      margin: 0;
      color: #2ea878;
    }
  }
  .searchWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    padding: 1px 5px;
    border-radius: 4px;
    color: #666;
    font-size: 0.22rem;
    i {
      color: #666;
    }
    p {
      margin-left: 5px;
      color: #666;
    }
  }

  .practiceWrap {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #f5631e;
    padding: 1px 8px;
    border-radius: 4px;
    font-size: 0.22rem;
    i {
      color: #f5631e;
      font-size: 0.22rem;
    }
    p {
      margin: 0;
      color: #f5631e;
    }
  }
}
</style>
