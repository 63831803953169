<template>
  <div class="practiceDetail" v-if="itemData">
    <van-nav-bar
      fixed
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div>
      <p-detail-des :item-data="itemData"></p-detail-des>
    </div>
    <van-tabs
      v-model="active"
      :border="true"
      offset-top="46"
      sticky
      swipeable
      scrollspy
      title-active-color="#f5631e"
      color="#f5631e"
      background="#f7f7f7"
    >
      <van-tab title="项目介绍">
        <p-detail-tab1 :item-data="itemData"></p-detail-tab1>
      </van-tab>
      <van-tab title="项目收获">
        <p-detail-tab2 :item-data="itemData"></p-detail-tab2>
      </van-tab>
      <!-- <van-tab title="往期学员分享" >
        <p-detail-tab3 :itemData="itemData"></p-detail-tab3>
      </van-tab> -->
    </van-tabs>
    <!-- <div class="live-chat">
      <a-button class="live-chat-btn" type="primary" @click="goToLiveChat">了解更多</a-button>
    </div> -->
  </div>
</template>

<script>
import PDetailDes from './item/PDetailDes'
import PDetailTab1 from './item/PDetailTab1'
import PDetailTab2 from './item/PDetailTab2'
import PDetailTab3 from './item/PDetailTab3'
import { backInitMixin } from '@/utils/mixin'
export default {
  name: 'PracticeDetail',
  components: {
    PDetailDes, PDetailTab1, PDetailTab2, PDetailTab3
  },
  mixins: [backInitMixin],
  props: {

  },
  data () {
    return {
      itemData: null,
      active: 0
    }
  },
  computed: {
    title () {
      return `${this.itemData.ju_promotion_category}详情`
    }
  },
  watch: {

  },
  created () {
    this.params = {
      filters: { name: '', node_category: this.$utils.newsCategory.ju }
    }
  },
  mounted () {

  },

  activated () {
    this.name = this.$route.query.name
    this.params.filters.name = this.name
    this._getData()
  },
  methods: {
    _getData () {
      this.$api['home/news'](this.params)
        .then(res => {
          if (res.message) {
            this.itemData = res.message[0]
          }
        })
    },
    goToLiveChat () {
      const url = this.itemData.original_url
      if (url) {
        window.open(url)
      } else {
        window.open('https://www.jobupper.com/')
      }
    }

  }
}
</script>

<style scoped lang="less">
.practiceDetail{
    margin-top:46px;
}
.live-chat{
      position:fixed;
      left:0;
      right:0;
      bottom:0;

      height:60px;
      display:flex;
      justify-content: center;
      align-items:center;

      button{
        width:200px;
        height:40px;
        border-radius:20px;
        box-shadow:0px 0px 15px 0px #F56323;
        background: #F56323;
        border:0;
        font-weight:bold;
      }
    }
</style>
