
import State from '@/views/state/State'
import StateList from '@/views/state/StateList'

export default [

  {
    path: '/state',
    name: 'state',
    component: State,
    meta: { title: '州详情' }

  },
  {
    path: '/stateList',
    name: 'stateList',
    component: StateList,
    meta: { title: '州列表' }

  }

]
