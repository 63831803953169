<template>
  <div class="banner">
    <!-- <img class="img" :src="imgUrl" alt="" /> -->
    <!-- <div class="tag" v-if="imgUrl&&pageCount">
      {{ pageCount | NumberFormat }} 次浏览
    </div> -->
    <van-swipe class="my-swipe" :autoplay="3000" ref="vanSwipe" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in bannerList" :key="index">
        <img @click="goToLink(item)" class="img" :src="item.block_image" alt />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['banner']),
    ...mapState({
      pageCount: state => state.app.pageCount
    }),
    bannerList() {
      if (this.banner) {
        return this.banner.block_content_list;
      } else {
        return [];
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    goToLink(item) {
      if (!item.block_execcode) {
        return;
      }
      let url = '';

      if (item.block_execcode.includes('https')) {
        url = item.block_execcode;
      } else {
        url = `https://${item.block_execcode}`;
      }
      window.open(url);
    }
  }
};
</script>

<style scoped lang="less">
.banner {
  position: relative;
}
img {
  width: 100%;
}
.tag {
  position: absolute;
  top: 0.19rem;
  right: 0;
  height: 0.48rem;
  padding: 0 0.16rem 0 0.24rem;
  color: #fff;
  font-weight: bold;
  font-size: 0.22rem;
  line-height: 0.48rem;
  background-color: rgba(19, 15, 133, 0.6);
  border-top-left-radius: 0.24rem;
  border-bottom-left-radius: 0.24rem;
}
</style>
