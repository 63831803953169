<template>
  <div class="author-item" @click="goToAuthor">
    <img v-if="item.avatar" v-lazy="$utils.checkImg(item.avatar)" alt="">
    <img v-else src="@/assets/female.png" alt="">
    <h3>{{ item.pen_name }}  <van-tag class="tag" v-if="from==='home'&&item.new_posts" type="danger">+{{ item.new_posts }}</van-tag></h3>
    <!-- <p>{{ item.posts }} 篇原创</p> -->
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    item: {
      default: () => {},
      type: Object
    },
    from: {
      default: 'expert',
      type: String
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    goToAuthor () {
      this.$router.push({
        path: `/author/${this.item.name}`

      })
    }
  }
}
</script>

<style scoped lang="less">
.author-item{
    margin-left:20px;
    width:100px;
    display:flex;
    flex-direction: column;
    align-items:center;
     position:relative;
    cursor: pointer;
    img{
        width:60px;
        height:60px;
        border-radius:30px;
    }
    h3{

        font-size:14px;
        color:@primary-color;
        margin-top:10px;
        margin-bottom:0;
        display:-webkit-box;
        word-break: break-all;
        overflow:hidden;
        text-overflow: ellipsis;
        -webkit-box-orient:vertical;
        -webkit-line-clamp: 1;
        margin-bottom:14px;
    }
    p{
      margin-top:5px;
      margin-bottom:14px;
    }
}
.tag{
      position:absolute;
      border-radius:7px;
      right:-10px;
      bottom:50px;
      font-size:10px;
    }
</style>
