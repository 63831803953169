<template>
  <div class="all-site">
    <div class="title-wrap">
      <h3>厚仁留学全服务</h3>
    </div>
    <div class="list">
      <div class="list-item" v-for="item in list" :key="item.name">
        <SiteItem :item="item"></SiteItem>
      </div>
    </div>
  </div>
</template>

<script>
import SiteItem from '../components/SiteItem';
export default {
  components: {
    SiteItem
  },
  props: {},
  data() {
    return {
      site: []
    };
  },
  computed: {
    list() {
      const site = this.site.slice(0, 4);
      const more = {
        logo: require('@/assets/more.png'),
        title: '更多'
      };
      const all = [].concat(site, [more]);
      return all;
    }
  },
  created() {
    this.params = {
      filters: { pinned: 1 }
    };
    this.getSite();
  },
  mounted() {},
  watch: {},
  methods: {
    getSite() {
      this.loading = true;
      this.$api['home/site'](this.params)
        .then(res => {
          console.log(res, 'site');
          if (res.message) {
            this.site = res.message;
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }
};
</script>

<style scoped lang="less">
.all-site {
  padding: 20px 16px;
  .title-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      margin-right: 10px;
      color: #333;
    }
  }
  .list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .list-item {
      margin-right: 2.5%;
      width: 18%;
      &:nth-child(5) {
        margin-right: 0;
      }
    }
  }
}
</style>
