import Auth from '@/views/auth/Auth'
import Subscription from '@/views/auth/Subscription'
import Transition from '@/views/auth/Transition'

export default [

  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: { title: '个人中心' }
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: Subscription,
    meta: { title: '订阅中心' }
  },
  {
    path: '/transition',
    name: 'transition',
    component: Transition,
    meta: { title: '过渡页' }
  }

]
