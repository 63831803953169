<!-- @format -->

<!--@format
 by: wk 2018/12/18
 name: 页面组件——首页地图
 备注:
-->
<template>
  <div class="rank-line">
    <div class="title-wrap" v-if="school.school_type === 'Liberal Arts College' && school.un_lac_ranking">
      <i class="iconfont icon-ketangbiaoxian1"></i>
      <span>2022 US NEWS 文理学院排名：{{ school.un_lac_ranking }}</span>
    </div>
    <div class="title-wrap" v-else-if="school.latest_ranking_2022">
      <i class="iconfont icon-ketangbiaoxian1"></i>
      <span>2022 US NEWS 综合排名：{{ school.latest_ranking_2022 }}</span>
    </div>

    <div class="line-wrap" v-show="rankLineData.length">
      <div class="website-wrap">
        <h4 class="title-2">US NEWS历年排名</h4>
        <div class="website" @click="goToSchoolRank">更多排名信息</div>
      </div>
      <div id="rankLine"></div>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import { mapState } from 'vuex';
const echarts = require('echarts/lib/echarts');
require('echarts/lib/component/dataZoom');
require('echarts/lib/chart/line');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/legend');
require('echarts/lib/component/visualMap');

require('echarts/lib/component/title');
export default {
  components: { TopHeader },

  props: {},

  computed: {
    ...mapState({
      school: state => state.app.schoolData
    })
  },

  data() {
    return {
      rankLineData: [],
      active: 0
    };
  },

  created() {},

  mounted() {
    this._getMapLineData();
  },
  watch: {
    school(newV) {
      if (newV && newV.infoset_ranks) {
        this._getMapLineData();
      }
    }
  },
  methods: {
    goToSchoolRank() {
      window.open('https://school.wholeren.cn/');
    },
    /**
     * @description: 拿到map学校数据
     * @param {type}
     * @return:
     */
    async _getMapLineData() {
      if (this.school.infoset_ranks) {
        this.rankLineData = this.school.infoset_ranks;

        this._drawMap();
      }
    },

    /**
     * @description: 绘制地图
     */
    _drawMap() {
      var dom = document.getElementById('rankLine');
      var myChart = echarts.init(dom);

      const xData = this.rankLineData.map(item => item.year);
      const yData = this.rankLineData.map(item => item.by_wholeren);
      console.log(xData, yData, 'tt');
      const option = {
        tooltip: {
          trigger: 'axis',
          extraCssText: 'text-align:left;z-index:999',
          formatter: params => {
            console.log(params, 'paras');
            const data = params[0];

            let html = '';
            if (data) {
              html += `<span style="color:#fff;font-size:bold;">${data.name} US NEWS <br/></span>`;
              html += data.marker + `排名：<span style="color:#fff442;font-size:bold;">${data.value} <br/></span>`;
            }

            return html;
          }
        },

        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: 40,
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLine: {
            onZero: false
          },
          data: this.rankLineData.map(item => item.year)
        },
        yAxis: [
          {
            minInterval: 1,
            name: '排名',
            nameLocation: 'start',
            type: 'value',
            scale: true,
            inverse: true,
            boundaryGap: [0, 0.2],
            nameTextStyle: {
              align: 'left'
            }
          }
        ],
        series: [
          {
            type: 'line',
            yAxisIndex: 0,
            itemStyle: {
              color: '#4169e2'
            },
            data: this.rankLineData.map(item => item.by_wholeren)
          }
        ]
      };
      myChart.setOption(option);
    }
  }
};
</script>
<style lang="less" scoped>
.rank-line {
  background: #fff;
  padding: 10px 0;

  .title-wrap {
    background: linear-gradient(to bottom, #7ac9f5, #446fe3);
    color: #fff;
    margin: 10px 16px 0;
    padding-left: 10px;

    height: 32px;
    line-height: 32px;
    font-weight: bold;
    text-align: left;
    border-radius: 5px;
    i {
      color: #fc8300;
    }
  }

  .line-wrap {
    padding: 15px 0;
    .title-2 {
      margin: 0 16px;
    }
    .website-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .website {
        padding: 0 10px;
        height: 24px;
        border: 1px solid #4169e2;
        color: #4169e2;
        border-radius: 5px;
        line-height: 24px;
        margin-right: 16px;
      }
    }
    .shareDomLine {
      width: 90vw;

      height: 30vh;
      @media screen and (min-width: 768px) {
        width: 355px;
      }
    }
    #rankLine {
      width: 100vw;
      height: 35vh;
      background: #f7f7f7;
      @media screen and (min-width: 768px) {
        width: 768px;
      }
    }
  }
}
</style>
