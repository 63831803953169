<template>
  <div class="pdetailTab">
    <top-header color="#f5631e" title="项目收获"></top-header>
    <div class="con" v-html="itemData.ju_achievement"></div>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
export default {
  components: {
    TopHeader
  },
  props: {
    itemData: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="less">
.pdetailTab {
  margin-top: 10px;
  padding: 0 16px;
  .con {
    text-align: left;
  }
}
</style>
