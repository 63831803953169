<template>
  <div class="news">
    <NewsShareDom></NewsShareDom>
    <van-nav-bar
      fixed
      title="重要播报"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- <Sticky :offsetTop="50"></Sticky> -->
    <div class="list">
      <a-timeline>
        <flow-list url="home/news" :params="params" :sep="false" @handleData="handleData">
          <template slot-scope="row">
            <div>
              <a-timeline-item>
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="row.itemData"></news-item>
              </a-timeline-item>
              <a-timeline-item v-if="row.myIndex===1">
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="newsAd[0]"></news-item>
              </a-timeline-item>
              <a-timeline-item v-if="row.myIndex===6">
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="newsAd[1]"></news-item>
              </a-timeline-item>
            </div>
          </template>
        </flow-list>
      </a-timeline>
    </div>
  </div>
</template>

<script>

import { FlowList, Sticky, NewsItem } from '@/components'
import NewsShareDom from './item/NewsShareDom'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    FlowList, Sticky, NewsItem, NewsShareDom
  },
  props: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      newsAd: state => state.app.newsAd
    })
  },
  watch: {
  },
  created () {
    this.params = {
      limit_start: 0,
      limit_page_length: 10,
      filters: { recommended: 'Index Top Slide' }
    }
  },
  activated () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0

    this.track()
  },
  mounted () {

  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.newsSlide = true
      }
    })
  },
  methods: {
    ...mapMutations({
      setNewsSlideData: 'SET_NEWS_SLIDE_DATA'
    }),
    handleData (list) {
      this.setNewsSlideData([].concat(list))
    },
    onClickLeft () {
      if (this.newsSlide) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    },
    track () {
      this.$gtag.event('go_coronavirus_update_news', {
        event_category: 'CoronavirusUpdateNews',
        event_label: this.$utils.newsCategory.news
      })
    }
  }
}
</script>

<style scoped lang="less">
.news{
  padding-top:60px;
}
.list{
  padding:0 16px;
}
.dot{
  width:14px;
  height:14px;
  border-radius:7px;
  background:#c7d4f7;
  display:flex;
  justify-content: center;
  align-items: center;
  div{
    width:6px;
    height:6px;
    border-radius:3px;
    background:#3f69e2;
  }
}
/deep/ .van-pull-refresh{
  overflow:visible
}
/deep/ .ant-timeline-item-tail{
  border-left-color:#c7d4f7
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail{
  display:block
}
</style>
