<template>
  <div class="myTable">
    <div class="table" id="myTable">
      <div v-show="tableFix" class="tableHeader"></div>
      <div class="tableHeader" :class="{tableFix:tableFix}">
        <p class="name">学校名称</p>
        <p class="confirm">确诊</p>
        <p class="course">网课开始</p>
        <p class="online">结束</p>
        <p class="detail">备注</p>
      </div>
      <div class="tableItem" v-for="(item) in tableData" :key="item.name">
        <p class="name">{{ item.school_cn }}</p>
        <p :class="[item.ncov_confirmed ? 'red1':'gray1']" class="confirm">{{ item.ncov_confirmed }}</p>
        <p class="course"><span v-if="item.online_course_start">{{ item.online_course_start | moment('MM/DD') }}</span><span v-else>暂无</span></p>
        <p class="online"><span v-if="item.online_course_end">{{ item.online_course_end | moment('MM/DD') }}</span><span v-else>暂无</span> </p>
        <p class="detail" @click="goToSchool(item)">详情</p>
      </div>
    </div>
    <div style="padding:0 16px;">
      <load-more slot="footer" @loadMore="loadMore" v-if="tableData.length" :has-more="hasMore" :loading="loading"></load-more>
    </div>
  </div>
</template>

<script>
import { LoadMore } from '@/components'
export default {
  name: 'MyTable',
  components: {
    LoadMore
  },
  props: {
    params: {
      default: () => {},
      type: Object
    },
    request: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      tableData: [],
      tableFix: false,
      hasMore: true,
      loading: false
    }
  },
  computed: {

  },
  watch: {

  },
  created () {
    this.tabHeight = 60
    this._getSchoolData()
  },
  activated () {
    window.addEventListener('scroll', this.onScroll)
  },
  deactivated () {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
  mounted () {
  },
  methods: {
    onScroll () {
      // 获取所有锚点元素
      const tableContent = document.getElementById('myTable')
      // 所有锚点元素的 offsetTop
      const offsetTop = tableContent.offsetTop
      const offsetHeight = tableContent.offsetHeight

      // 获取当前文档流的 scrollTop
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      //   console.log(123, scrollTop, offsetTop, offsetHeight)
      if (scrollTop > offsetTop - this.tabHeight) {
        this.tableFix = true
        if (scrollTop > offsetTop + offsetHeight - this.tabHeight - 40) {
          this.tableFix = false
        }
      } else {
        this.tableFix = false
      }
    },
    loadMore () {
      this.params.limit_start += this.params.limit_page_length
      this._getSchoolData()
    },
    _getSchoolData () {
      this.loading = true
      this.$api[this.request](this.params)
        .then(res => {
          console.log(res, 'schoolTable')
          if (res.message) {
            if (res.message.length < this.params.limit_page_length) {
              this.hasMore = false
            }
            this.tableData = [].concat(this.tableData, res.message)
            this.loading = false
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    goToSchool (item) {
      console.log(item)
      this.$router.push({
        path: '/school',
        query: {
          name: item.name,
          state: item.school_state_short
        }
      })
    }
  }

}
</script>

<style scoped lang="less">
.red1 {
  color: red;
}
.green1 {
  color:rgb(40, 183, 163);
}
.gray1 {
  color:rgb(93, 112, 146);
}

.myTable{
    .tableHeader{
        display:flex;
        align-items:center;
        background:#f7f7f7;
        height:40px;
        &.tableFix{
            position:fixed;
            top:60px;
            left:0;
            right:0;
            z-index:10;
        }
        p{
            height:100%;
            margin:0;
            line-height:40px;
            text-align:center;
            font-weight:bold;
            color:#333;
        }
        .name{
            background:#e3e7f3;
            width:30%;
        }
        .confirm{
            flex:1;
            background:#e69a8d;
        }
        .course{
            flex:1;
            background:#95db9a;
        }
        .online{
            flex:1;
            background:#b4c0d5;
        }
        .detail{
            flex:1;
            background:#e3e7f3;
        }
    }
    .tableItem{
        display:flex;
        align-items:center;
        background:#f7f7f7;
        border-bottom: 1px solid #e8e8e8;
        min-height:40px;
        p{
            height:100%;
            margin:0;
            text-align:center;
            font-weight:bold;
            background:#f7f7f7;

        }
        .name{
            width:30%;
        }
        .confirm{
            flex:1;

        }
        .course{
            font-weight:normal;
            flex:1;

        }
        .online{
            font-weight:normal;
            flex:1;

        }
        .detail{
            flex:1;
            color:#4169e2;
        }
    }
}

</style>
