<template>
  <div class="author-list-item" @click="goToAuthor">
    <div class="top-wrap">
      <div class="top-left-wrap">
        <img :src="$utils.checkImg(author.avatar)" v-if="author.avatar" alt="" class="img">
        <img v-else src="@/assets/female.png" alt="" class="img">
        <div class="text-wrap">
          <div class="text-top-wrap">
            <p class="text-name">{{ author.pen_name }}</p>
          </div>
          <div class="text-center-wrap">
            <p class="text-center-group" v-if="author.external_group">{{ author.external_group }}</p>
          </div>
          <!-- <div class="text-bottom-wrap">
            <p class="text-bottom-post">{{ author.posts }}篇原创</p>
          </div> -->
        </div>
      </div>
      <div class="top-right-wrap" @click="goToAuthor">
        <p class="link">查看更多</p>
        <a-icon type="right"></a-icon>
      </div>
    </div>
    <p class="label">{{ author.description }}</p>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    author: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    goToAuthor () {
      this.$router.push({
        path: `/author/${this.author.name}`

      })
    },
    goToLiveChat () {
      this.$utils.goToLiveChat(this.author.pen_name)
    }
  }
}
</script>

<style scoped lang="less">
p{
  margin:0
}
.author-list-item{
  border:1px solid #ededed;
  padding:16px ;
  border-radius:10px;

}
.top-wrap{
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  .top-left-wrap{
      display:flex;
      flex-direction: row;
      position:relative;
      align-items:center;

      img{
        width:50px;
        height:50px;
        border-radius:25px;
      }
      .text-wrap{
        margin-left:16px;
        display:flex;
        flex-direction: column;
         position:relative;
        justify-content: space-between;
        .text-top-wrap{
          display:flex;
          flex-direction: row;
          align-items:center;
          .text-name{
            font-size:16px;
            font-weight:bold;
            color:@primary-color;

          }
          .text-line{
            margin:0 10px;
          }

        }
        .text-center-wrap{
          margin-top:5px;
          .text-center-group{
            font-size:12px;
          }
        }
        .text-bottom-wrap{
          margin-top:5px;
          .text-bottom-post{
            font-size:12px;
          }
        }

      }

    }
    .top-right-wrap{
      display:flex;
      flex-direction: row;
      align-items:center;
      p,i{
        color:@primary-color
      }
      p{
        margin-right:5px;
      }
      i{
        font-size:12px;
      }
    }
}
.label{
    margin-top:10px;
    display:-webkit-box;
    word-break: break-all;
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-box-orient:vertical;
    -webkit-line-clamp: 2;
}
</style>
