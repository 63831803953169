<template>
  <div class="btmFloatBtnGroup" v-if="isShow">
    <div class="affixWrap">
      <div class="shareWrap">
        <div class="share conWrap" @click="showShare()">
          <img
            alt="分享疫情数据"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAAAXNSR0IArs4c6QAAA2xJREFUWAntmD1IVWEYx70VGVlZgUMG3SKbWiyolmgL06FBcE+ooalAGiJoqUnbWgsdXDNbhBpyso8lWhMi+m6JFDOLPm6///U8+Xi+z1EuFD3w8/36P8/7eD7e9z23qem//SNXoFL2/6jVai34dkEP7IX2gPeUxyuVykvKxhkJdcIYLECSnWlYRmRQhVH4BUk2y8AktDUkMSbqhhnw9oXGHeiHfaBb2zhjwgH4CWZfqVyDbWWzkC+Ufr6bcFZS3h7QqJZNSH74n4RvMA0dhWPhpNvnr9QI7ebCgUIOxLgKZm+o5E8OcRX8MzUSip+7SZzNcAi6oA/Ow3cwU3J7cgVEqLfPTLev0JVCvxb0UjyCH5BltzITI8IBsCVBD/quTCcnQN8OT6GI3XMh6tV14Q7al8HemOus4K9iNLFdZLKVgSnY7QQvqD+EDzAHm+Ac2Nzz1C9BshG4BWxF1zpVaElAfwPM3lI5EZ6NvosmoPwMx8KaSBtRr3MajwhSOvDbCfYWa0LtnxGj/yzI5iA7KUVAeFMegfVHoqZ04HPBHCmvpEg1z2HYnqZZNoZ4Eszyry9EwWnCHCn3Lwu80gYBn7ngG4vEw+914DtbxM+0+LbBaYjuLHTqvstmzCFPiX5D3Wvxz5M8PmENrveDGNMaWxMWlGxvcX4fXb1I9WAg3qEynNi7YLCV7IvcSq1NZgtWyVsGc7UG+noOSYlJo6NyXtNx+lMgfpzXyen8XLGJPXfio66eWmV30FU6An0wlCqOH/Rz+RwW1VxSv8Dejo+x+r3MOw5mvZEZGPFb0jztQltSJGCODs0Bmkum7TD+iM6AvoDMBnPEXpGEiYZsMsqxxGAM+mOP/oNCx57EwDEDig12aNBRqzNGttSFwB8Up2ivXxpdnRoxm0GxzUYzI6Osgj9aD2c6FRQQX98QZporuhXFxUQY/hgZpm/FV44YulI+KR2VuuNySOzDIfz5pktf+pmTL/jbR7M2kJhA2oAcwQ6ACqSHdRByLyXSBj72oNOsxyyXlCVMkLifCLT2aGE8BR3wZ29VPejTmDQ6pnvTM1Xs9lky4ZJAVdDbqtc6yTShSDL5Kka+Bz2cRFqboFrnsn6GQrLMdAvlk75OuYntM8115asyibaOLugB/8OdAuiEILQhT8BdNnp9pv399hull/WKdTt1bwAAAABJRU5ErkJggg=="
          >

          <span
            class="text___2tall"
            style="color: #fff; margin-left: 5px; font-weight: 600; font-size: 12px;"
          >分享</span>
        </div>
        <div class="group conWrap" @click="showGroup()">
          <img
            alt="加入讨论群"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAAAXNSR0IArs4c6QAABERJREFUWAntWF1sFFUUvmemdWtZg9i4lLJbE34MaogPJgUhmkwrJZLwRn3Q8EB86m6VgDWRGhMSog/+Ael2MTHqiy9aSAgP1irdVoIxIcE/TIw+iO0sS0stgi1ul9mZ63e3nWF2OtPdbWcSH7jJ5p6/e86355y5984wdndUlwHyMm9LZB7m3DjCOG3lxB7wsluCPIc1P8sSf+dssnnQa70rMCVx9TEyChc44/VeC32RS+zF4b7mj918SW5CZhTeCxyUCMzpeMdhfo8bhho3IWTbTDkRHaqpr+1dGV6tmbLlzDcmrzxVMIwB+KhlnIf/mri6GfRFp08PYPw+07AhxI73v9so+sKvMaTE1e8BaotwKMu6FcsewL2UNouZQtSwsb6QaOyyPssC8wWJwwnH426KSGfcpO2zRyntJsujW1+aWE+GFiHiM2eTsUvCG0msB3Ba0Pz5++vYBbcIgQNjev5bg7PVIvjOA2rD4NHY9XRf8zmw4uc5Ai8l58zaDuR8yKI9Ec0rAgdWDoCX/n8LzNce2xW/1pir1VaWZEHTJfO5y0naemX/FUtPBZpN9zWNltjPM74Cy/HZy+w2q3MLVJTp+nmm39GKfaK9a3zdV8nGy3ekc5TfpfQG5Yw8z+vE73VT+ZoxnKvfYfNcUxqIYjitZSFDhlTs+lbOiFgOj+lkqf0c5yuwdCpmHf5mMKVz7DroVYKvp7qWL1KRcVO32Ox3KReLVZXuLrCq0gXj4DNG9Dua/B9GlCnUG9OVAvS1+d2CDqdiW51yJT72BjF6Fudovq6G9g70RjNOm8CBOQMKfg4Uf1LQGmcbMC0AFnwpRfQljGVnrONzLk+NZHfhIrgZpcmGw6FTZ95+sOJe8sK8rIy1xrPtUyPqJc71M4ZhvIkb8yfT07nRtsRYQgD2ClqJfEnAnumcWIcGPs15YRBZesQRaJVhsOTUSOaHtnhGcegqZqsqZXv3+Art1u0eg/Kv4OAL2aL8jYYewKcE3OO5aGZMKC3j6da4epKR3O11vbH5KCErzpjSpT5fuKX9htU9yFIRFMDo2KM+kCRpY/pE7IWNj0cfxZvGQRzUN80oALgHpf4VAA93HFBdbxKmrX2Gj4UDB6/1SoXARwGkBVbb7Za4SZzjMr083Bv9yS4XNDIb0f7V3oLzfQBm/Xn8kTEm8Q/xdvQa5CuErSyTgrenEUHbR1lgduMiTaRKTHp1KLX2swU6h0DpzD5BpPcCRHHPcqiLrBcw69+4LXLIZiWSjoQj8qZKQIm1wyeaLg71RbdLjPYi81mHv0XZCpufToVCNd1fHlvz56LeXJQouWiLTzvi105PUf51PBUHISj7GucKDM5Gkf6HcHj8QjLtTyejaZeYVYn6U5EZLDi0M65+pDH+Pvp2N+IYaLI/3By59tiOrskmneU3NTy99pv+5/B1IYDRnlC3cHwi+DoV/TEA98G5/A9AZWq/wdkxwwAAAABJRU5ErkJggg=="
          >

          <span
            class="text___2tall"
            style="color: #4169e2; margin-left: 5px; font-weight: 600; font-size: 12px;"
          >微信群</span>
        </div>

        <div @click="goToOffer" class="clue conWrap" target="_blank">
          <span
            class="text___2tall"
            style="color: #fff; margin-left: 5px; font-weight: 600; font-size: 12px;"
          >Offer榜</span>
        </div>

        <div class="about conWrap" @click="showAbout()">
          <span
            class="text___2tall"
            style="color: #4169e2; margin-left: 5px; font-weight: 600; font-size: 12px;"
          >免费咨询</span>
        </div>
      </div>
    </div>
    <van-overlay :show="isShowShare" @click="isShowShare = false">
      <div class="showImg shareShare">
        <div v-if="shareImg" style="width:100%">
          <img :src="shareImg" alt="" id="shareImg" @click="handleImg">
          <p>长按图片保存到手机，即可分享到微信朋友圈</p>
        </div>
        <van-loading v-else size="24px" color="#fff" type="spinner" vertical><span class="loading">分享图片生成中...</span></van-loading>
      </div>
    </van-overlay>
    <van-overlay :show="isShowGroup" @click="isShowGroup = false">
      <div class="showImg shareGroup">
        <img :src="shareGroup.block_image | checkImg" alt="">
      </div>
    </van-overlay>
    <!-- <van-overlay :show="isShowAbout" @click="isShowAbout = false">
      <div class="showImg shareAbout">
        <img :src="shareAbout.block_image | checkImg" alt="">
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { mapGetters } from 'vuex'
import { ImagePreview } from 'vant'
export default {
  components: {

  },
  props: {

  },
  data () {
    return {
      isShow: true,
      bottom: 20,
      isShowShare: false,
      shareImg: '',
      isShowGroup: false

      // isShowAbout: false

    }
  },
  computed: {
    ...mapGetters(['shareGroup', 'shareAbout']),
    shareGroupImgUrl () {
      if (this.shareGroup) {
        return this.shareGroup.block_image
      } else {
        return ''
      }
    },
    shareAboutImgUrl () {
      if (this.shareAbout) {
        return this.shareAbout.block_image
      } else {
        return ''
      }
    }
  },
  watch: {
    $route (newV, oldV) {
      console.log('newV', newV, oldV)
      const noShowList = ['transition', 'welfareDetail', 'authorDetail', 'expertDetail', 'practiceDetail', 'practice']
      if (newV) {
        if (noShowList.includes(newV.name)) {
          this.isShow = false
        } else {
          this.isShow = true
        }
      }
    }
  },
  created () {
    this.content = this.$route.name
  },
  activated () {

  },
  mounted () {

  },
  methods: {
    handleImg () {
      ImagePreview({
        images: [this.shareImg]

      })
    },
    goToOffer () {
      this.$router.push({
        path: '/offer'
      })
    },
    async showShare () {
      this.isShowShare = true
      let domId = ''
      let eventId = 'isShowShareDom'
      console.log('this.$route.name', this.$route)
      if (this.$route.name === 'state') {
        domId = 'shareDomState'
      } else if (this.$route.name === 'school') {
        domId = 'shareDomSchool'
      } else if (this.$route.name === 'newsSlide') {
        domId = 'shareDomNewsSlide'
      } else if (this.$route.name === 'newsDetail') {
        domId = 'shareDomNews'
        eventId = 'isShowShareDomNews'
      } else {
        domId = 'shareDom'
      }
      this.$bus.$emit(eventId, { show: true, content: this.content })
      this.$nextTick(async () => {
        const dom = document.getElementById(domId)
        console.log('tt', dom, dom.offsetLeft, dom.offsetWidth)
        const canvas = await html2canvas(dom, {
          useCORS: true,
          allowTaint: true,

          x: dom.offsetLeft - dom.offsetWidth / 2,
          y: window.pageYOffset

        })
        console.log(1234)
        this.shareImg = canvas.toDataURL()

        this.$bus.$emit(eventId, { show: false })
      })
    },
    showGroup () {
      this.isShowGroup = true
    },
    showAbout () {
      // this.isShowAbout = true
      this.$utils.goToLiveChat('bottom')
    }

  }
}
</script>

<style scoped lang="less">
.affixWrap{
  position: fixed;
    bottom: 20px;
    left: 0px;
    z-index:10;
}
.shareWrap{

  display:flex;
  width:100vw;
  justify-content: space-evenly;
  .conWrap{
    box-shadow: 0 0.02rem 0.06rem 0 rgba(65,105,226,.2);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border-radius: 18px;
    padding: 0px;
    img{
      display: inline-block;
      width: 20px;
      height:20px;
    }
  }
  .share{
    background-color: #4169e2;
    border-color: #4169e2;
    margin:0 14px 0 14px;
    flex: 1;
  }
  .group{
    background-color: #fff;
    margin:0 14px 0 0px;
    border: 1px solid #4169e2;
    flex: 1;
  }
  .clue {
    background-color: #fc8300;

    margin:0 14px 0 0px;
    flex:1;
    color:#fff
   }
   .about{
    background-color: #fff;
    margin:0 14px 0 0px;
    border: 1px solid #4169e2;
    flex:1;
  }
}
.shareImg{
  width: 103px; height: 111px; position: absolute; top: 20px; right: 20px;
}
.shareText{
  position: absolute;color: #fff; font-size: 16px; font-weight: 500; top: 117px; right: 85px;
}
.showImg{
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  p{
    margin-top:10px;
    color:#fff;
    text-align:center;
    font-weight:bold;
  }
  img{
    width:80%;
    max-width: 450px;
  }
  .loading{
    color:#fff;
    font-weight:bold;
  }
}
.shareShare{
 img{
   width:50%;
 }
}
.shareAbout{
  img{
    width:90%;

  }
}

/deep/ .van-overlay{
  z-index:100;
}
</style>
