<template>
  <div class="news">
    <van-nav-bar
      fixed
      title="热点新闻"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- <Sticky :offsetTop="240"></Sticky> -->
    <div class="search-wrap">
      <div style="padding:0 16px;">
        <a-input-search

          placeholder="大家都在搜：美国疫情"
          @search="onSearch"
          v-model="search"
          enter-button="搜索"
        >
          <a-icon slot="prefix" type="search"></a-icon>
        </a-input-search>
      </div>

      <div class="tagsWrap">
        <div class="tagItem" :class="{activeTag:activeTag===index}" v-for="(item,index) in tagList" :key="item.name" @click="clickTag(item,index)">
          <span class="name">{{ item.name }}</span>
          <van-tag class="tips hot" v-if="item.tips==='热'">{{ item.tips }}</van-tag>
          <van-tag class="tips " type="danger" v-if="newsCount[item.tips]">+{{ newsCount[item.tips] }}</van-tag>
        </div>
        <div class="line line_1"></div>
        <div class="line line_2"></div>
      </div>
    </div>
    <div class="sep"></div>
    <div class="list">
      <a-timeline v-if="isShow">
        <flow-list :url="url" :params="params" :sep="false">
          <template slot-scope="row">
            <div>
              <a-timeline-item>
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="row.itemData" :search="search"></news-item>
              </a-timeline-item>
              <a-timeline-item v-if="row.myIndex===1">
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="newsAd[0]"></news-item>
              </a-timeline-item>
              <a-timeline-item v-if="row.myIndex===6">
                <div slot="dot" class="dot">
                  <div></div>
                </div>
                <news-item :item-data="newsAd[1]"></news-item>
              </a-timeline-item>
            </div>
          </template>
        </flow-list>
      </a-timeline>
    </div>
  </div>
</template>

<script>

import { FlowList, Sticky, NewsItem } from '@/components'
import { mapState } from 'vuex'
export default {
  name: 'News',
  components: {
    FlowList, Sticky, NewsItem
  },
  props: {

  },
  data () {
    return {
      name: '',
      isShow: true,
      url: 'home/news',
      activeTag: 0,
      search: '',
      params: {
        limit_start: 0,
        limit_page_length: 10,

        filters: { node_category: this.$utils.newsCategory.all }
      },
      tagList: [

        {
          name: '全部',
          tips: '',
          key: 'all'
        },
        {
          name: '重要播报',
          recommended: 'Index Top Slide',
          tips: ''

        },
        {
          name: '校园疫情',
          tips: this.$utils.newsCategory.news,
          key: 'news'
        },
        {

          name: '学业新闻',
          tips: this.$utils.newsCategory.study,
          key: 'study'
        },
        {
          name: '实习就业',
          tips: this.$utils.newsCategory.job,
          key: 'job'
        },
        {
          name: '使馆信息',
          tips: this.$utils.newsCategory.embassy,
          key: 'embassy'
        },
        {
          name: '疫苗进展',
          tips: this.$utils.newsCategory.vaccine,
          key: 'vaccine'
        },
        {
          name: '身份/出入境',
          tips: this.$utils.newsCategory.identity,
          key: 'identity'
        },
        {
          name: '联邦新闻',
          tips: this.$utils.newsCategory.federal,
          key: 'federal'
        },

        // {
        //   name: '精品文章',
        //   tips: this.$utils.newsCategory.article,
        //   key: 'article'
        // },

        // {
        //   name: '物资邮寄',
        //   tips: this.$utils.newsCategory.post,
        //   key: 'post'
        // },
        {
          name: '机票行情',
          tips: this.$utils.newsCategory.ticket,
          key: 'ticket'
        }

      ]

    }
  },
  computed: {
    ...mapState({
      newsAd: state => state.app.newsAd,
      newsCount: state => state.searchArea.newsCount
    })
  },
  watch: {
  },
  created () {

  },
  activated () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    const { item, index } = this.$route.query
    console.log(this.$route, this.$router, 'item')
    if (item) {
      this.clickTag(item, index)
    }
    this.track()
  },
  mounted () {

  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.noticesFrom = true
      }
    })
  },
  methods: {
    onSearch (value) {
      value = value.replace(/(^\s*)|(\s*$)/g, '')

      this.isShow = false
      this.activeTag = -1

      this.params = {
        limit_start: 0,
        limit_page_length: 10,
        search: this.search,
        doctype: 'nCov Node',
        filters: { node_category: this.$utils.newsCategory.all }
      }
      if (value) {
        console.log(value, 'value')
        this.params.search = value
      } else {
        this.params.search = ''
      }
      this.url = 'home/search'
      this.$nextTick(() => {
        this.isShow = true
      })
    },
    clickTag (item, index) {
      console.log('name,', item)

      this.isShow = false
      this.activeTag = index

      this.params = {
        limit_start: 0,
        limit_page_length: 10,

        filters: { node_category: this.$utils.newsCategory[item.key] }
      }

      if (index === 1) {
        this.params = {
          limit_start: 0,
          limit_page_length: 10,

          filters: { recommended: item.recommended }
        }
      }
      // this.search = `#${item.name}#`
      this.url = 'home/news'

      this.$nextTick(() => {
        this.isShow = true
      })
    },
    onClickLeft () {
      console.log(123)
      if (this.noticesFrom) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    },
    track () {
      this.$gtag.event('go_coronavirus_update_news', {
        event_category: 'CoronavirusUpdateNews',
        event_label: this.$utils.newsCategory.news
      })
    }
  }
}
</script>

<style scoped lang="less">
.news{
  padding-top:60px;
  position:relative;

}
.sep{
  height:10px;
  width:100%;
  background:#f1f1f1
}
.tagsWrap{
  display:flex;
  flex-wrap:wrap;
  margin:10px 0 ;
  position:relative;
  .line{
    position:absolute;

    height:100%;
    width:1px;
    background:#f1f1f1;
    &.line_1{
      left:34%;
    }
    &.line_2{
      left:68%;
    }
  }
  .tagItem{
    width:25%;
    margin:0 4%;
    height:30px;
    display:flex;
    justify-content: center;
    align-items:center;
    position:relative;
      &.activeTag{
        .name{
           color:#fff;
           background:@primary-color;
           padding:3px 8px;
            border-radius:5px;
        }
      }

    .name{
      color:#333;

    }
    .tips{
      position:absolute;
      right:-15px;
      margin-left:10px;
      border-radius:5px;
      background: #ECF0FC;
      color:#4169E2;

      &.hot{
         background:#fc8300
      }
      &.fei{

      }
      &.jian{

      }
      &.new{
        background:#ffcc00
      }
    }
  }
}
.list{
  padding:0 16px;
  margin-top:20px;
}
.dot{
  width:14px;
  height:14px;
  border-radius:7px;
  background:#c7d4f7;
  display:flex;
  justify-content: center;
  align-items: center;
  div{
    width:6px;
    height:6px;
    border-radius:3px;
    background:#3f69e2;
  }
}
/deep/ .van-pull-refresh{
  overflow:visible
}
/deep/ .ant-timeline-item-tail{
  border-left-color:#c7d4f7
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail{
  display:block
}
</style>
