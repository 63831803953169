<template>
  <div class="orderTag">
    <div class="wrap getOrder" v-if="!isOrder" @click="handleOrder">
      <a-spin v-if="loading" size="small" class="spin"></a-spin>
      <a-icon v-else type="plus"></a-icon>
      <p>订阅</p>
    </div>
    <div class=" wrap cancelOrder" v-else @click="handleCancel">
      <a-spin v-if="loading" size="small" class="spin"></a-spin>
      <p>已订阅</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Toast } from 'vant'
export default {
  components: {

  },
  props: {
    type: {
      default: '',
      type: String
    },
    name: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['loginId', 'orderState', 'orderSchool', 'schoolData', 'stateData']),
    isOrder () {
      let value = false
      if (this.type === 'state') {
        this.orderState.forEach(item => {
          if (item.name === this.stateData.name) {
            value = true
          }
        })
      } else if (this.type === 'school') {
        this.orderSchool.forEach(item => {
          if (item.name === this.schoolData.name) {
            value = true
          }
        })
      }
      return value
    }
  },

  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    ...mapMutations({
      setOrderState: 'SET_ORDER_STATE',
      setOrderSchool: 'SET_ORDER_SCHOOL',
      setShowSub: 'SET_SHOW_SUB'
    }),
    async handleOrder () {
      if (!this.loginId) {
        this.setShowSub(true)
        return
      }
      const res = await this._getOrder('POST')
      if (res.code !== 200) {
        return
      }
      if (this.type === 'state') {
        const myOrderState = [].concat(this.orderState)
        myOrderState.push(this.stateData)
        this.setOrderState(myOrderState)
      } else if (this.type === 'school') {
        const myOrderSchool = [].concat(this.orderSchool)
        myOrderSchool.push(this.schoolData)
        this.setOrderSchool(myOrderSchool)
      }
    },
    async handleCancel () {
      const res = await this._getOrder('DELETE')
      if (res.code !== 200) {
        return
      }
      if (this.type === 'state') {
        const myOrderState = [].concat(this.orderState)
        myOrderState.forEach((item, index) => {
          if (item.name === this.stateData.name) {
            myOrderState.splice(index, 1)
          }
        })
        this.setOrderState(myOrderState)
      } else if (this.type === 'school') {
        const myOrderSchool = [].concat(this.orderSchool)
        myOrderSchool.forEach((item, index) => {
          if (item.name === this.schoolData.name) {
            myOrderSchool.splice(index, 1)
          }
        })
        this.setOrderSchool(myOrderSchool)
      }
    },
    async _getOrder (method) {
      if (this.type === 'state') {
        this.params = {
          dt: 'State',
          dn: this.name,
          subscriber: this.loginId,
          action: 'add'
        }
      } else if (this.type === 'school') {
        this.params = {
          dt: 'nCov School Data',
          dn: this.name,
          subscriber: this.loginId,
          action: 'add'
        }
      }

      if (method === 'DELETE') {
        this.params.action = 'del'
      }
      this.loading = true
      const res = await this.$api['auth/handleSub'](this.params)
      console.log('this.res', res)
      this.loading = false
      Toast(res.msg)
      return res
    }

  }
}
</script>

<style scoped lang="less">
.orderTag{
    line-height:20px;
    position:relative;
    top:-10px;
    .wrap{
        padding:2px 6px;
        border:1px solid #fc8300;
        border-radius:3px;
        display:flex;
        justify-content: center;
        align-items: center;
        p{
            margin:0;
            font-size:12px;
        }
        .spin{
          position:relative;
          top:2px;
        }
    }
    .getOrder{
        display:flex;
        align-items:center;
        i{
            color:#fc8300
        }
        p{
            color:#fc8300
        }
    }
    .cancelOrder{
        border-color:#999;
    }
}
</style>
