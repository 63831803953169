<template>
  <div class="tips">
    <!-- <div class="school-link" @click="goToSchool('rank')">
      <div class="school-link-left">
        <i class="iconfont icon-university"></i>
        <span>2021最新排名</span>
        <span>|</span>
        <span class="school">查看全美高校确诊病例</span>
      </div>
      <div class="school-link-right">
        <a-icon type="right"></a-icon>
      </div>
    </div>
    <div class="school-link" @click="goToSchool('money')">
      <div class="school-link-left">
        <i class="iconfont icon-gongzi"></i>
        <span>2021薪资排行榜</span>
        <span>|</span>
        <span class="school">查看全美高校毕业年薪</span>
      </div>
      <div class="school-link-right">
        <a-icon type="right"></a-icon>
      </div>
    </div> -->
    <div class="school-link" @click="goToSchool('rank')">
      <div class="school-link-left">
        <i class="iconfont icon-university"></i>
        <span>最新排名</span>
        <span>|</span>
        <span class="school">2022 USNEWS美国大学排名出炉</span>
      </div>
      <div class="school-link-right">
        <a-icon type="right"></a-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    goToSchool(type) {
      console.log(type, 'type');
      if (type === 'money') {
        this.$router.push({
          path: '/schoolList',
          query: { type: 'money' }
        });
      } else {
        this.$router.push('/schoolList');
      }
    }
  }
};
</script>

<style scoped lang="less">
.tips {
  margin-top: 20px;
  .school-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 16px;

    padding: 0 10px;
    border: 1px solid #ededed;
    border-radius: 6px;
    box-shadow: 3px 1px 9px rgba(0, 0, 0, 0.2);

    height: 35px;
    font-size: 0.24rem;
    .school-link-left {
      display: flex;
      align-items: center;
      .iconfont {
        color: rgb(174, 33, 44);
        margin-right: 5px;
        &.icon-gongzi {
          color: rgb(167, 170, 12);
        }
      }
      span {
        margin-right: 5px;
      }
      .school {
        color: #4169e2;
      }
    }
  }
}
</style>
