/** @format */

export default [
  {
    name: 'map',
    method: 'GET',
    desc: '州地图',
    path: '/assets/map/us/AK.json'
  },
  {
    name: 'mapData',
    method: 'GET',
    desc: '州地图疫情数据',
    path: '/api/method/soa.ncov.api.coronavirus_county'
  }

]
