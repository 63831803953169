/** @format */

import axios from './axios'
import api from './api'
import eventbus from './eventbus'
import * as utils from '../utils'

export default {
  install: (Vue) => {
    // 挂载实例
    Vue.prototype.$ajax = axios
    Vue.prototype.$api = api
    Vue.prototype.$bus = eventbus
    Vue.prototype.$utils = utils
  }
}
