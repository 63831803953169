<template>
  <div class="topHeader">
    <div class="titleWrap" :class="{ pad: padding }">
      <span :style="{ background: color }"></span>
      <h2 class="title">{{ title }}</h2>
      <div class="label" v-if="label">{{ label }}</div>
      <slot></slot>
    </div>
    <div class="right" v-if="hasRight" @click="handleRight">
      <p>全部</p>
      <a-icon type="right"></a-icon>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      default: '',
      type: String
    },
    label: {
      default: '',
      type: String
    },

    padding: {
      default: false,
      type: Boolean
    },
    hasRight: {
      default: false,
      type: Boolean
    },
    color: {
      default: '#4169e2',
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleRight() {
      this.$emit('handleRight');
    }
  }
};
</script>

<style scoped lang="less">
.titleWrap {
  display: flex;
  align-items: center;
  span {
    width: 3px;
    height: 20px;
    background: #4169e2;
    margin-right: 10px;
  }
  .title {
    font-size: 18px;
    margin: 10px 0;
  }
  .label {
    margin-left: 10px;
    color: #999;
  }
}
.pad {
  padding: 0 16px;
}
.topHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .right {
    display: flex;
    align-items: center;
    margin-right: 16px;
    p {
      margin: 0;
    }
  }
}
</style>
