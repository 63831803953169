<template>
  <div id="app">
    <!-- <ShareDom></ShareDom> -->
    <ShareDomNews></ShareDomNews>
    <div style="min-height:70vh;background:#fff">
      <keep-alive
        :exclude="[
          'StateMap',

          'VideoDetail',
          'HotAsk',
          'Subscription',
          'ReportDetail',
          'SchoolList',
          'WelfareDetail',
          'ExpertDetail',
          'AuthorDetail',
          'NewsDetail',
          'AuthorList'
        ]"
      >
        <router-view :key="$route.fullPath" />
      </keep-alive>
    </div>
    <foot></foot>
    <!-- <affix></affix> -->
    <handle-sub></handle-sub>
    <show-welfare ref="welfare"></show-welfare>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import '@vant/touch-emulator';
import { Foot, Affix, ShareDom, HandleSub, ShareDomNews, ShowWelfare } from '@/components';
export default {
  name: 'App',
  components: { Foot, Affix, ShareDom, HandleSub, ShareDomNews, ShowWelfare },
  beforeMount() {
    const docEl = document.documentElement;
    const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
    const recalc = function() {
      let deviceWidth = docEl.clientWidth;
      if (!deviceWidth) return;
      if (deviceWidth > 750) deviceWidth = 750;
      document.documentElement.style.fontSize = deviceWidth / 7.5 + 'px';
    };
    if (!document.addEventListener) return;
    recalc();
    window.addEventListener(resizeEvt, recalc, false);
    document.addEventListener('DOMContentLoaded', recalc, false);
    window.recalc = recalc;
  },
  created() {
    this.GetOrder();
    this.GetBlock();
    this.GetAd();
    this.GetAskTags();
    this.GetNewsAd();
    this.GetWelfareItem();
  },
  computed: {
    ...mapGetters(['loginId'])
  },
  methods: {
    ...mapActions(['GetBlock', 'GetAd', 'GetOrder', 'GetAskTags', 'GetNewsAd', 'GetWelfareItem']),
    ...mapMutations({
      setShowSub: 'SET_SHOW_SUB'
    })
  },
  mounted() {}
};
</script>

<style>
html {
  font-size: 100px;
}

#app {
  overflow: hidden;
  font-family: Arial, '微软雅黑', '黑体';
  font: inherit;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.title {
  font-size: 21px;
  font-weight: bold;
  margin: 20px 0 10px;
  text-align: left;
  color: #333;
}

h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
}
p {
  color: #666;
  font-size: 14px;
  text-align: left;
  margin: 0;
  padding: 0;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
.autoline {
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
<style lang="less">
.van-nav-bar__left .van-icon-arrow-left {
  color: @primary-color;
}
.van-nav-bar__left .van-nav-bar__text {
  color: @primary-color;
}
.van-nav-bar--fixed {
  z-index: 10;
}
.ant-empty-description {
  text-align: center;
}
</style>
