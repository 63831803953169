import { render, staticRenderFns } from "./SchoolNews.vue?vue&type=template&id=6f0f3b58&scoped=true&"
import script from "./SchoolNews.vue?vue&type=script&lang=js&"
export * from "./SchoolNews.vue?vue&type=script&lang=js&"
import style0 from "./SchoolNews.vue?vue&type=style&index=0&id=6f0f3b58&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f0f3b58",
  null
  
)

export default component.exports