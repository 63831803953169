<template>
  <div>
    <van-sticky :offset-top="offsetTop" class="sticky" :style="{top:`${top}px`}">
      <div class="wrap">
        <div class="loginWrap">
          <div class="iconWrap">
            <a-icon type="mail"></a-icon>
          </div>
          <p @click="setShowSub(true)" v-if="!loginId">订阅</p>
          <p @click="goToOrder" v-else class="text">{{ loginId }}</p>
        </div>
      </div>
      <!-- <div class="wrap" v-else>
        <div class="loginWrap">
          <p @click="goToOrder">{{ loginId }}</p>
        </div>

      </div> -->
    </van-sticky>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {

  },
  props: {
    offsetTop: {
      default: 80,
      type: Number
    },
    top: {
      default: 0,
      type: Number
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['loginId', 'orderSchool', 'orderState']),

    hasData () {
      if (this.orderSchool.length || this.orderState.length) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    ...mapMutations({
      setShowSub: 'SET_SHOW_SUB'
    }),
    goToOrder () {
      console.log(123)
      if (this.hasData) {
        this.$router.push('subscription')
      } else {
        this.$router.push('transition')
      }
    }
  }
}
</script>

<style scoped lang="less">
.sticky{
    position:absolute;
    right:-2px;
    top:0;
    // z-index:100;
    .wrap{
        display:flex;
        justify-content: flex-end;
        .loginWrap{
            padding:5px 10px;
            max-width:110px;
            border:1px solid #e7e7e7;
            border-top-left-radius: 23px;
            border-bottom-left-radius: 23px;
            background: #fff;
            color:#fff;
            display:flex;
            align-items:center;
            justify-content: center;
            font-size:12px;
            .iconWrap{
                background:@primary-color;
                margin-right:5px;
                width:26px;
                height:26px;
                border-radius:13px;
                font-size:15px;
                display:flex;
                align-items:center;
                justify-content: center;
            }
            p{
                flex:1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align:center;
                color:@primary-color;
                margin:0;
                font-size:12px;
                &.text{
                    color:#666
                }
            }
        }

    }
  }

/deep/ .van-sticky--fixed{
  right:-2px;
  text-align:right;
}
</style>
