<template>
  <div class="transition">
    <van-nav-bar fixed title="我的订阅" left-text="返回" left-arrow @click-left="onClickLeft"> </van-nav-bar>
    <div class="stateWrap">
      <top-header title="可能感兴趣的州"></top-header>
      <p>选择并订阅您关注的州</p>
      <search-state @search="handleSearchState"></search-state>

      <div class="stateList" v-if="showStateList.length">
        <state-item v-for="item in showStateList" :key="item.name" :item-data="item"></state-item>
      </div>
      <a-empty class="noData" v-else description="暂无数据"></a-empty>
    </div>
    <div class="schoolWrap">
      <top-header title="可能感兴趣的学校"></top-header>
      <p>选择并订阅您关注的学校</p>

      <search-school @search="handleSearchSchool"></search-school>
      <div id="schoolList" v-if="showSchoolList.length">
        <div class="schoolList">
          <school-item v-for="item in oddSchoolList" :key="item.name" :item-data="item"></school-item>
        </div>
        <div class="schoolList">
          <school-item v-for="item in eddSchoolList" :key="item.name" :item-data="item"></school-item>
        </div>
      </div>
      <a-empty class="noData" v-else description="暂无数据"></a-empty>
    </div>
    <div class="btn">
      <a-button type="primary" block @click="goToSubscription">进入我的订阅</a-button>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import { mapState } from 'vuex';
import StateItem from './components/StateItem';
import SchoolItem from './components/SchoolItem';

import SearchState from './components/SearchState';
import SearchSchool from './components/SearchSchool';
export default {
  components: {
    TopHeader,
    StateItem,
    SchoolItem,
    SearchState,
    SearchSchool
  },
  props: {},
  data() {
    return {
      showStateList: [],
      showSchoolList: [],
      loadmoreHeight: 50
    };
  },
  computed: {
    ...mapState({
      stateList: state => state.app.stateList
    }),
    oddSchoolList() {
      return this.showSchoolList.filter((item, index) => {
        return index % 2 === 0;
      });
    },
    eddSchoolList() {
      return this.showSchoolList.filter((item, index) => {
        return index % 2 !== 0;
      });
    }
  },
  watch: {
    stateList(newV) {
      this.showStateList = newV;
    }
  },
  created() {
    this.paramsSchool = {
      limit_start: 0,
      limit_page_length: 0,
      or_filters: [{ ncov_confirmed: ['>', 0] }, { course_status: 'Yes' }],
      order_by:
        'ncov_confirmed desc,isnull(course_status),length(course_status) desc,isnull(latest_ranking_2022),length(latest_ranking_2022),latest_ranking_2022 asc'
    };
    this.paramsLocation = {
      lng: '',
      lat: ''
    };
    this.getSchoolList();
  },
  mounted() {
    this.showStateList = this.stateList;
    if (navigator.geolocation) {
      const that = this;
      navigator.geolocation.getCurrentPosition(
        function(res) {
          console.log(that, 'navigator');
          console.log(res, 'navigator');
          that.paramsLocation.lat = res.coords.latitude;
          that.paramsLocation.lng = res.coords.longitude;
          that.getLocation();
        },
        function(err) {
          console.log(err, 'navigator');
        }
      );
    }
  },
  activated() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.transition = true;
      }

      console.log('fromvm schiool', from, vm);
    });
  },
  methods: {
    handleSearchState(value) {
      this.showStateList = this.stateList.filter(item => {
        return item.cn_state.includes(value) || item.state.includes(value);
      });
    },
    handleSearchSchool(value) {
      this.showSchoolList = this.schoolList.filter(item => {
        return item.school_cn.includes(value) || item.school.includes(value);
      });
    },
    goToSubscription() {
      this.$router.replace({
        path: '/subscription'
      });
    },
    getLocation() {
      this.$api['auth/location'](this.paramsLocation).then(res => {
        if (res.message) {
          this.stateList.forEach((item, index) => {
            if (item.state === res.message.state_name) {
              const newStateList = [].concat(this.stateList);
              newStateList.splice(index, 1);
              this.showStateList = [].concat(item, newStateList);
              console.log(this.showStateList, 'navigator');
            }
          });
        }
      });
    },
    getSchoolList() {
      this.$api['home/measure'](this.paramsSchool).then(res => {
        if (res.message) {
          this.schoolList = res.message;
          this.showSchoolList = res.message.slice(0, 20);
        }
      });
    },

    onClickLeft() {
      console.log(this.$router);
      if (this.transition) {
        this.$router.back();
      } else {
        this.$router.push('/');
      }
    }
  }
};
</script>

<style scoped lang="less">
.transition {
  margin-top: 46px;
  padding: 0 16px;
  p {
    margin-bottom: 10px;
  }
  .stateWrap {
    .stateList {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
  }
  .schoolWrap {
    #schoolList {
      overflow-x: scroll;
    }
    .schoolList {
      display: flex;
      flex-wrap: nowrap;
    }
  }
  .btn {
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 0 30px;
  }
}
.stateList::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0);
}
.stateList::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.stateList::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  min-height: 28px;
}
.stateList::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
#schoolList::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0);
}
#schoolList::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
#schoolList::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  min-height: 28px;
}
#schoolList::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.noData {
  margin-top: 20px;
}
/deep/ .ant-empty-description {
  text-align: center;
}
</style>
