<template>
  <div class="listItem">
    <div class="ad" v-if="item&&item.node_category === '广告-AD'">
      <a :href="item.original_url" target="_blank" v-if="item.attach_image_url">
        <img :src="item.attach_image_url" class="img" />
      </a>
      <div class="adTextWrap" v-else>
        <van-tag class="tag" type="primary">Ad</van-tag>
        <span class="text">{{ item.title }}</span>
        <div class="autoline adcontent">{{ item.cn_content }}</div>
      </div>
      <div
        class="report"
        v-if="group && group.block_content_list"
        v-html="group.block_content_list[0].block_content"
      ></div>
    </div>
    <div class="normal" v-else>
      <h4 v-if="from !== 'shareDom'&&item.modified">
        <span
          class="checkTime"
          v-if="$utils.checkTime(item.modified)"
        >更新于：{{ item.modified | checkTime }}</span>
        <span>{{ item.modified | moment("YYYY-MM-DD") }}</span>
        <!-- <a class="ml10p"><span @click="goToState"> {{ item.state_shortname }}</span></a> -->
      </h4>
      <div class="mainWrap" @click="goToDetail">
        <p class="autoline title">{{ item.title }}</p>
        <p class="autoline" v-if="from !== 'shareDom'">
          <read-more class="contentLine8" :item="item" :search="search"></read-more>
        </p>
        <p class="location" v-if="(item.state_cn || item.ncov_school_cn) &&from !== 'shareDom'">
          <a-icon class="icon" type="environment"></a-icon>
          <span @click.stop="goToState" class="stateName"> {{ item.state_cn }}</span>
          <span class="sep" v-if="item.state_cn && item.ncov_school_cn">|</span>
          <span @click.stop="goToSchool" class="schoolName"> {{ item.ncov_school_cn }}
          </span>
        </p>
        <p @click.stop="goToLink" class="link" v-if="item.original_url && from !== 'shareDom'">
          来源：
          <span> {{ item.original_url }}</span>
        </p>
        <div class="tagWrap">
          <van-tag class="tagCategory">{{ category }}</van-tag>
          <span v-if="item.pv_count">阅读：{{ item.pv_count }}</span>
          <a-button
            type="primary"
            size="small"
            @click.stop="handleShare"
            v-if="from !== 'shareDom'"
          >
            <i type="left" class="iconfont icon-share"></i> 分享
          </a-button>
        </div>
      </div>
    </div>
    <van-overlay :show="isShowShare" @click="isShowShare = false">
      <div class="showImg shareShare">
        <div v-if="shareImg" class="shareImgWrap">
          <img :src="shareImg" alt id="shareImg" @click="handleImg" />
          <p>长按图片保存到手机，即可分享到微信朋友圈</p>
        </div>
        <van-loading v-else size="24px" color="#fff" type="spinner" vertical>
          <span class="loading">分享图片生成中...</span>
        </van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import ReadMore from './ReadMore'
import { ImagePreview } from 'vant'
import { mapMutations, mapGetters } from 'vuex'
export default {
  components: {
    ReadMore
  },
  props: {
    from: {
      default: '',
      type: String
    },
    itemData: {
      default: () => {},
      type: Object
    },
    search: {
      default: '',
      type: String
    }

  },
  data () {
    return {
      shareImg: '',
      isShowShare: false,
      pv: 0
    }
  },
  computed: {
    ...mapGetters(['group']),
    category () {
      console.log('ta', this.itemData.node_category.split('-')[0])
      return `#${this.itemData.node_category.split('-')[0]}#`
    },
    item () {
      return {
        ...this.itemData,
        pv_count: this.itemData.pv_count + this.pv
      }
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    ...mapMutations({
      setShareDomNews: 'SET_SHARE_DOM_NEWS'
    }),
    handleImg () {
      ImagePreview({
        images: [this.shareImg]

      })
    },
    goToDetail () {
      this.$router.push({
        path: '/newsDetail',
        query: {
          name: this.itemData.name
        }
      })
      this.pv = this.pv + 1
    },
    goToState () {
      if (this.from === 'state') {
        return
      }
      this.$router.push({
        path: '/state',
        query: {
          name: this.itemData.state,
          short_name: this.itemData.state_shortname
        }
      })
    },
    goToSchool () {
      if (this.from === 'school') {
        return
      }
      this.$router.push({
        path: '/school',
        query: {
          name: this.itemData.ncov_school,
          state: this.itemData.state_shortname

        }
      })
    },
    async handleShare () {
      this.setShareDomNews(this.itemData)
      this.isShowShare = true
      this.$bus.$emit('isShowShareDomNews', { show: true, content: 'newsItem' })
      this.$nextTick(async () => {
        const dom = document.getElementById('shareDomNews')
        console.log('tt', dom)
        const canvas = await html2canvas(dom, {
          useCORS: true,
          allowTaint: true,

          x: dom.offsetLeft - dom.offsetWidth / 2,
          y: window.pageYOffset

        })
        console.log(1234)
        this.shareImg = canvas.toDataURL()
        this.$bus.$emit('isShowShareDomNews', { show: false })
      })
    }
  }
}
</script>

<style scoped lang="less">
p {
  margin: 0;
}
.listItem {
  width: 100%;
  .ad {
    width: 100%;
    img {
      width: 100%;
    }
    .report {
      display: block;
      margin-top: 10px;
      img {
        width: 100%;
      }
    }
  }
  .adTextWrap {
    border: 1px solid #e7e7e7;
    padding: 6px 10px;
    text-align: left;
    align-items: center;
    border-radius: 5px;
    .tag {
      position: relative;
      top: -2px;
    }
    .text {
      word-wrap: break-word;
      white-space: pre-wrap;
      color: #4169e2;
      margin-left: 10px;
      font-size: 16px;
    }
    .adcontent {
      padding-top: 10px;
    }
  }
}
h4 {
  color: #999;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 400;
  .checkTime {
    font-size: 14px;
    margin-right: 20px;
  }
}
.mainWrap {
  background: #f7f7f7;
  padding: 12px;
  text-align: left;
  .tagWrap {
    padding-top: 5px;
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      margin: 0;
    }
    button {
      background: #f7f7f7;
      border: none;
      color: #fc8300;
      box-shadow: none;
    }
  }
  .tagCategory {
    margin-top: 10px;
    background: #f7f7f7;
    padding: 3px 5px;
    color: #333;
    font-size: 14px;
  }
}
.link {
  font-weight: 400;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;

  color: #b4b4b4;
  display: -webkit-box;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  a {
    color: #b4b4b4;
  }
}
.location {
  font-weight: 400;
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  .icon {
    margin-right: 10px;
    color: #999;
    font-size: 14px;
    position: relative;
    top: 1px;
  }
  .stateName {
    font-weight: bold;
    color: #4169e2;
  }
  .sep {
    margin: 0 20px;
    color: #333;
  }
  .schoolName {
    font-weight: bold;
    color: #f27355;
  }
}
.autoline {
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  color: #555;
  margin-bottom: 12px;
  &.title {
    font-size: 15px;
    font-weight: 500;
    color: @primary-color;
  }
}
.ml10p {
  margin-left: 10px;
  color: #4169e2;
}

.showImg {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .shareImgWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  p {
    margin-top: 10px;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }
  img {
    width: 60%;
    max-width: 450px;
  }
  .loading {
    color: #fff;
    font-weight: bold;
  }
}
/deep/ .van-overlay {
  z-index: 100;
}
</style>
