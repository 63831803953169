<template>
  <div class="practice">
    <van-nav-bar fixed title="实习项目" left-text="返回" left-arrow @click-left="onClickLeft"> </van-nav-bar>

    <van-tabs
      v-model="active"
      :border="true"
      offset-top="46"
      sticky
      swipeable
      scrollspy
      title-active-color="#f5631e"
      color="#f5631e"
      background="#f7f7f7"
    >
      <van-tab title="项目简介">
        <practice-des></practice-des>
        <!-- <practice-tab1></practice-tab1> -->
      </van-tab>
      <!-- <van-tab title="全部项目" v-if="practiceTab2.length">
        <practice-tab2></practice-tab2>
      </van-tab> -->
      <van-tab title="科研项目" v-if="practiceTab3.length">
        <practice-tab3></practice-tab3>
      </van-tab>
      <van-tab title="实习项目" v-if="practiceTab4.length">
        <practice-tab4></practice-tab4>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import PracticeDes from './item/PracticeDes';
import PracticeTab1 from './item/PracticeTab1';
import PracticeTab2 from './item/PracticeTab2';
import PracticeTab3 from './item/PracticeTab3';
import PracticeTab4 from './item/PracticeTab4';
import { mapState, mapMutations } from 'vuex';
export default {
  components: {
    PracticeDes,
    PracticeTab1,
    PracticeTab2,
    PracticeTab3,
    PracticeTab4
  },
  props: {},
  data() {
    return {
      active: 0
    };
  },
  computed: {
    ...mapState({
      practiceTab2: state => state.searchArea.practiceTab2,
      practiceTab3: state => state.searchArea.practiceTab3,
      practiceTab4: state => state.searchArea.practiceTab4
    })
  },
  watch: {},
  created() {
    this.params2 = {
      filters: { node_category: this.$utils.newsCategory.ju, ju_promotion_category: ['!=', 'Offer榜'] }
    };
    this.params3 = {
      filters: { node_category: this.$utils.newsCategory.ju, ju_promotion_category: '科研项目' }
    };
    this.params4 = {
      filters: { node_category: this.$utils.newsCategory.ju, ju_promotion_category: '实习项目' }
    };
    this._getData('setPracticeTab2', this.params2);
    this._getData('setPracticeTab3', this.params3);
    this._getData('setPracticeTab4', this.params4);
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.practice = true;
      }

      console.log('fromvm stae', from, vm);
    });
  },
  methods: {
    ...mapMutations({
      setPracticeTab2: 'SET_PRACTICE_TAB2',
      setPracticeTab3: 'SET_PRACTICE_TAB3',
      setPracticeTab4: 'SET_PRACTICE_TAB4'
    }),
    _getData(handle, params) {
      this.$api['home/news'](params).then(res => {
        if (res.message) {
          this[handle](res.message);
        }
      });
    },
    onClickLeft() {
      console.log(this.$router);
      if (this.practice) {
        this.$router.back();
      } else {
        this.$router.push('/');
      }
    }
  }
};
</script>

<style scoped lang="less">
.practice {
  margin-top: 46px;
  .des {
    text-align: left;
  }
}
</style>
