<template>
  <div class="schoolData">
    <div class="top">
      <!-- <h4 class="title">{{ school.school_cn }}</h4> -->
      <div class="topTitle">
        <p class="enTitle">{{ school.school }}</p>
      </div>

      <div class="conWrap">
        <p class="schoolState" v-if="school.school_state_short">
          所在州：
          <a @click="goToState(school)">{{ school.school_state_short }}</a>
        </p>
        <p class="schoolState" v-if="school.school_county_name">郡县：{{ school.school_county_name }}</p>
      </div>
      <div class="tag-wrap">
        <van-tag plain size="medium" color="#4169e2">{{ school.infoset_school_type_cn }}</van-tag>
        <van-tag plain size="medium" color="#4169e2">{{ school.infoset_religious_belief_cn }}</van-tag>
        <van-tag plain size="medium" color="#4169e2">{{ school.infoset_academic_calendar_cn }}</van-tag>
      </div>
      <Sticky :offset-top="100"></Sticky>
    </div>
    <RankLine :school="school"></RankLine>
    <div class="part summary">
      <TopHeader title="学校简介">
        <div class="website" @click="goToWebsite">官网</div>
      </TopHeader>
      <p class="autoline">{{ school.un_madlib_cn || '暂无数据' }}</p>
    </div>
  </div>
</template>

<script>
import { TopHeader, Sticky } from '@/components';
import RankLine from './RankLine';
import { mapState } from 'vuex';
export default {
  components: {
    TopHeader,
    RankLine,
    Sticky
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      school: state => state.app.schoolData
    })
  },

  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleMore() {
      this.showMore = true;
    },
    handleHide() {
      this.showMore = false;
    },
    goToWebsite() {
      window.open(this.school.infoset_official_website);
    },
    goToTran(url) {
      window.open(url);
    },

    goToState(item) {
      this.$router.push({
        path: '/state',
        query: {
          name: item.school_state,
          short_name: item.school_state_short
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.schoolData {
  background: #f7f7f7;
}
.top {
  padding: 0 16px;
  position: relative;
  background: #fff;
  p {
    margin-bottom: 8px;
    font-weight: 400;
    color: #333;
    text-align: left;
  }
  .topTitle {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    .enTitle {
      font-size: 18px;
      font-weight: 500;
      margin-right: 50px;
      width: 70%;
    }
  }

  .conWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2px;
    .schoolState {
      text-align: left;
      margin-right: 30px;
    }
  }
  .tag-wrap {
    display: flex;
    flex-direction: row;

    align-items: center;
    span {
      margin-right: 10px;
      border-radius: 20px;
      &:after {
        border-radius: 20px;
      }
    }
  }
}
.part {
  padding: 0 16px;
  background: #fff;
  padding-bottom: 20px;
  .bgColor {
    background: #f7f7f7;
  }
  .autoline {
    word-wrap: break-word;
    white-space: pre-wrap;
    color: #333;
    margin-bottom: 0;
  }
}
.summary {
  .website {
    width: 50px;
    height: 22px;
    border: 1px solid #4169e2;
    color: #4169e2;
    border-radius: 5px;
    line-height: 21px;
    margin-left: 20px;
  }
  .tags {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
