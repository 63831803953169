<template>
  <div class="reportDetail">
    <van-nav-bar fixed title="留学日报" left-text="返回" left-arrow @click-left="onClickLeft"> </van-nav-bar>
    <h4>{{ title }}</h4>
    <div class="topWrap">
      <van-tag :color="tagType">{{ report.report_name }}</van-tag>
      <p>{{ report.publish_date }}</p>
    </div>
    <div class="con">
      <span v-html="report.rich_content"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportDetail',
  components: {},
  props: {},
  data() {
    return {
      report: {}
    };
  },
  computed: {
    tagType() {
      const category = {
        求职日报: '#D84847',
        转升日报: '#2ea878',
        学术日报: '#e6a53d'
      };
      return category[this.report.report_name];
    },
    title() {
      const category = {
        求职日报: 'JobUpper日报-与你一起了解美国就业最新动态，一起在逆境中把握职业发展机会',
        转升日报: '转升学日报 - 美国大学申请与学术最新动态',
        学术日报: '护学星日报 - 逆境变转机，陪伴孩子们顺利完成学业，走向优秀'
      };

      return category[this.report.report_name];
    }
  },
  watch: {},
  created() {
    this.report = this.$route.query.item;
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.reportDetail = true;
      }

      console.log('fromvm stae', from, vm);
    });
  },
  methods: {
    onClickLeft() {
      console.log(this.$router);
      if (this.reportDetail) {
        this.$router.back();
      } else {
        this.$router.push('/');
      }
    }
  }
};
</script>

<style scoped lang="less">
.reportDetail {
  padding-top: 46px;
  margin: 0 16px 20px;
  h4 {
    margin-top: 15px;
    font-size: 16px;
  }
  p {
    margin: 0;
  }
  .topWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    span {
      font-weight: bold;
      font-size: 12px;
    }
    p {
      font-size: 12px;
    }
  }
  .con {
    text-align: left;
    font-size: 14px;
  }
}
</style>
