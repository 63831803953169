<template>
  <div class="offer" v-if="offer.length">
    <top-header title="录取喜报"></top-header>
    <div>
      <div class="list-wrap">
        <div class="item-wrap" v-for="(item, index) in offer" :key="item.name">
          <img :src="$utils.checkImg(item)" alt @click="showBig(index)" />
          <p class="title" @click="goToSchool(item)">{{ item.ncov_school_cn }}</p>
          <p class="rank">全美排名：{{ item.ncov_school_ranking }}</p>
          <p class="tag">恭喜{{ item.title }} 🎉</p>
        </div>
      </div>

      <load-more
        style="margin:0 0 20px;"
        :is-text="true"
        title="更多喜报"
        @loadMore="loadMore"
        v-if="isShowLoadMore"
        :has-more="hasMore"
        :loading="loading"
      ></load-more>
    </div>

    <!-- <a-empty class="noData" v-else description="暂无数据"></a-empty> -->
  </div>
</template>

<script>
import { FlowList, TopHeader, LoadMore } from '@/components';
import { ImagePreview } from 'vant';
export default {
  components: {
    FlowList,
    TopHeader,
    LoadMore
  },
  props: {},
  data() {
    return {
      offer: [],
      hasMore: true,
      loading: false
    };
  },
  computed: {
    isShowLoadMore() {
      return this.offer.length >= this.params.limit_page_length;
    },
    imgArr() {
      return this.offer.map(item => {
        return this.$utils.checkImg(item);
      });
    }
  },
  created() {
    this.params = {
      limit_start: 0,
      limit_page_length: 3,
      filters: { node_category: this.$utils.newsCategory.offer, ncov_school: '' }
    };
  },
  mounted() {},
  activated() {
    this.initCheck();
  },
  watch: {},
  methods: {
    initCheck() {
      const name = this.$route.query.name;

      this.params.filters.ncov_school = name;
      this.offer = [];
      this.hasMore = true;
      this.params.limit_start = 0;

      this.getNews();
    },
    loadMore() {
      this.params.limit_start += this.params.limit_page_length;
      this.getNews();
    },

    getNews() {
      this.loading = true;
      this.$api['home/news'](this.params)
        .then(res => {
          console.log(res, 'ameTable');
          if (res.message) {
            if (res.message.length < this.params.limit_page_length) {
              this.hasMore = false;
            }
            this.offer = [].concat(this.offer, res.message);
          } else {
            this.hasMore = false;
          }
          this.loading = false;
        })
        .catch(err => {
          console.log('err', err);
        });
    },
    showBig(index) {
      ImagePreview({
        images: this.imgArr,
        startPosition: index
      });
    },

    goToSchool(item) {
      console.log(item);
      this.$router.push({
        path: `/school/${item.ncov_school}`,
        query: {
          state: item.state_shortname
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.list-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item-wrap {
    padding-bottom: 15px;
    width: 30%;
    margin-right: 5%;
    &:nth-child(3n) {
      margin-right: 0;
    }
    img {
      width: 100%;
    }
    .title {
      font-size: 14px;
      margin-top: 8px;
      color: #4169e2;
    }
    .rank {
      font-size: 14px;
      margin-top: 8px;
      color: #666;
    }
    .tag {
      margin-top: 8px;
    }
  }
}

.offer {
  padding: 0 16px;
}
</style>
