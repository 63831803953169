<template>
  <div class="list refCon" v-if="tableData.length">
    <top-header title="学校动态"></top-header>

    <a-timeline style="margin-top:10px;">
      <div v-for="(item,index) in tableData" :key="item.name">
        <a-timeline-item>
          <div slot="dot" class="dot">
            <div></div>
          </div>
          <news-item :item-data="item" :from="isShareDom?'shareDom':'school'"></news-item>
        </a-timeline-item>
        <a-timeline-item v-if="index===1">
          <div slot="dot" class="dot">
            <div></div>
          </div>
          <news-item :item-data="newsAd[0]" :from="isShareDom?'shareDom':'school'"></news-item>
        </a-timeline-item>
        <a-timeline-item v-if="index===6">
          <div slot="dot" class="dot">
            <div></div>
          </div>
          <news-item :item-data="newsAd[1]" :from="isShareDom?'shareDom':'school'"></news-item>
        </a-timeline-item>
      </div>
    </a-timeline>
    <load-more
      style="margin:0 0 20px;"
      :is-text="true"
      @loadMore="loadMore"
      v-if="isShowLoadMore&&!isShareDom"
      :has-more="hasMore"
      :loading="loading"
    ></load-more>
  </div>
</template>

<script>
import { LoadMore, NewsItem } from '@/components'
import TopHeader from '@/components/TopHeader'
import { mapState } from 'vuex'
export default {
  components: {
    LoadMore, NewsItem, TopHeader
  },
  props: {
    isShareDom: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      tableData: [],
      hasMore: true,
      loading: false
    }
  },
  computed: {
    ...mapState({
      newsAd: state => state.app.newsAd
    }),
    isShowLoadMore () {
      return this.tableData.length >= this.params.limit_page_length
    }
  },
  watch: {

  },
  created () {
    this.params = {
      limit_start: 0,
      limit_page_length: this.isShareDom ? 1 : 10,
      filters: { ncov_school: '', node_category: ['in', ['校园疫情-School News', '学业新闻-Study&Growth']] }
    }
  },
  mounted () {

  },
  activated () {
    console.log(123)
    this.initCheck()
  },
  methods: {
    loadMore () {
      this.params.limit_start += this.params.limit_page_length
      this.getNews()
    },
    initCheck () {
      const name = this.$route.query.name

      this.params.filters.ncov_school = name
      this.tableData = []
      this.params.limit_start = 0
      this.hasMore = true
      console.log('this.tableData', this.tableData)
      this.getNews()
    },
    getNews () {
      this.loading = true
      this.$api['home/news'](this.params)
        .then(res => {
          console.log(res, 'ameTable')
          if (res.message) {
            if (res.message.length < this.params.limit_page_length) {
              this.hasMore = false
            }
            this.tableData = [].concat(this.tableData, res.message)
          } else {
            this.hasMore = false
          }
          this.loading = false
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  }
}
</script>

<style scoped lang="less">
.list{
    padding:0 16px;
    .autoline {
      word-wrap: break-word;
      white-space: pre-wrap;
    }
    .link{
      font-weight:bold;
      a{
         color:#44c477
      }

    }
}

h4{
  font-size:16px;
  font-weight:600;
}
.dot{
  width:14px;
  height:14px;
  border-radius:7px;
  background:#c7d4f7;
  display:flex;
  justify-content: center;
  align-items: center;
  div{
    width:6px;
    height:6px;
    border-radius:3px;
    background:#3f69e2;
  }
}
/deep/ .ant-timeline-item-tail{
  border-left-color:#c7d4f7
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail{
  display:block
}
</style>
