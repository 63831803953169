<template>
  <div class="welfare">
    <van-nav-bar
      fixed
      title="公益讲座"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="wrap">
      <div class="tag-wrap">
        <a-button class="tag" :class="{active:active===index}" v-for="(tagItem,index) in tagList" :key="index" @click="clickTag(tagItem,index)">{{ tagItem }}</a-button>
      </div>
      <div class="list-wrap">
        <flow-list url="home/news" :params="params" :sep="false" v-if="isShow">
          <template slot-scope="row">
            <div>
              <welfare-item :item-data="row.itemData"></welfare-item>
            </div>
          </template>
        </flow-list>
      </div>
    </div>
  </div>
</template>

<script>
import FlowList from '@/components/FlowList'
import WelfareItem from './components/WelfareItem'
import { backInitMixin } from '@/utils/mixin'
export default {
  components: {
    FlowList, WelfareItem
  },
  mixins: [backInitMixin],
  props: {

  },
  data () {
    return {
      tagList: ['全部', '学校/专业解析', '转学秘籍', '实习就业技巧', '招生官讲座', '大学公开讲座'],
      params: {
        limit_start: 0,
        limit_page_length: 10,
        filters: { node_category: this.$utils.newsCategory.welfare }
      },
      isShow: true,
      active: 0
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    clickTag (item, index) {
      this.active = index
      this.isShow = false
      if (item === '全部') {
        this.params.filters = { node_category: '公益讲座-Seminar' }
      } else {
        this.params.filters = { node_category: '公益讲座-Seminar', seminar_category: item }
      }

      this.$nextTick(() => {
        this.isShow = true
      })
    }
  }
}
</script>

<style scoped lang="less">
.welfare{
    padding:46px 20px 0;
    .tag-wrap{
        flex-wrap:nowrap;
        overflow-x:scroll;
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top:15px;
        &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
        }
        .tag{
            padding:0 5px;
            font-size:12px;
            background: #fff;
            border:1px solid @primary-color;
            border-radius:5px;
            color:@primary-color;
            font-weight:bold;

            margin-right:10px;
            &.active{
                background:@primary-color;
                color:#fff;
            }
        }
    }
}
</style>
