<template>
  <div class="welfare">
    <div class="title-wrap">
      <h3 class="title">精品讲座</h3>
      <div class="right" @click="goToWebinar">
        <p>全部</p>
        <a-icon type="right"></a-icon>
      </div>
    </div>
    <div class="swiper-wrap">
      <div class="swiper-item" v-for="item in welfare" :key="item.name">
        <img :src="item | checkImg(false)" alt="" />
        <p class="item-title">{{ item.title }}</p>
        <p>{{ item.seminar_date }}</p>
        <van-button size="mini" block plain type="primary" v-if="checkIsVideo(item)">查看回放</van-button>
        <van-button size="mini" block type="danger" v-else @click="goToDetail(item)">立即报名</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      welfare: []
    };
  },
  computed: {},
  created() {
    this.params = {
      limit_start: 0,
      limit_page_length: 10,
      filters: { node_category: '公益讲座-Seminar' }
    };
    this.getData();
  },
  mounted() {},
  watch: {},
  methods: {
    goToWebinar() {
      this.$router.push('/welfare');
    },
    goToDetail(item) {
      //   this.setWelfareItem(this.itemData)
      this.$router.push({
        path: `/welfareDetail/${item.name}`
      });
    },
    checkIsVideo(item) {
      if (item.bilibili_src_uri) {
        return true;
      } else {
        return false;
      }
    },
    getData() {
      this.$api['home/news'](this.params)
        .then(res => {
          console.log(res, 'video');
          if (res.message) {
            this.welfare = res.message;
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }
};
</script>

<style scoped lang="less">
.welfare {
  .title-wrap {
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      margin-right: 10px;
      color: #333;
    }

    .right {
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }
    }
  }
  .swiper-wrap {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;

    .swiper-item {
      padding-bottom: 40px;
      margin-left: 20px;
      position: relative;
      &:nth-last-child(1) {
        padding-right: 20px;
      }
      img {
        width: 140px;
        height: 70px;
        border-radius: 6px;
        object-fit: cover;
        object-position: left top;
      }
      p {
        margin-top: 5px;
        font-size: 12px;
        text-align: left;
        display: -webkit-box;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        &.item-title {
          font-weight: 450;
          font-size: 14px;
        }
      }
      .van-button {
        margin-top: 10px;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
</style>
