<template>
  <van-overlay :show="show" class="show-welfare" @click="show = false">
    <div class="lay">
      <div class="wrap">
        <img :src="welfareItem.seminar_poster" alt="" @click="bigImg" />
        <a-icon type="close-circle" theme="filled" class="icon" @click="show = false"></a-icon>
      </div>
      <a-button type="primary" @click="goToDetail">查看详情 >></a-button>
    </div>
  </van-overlay>
</template>

<script>
import { mapState } from 'vuex';
import { ImagePreview } from 'vant';
export default {
  components: {},
  props: {},
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapState({
      welfareItem: state => state.app.welfareItem
    })
  },
  watch: {
    welfareItem(newV) {
      if (newV && !newV.seminar_poster_offline) {
        this.show = true;
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // showWelfare () {
    //   this.show = true
    // },
    goToDetail() {
      this.show = false;
      this.$router.push({
        path: '/welfareDetail',
        query: this.welfareItem
      });
    },
    bigImg() {
      ImagePreview({
        images: [this.welfareItem.seminar_poster]
      });
    }
  }
};
</script>

<style scoped lang="less">
.show-welfare {
  z-index: 100;
  .lay {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .wrap {
      position: relative;
      width: 80%;
    }
    img {
      width: 100%;
      margin-bottom: 20px;
    }
    button {
      width: 80%;
    }
    .icon {
      font-size: 24px;
      position: absolute;
      top: -12px;
      right: -12px;
      color: #fff;
    }
  }
}
</style>
