<template>
  <div class="myOrder" v-if="loginId">
    <top-header :padding="true" title="我的订阅" :has-right="true" @handleRight="goToOrder"></top-header>
    <div v-if="orderAuthor.length" class="author-list">
      <author-item from="home" v-for="item in orderAuthor" :key="item.name" :item="item"></author-item>
    </div>
    <div v-if="hasData">
      <div v-if="orderState.length">
        <order-state-table :tab-height="60" dom-id="homeOrderStateTable"></order-state-table>
      </div>
      <div style="margin-top:10px;" v-if="orderSchool.length">
        <order-school-table :tab-height="60" dom-id="homeOrderSchoolTable"></order-school-table>
      </div>
    </div>
    <div class="noData" v-else>
      暂无订阅信息。前往感兴趣的州或学校，开启我的订阅。
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader'
import AuthorItem from '@/components/AuthorItem'
import OrderSchoolTable from '../components/OrderSchoolTable'
import OrderStateTable from '../components/OrderStateTable'
import { mapGetters } from 'vuex'
export default {
  components: {
    TopHeader, OrderSchoolTable, OrderStateTable, AuthorItem
  },
  props: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['loginId', 'orderSchool', 'orderState', 'orderAuthor']),
    hasData () {
      if (this.orderSchool.length || this.orderState.length) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

    goToOrder () {
      this.$router.push('subscription')
    }
  }
}
</script>

<style scoped lang="less">
.myOrder{
  margin-top:16px;

}
.noData{
  margin-bottom:20px;
  margin-top:10px;
  color:#666
}
.author-list{
    display:flex;
    flex-direction: row;
    overflow-x:scroll;
  }
</style>
