<template>
  <div class="welfare-detail">
    <van-nav-bar fixed title="公益讲座" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="wrap">
      <VideoTxItem v-if="itemData.video_id" :item-data="itemData"></VideoTxItem>
      <VideoBiliItem v-else-if="itemData.bilibili_src_uri" :item-data="itemData"></VideoBiliItem>
      <img v-else :src="$utils.checkImg(itemData, false)" alt="" @click="bigImg($utils.checkImg(itemData))" />

      <div class="con-wrap">
        <h3 class="name">{{ itemData.title }}</h3>
        <p class="modified">{{ itemData.seminar_date }}</p>
        <div class="tag-wrap">
          <van-tag class="tag">{{ itemData.seminar_category }}</van-tag>
        </div>
      </div>
      <top-header title="讲座详情"></top-header>
      <div class="label-wrap">
        <p class="label autoline">{{ itemData.cn_content }}</p>
        <img src="@/assets/qrcode.png" alt="" />
        <p class="tips">扫描二维码，加入讲座微信群</p>
      </div>
    </div>
    <div class="link" v-if="itemData.original_url && !itemData.video_id">
      <a :href="itemData.original_url" target="_blank">立即参与</a>
    </div>
  </div>
</template>

<script>
import { backInitMixin } from '@/utils/mixin';
import { ImagePreview } from 'vant';
import TopHeader from '@/components/TopHeader';
import VideoTxItem from '@/components/VideoTxItem';
import VideoBiliItem from '@/components/VideoBiliItem';
import { mapState } from 'vuex';
export default {
  name: 'WelfareDetail',
  components: {
    TopHeader,
    VideoTxItem,
    VideoBiliItem
  },
  mixins: [backInitMixin],
  props: {},
  data() {
    return {
      itemData: null
    };
  },
  computed: {
    ...mapState({
      //   welfareItem: state => state.app.welfareItem
    })
  },
  watch: {},
  created() {
    this.itemData = this.$route.query;
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    bigImg(url) {
      ImagePreview({
        images: [url]
      });
    }
  }
};
</script>

<style scoped lang="less">
.welfare-detail {
  padding: 46px 18px 80px;
  flex: 1;
  .wrap {
    img {
      width: 100%;
    }
    .con-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-top: 15px;
      margin-bottom: 15px;
      .name {
        font-size: 16px;
        color: #333;
        text-align: left;
      }
      .modified {
        font-size: 14px;
        color: #333;
        text-align: left;
      }
      .tag-wrap {
        text-align: left;
        .tag {
          font-size: 12px;
          background: @primary-color;
        }
      }
    }
    .label-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      .label {
        width: 100%;
        font-size: 14px;
        color: #333;
        text-align: left;
        margin-bottom: 15px;
      }
      img {
        width: 50%;
      }
      .tips {
        color: #333;
        margin-top: 15px;
      }
    }
  }
  .link {
    max-width: 730px;

    margin: auto;
    left: auto;
    position: fixed;
    bottom: 0;
    width: calc(100% - 36px);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ededed;
    background: #fff;
    a {
      width: 80%;
      height: 30px;
      background: #fc8300;
      line-height: 30px;
      color: #fff;
      font-weight: bold;
      border-radius: 6px;
    }
  }
}
</style>
