<template>
  <div class="loadMoreText" v-if="isText">
    <div class="textWrap" @click="loadMore" v-if="hasMore" :loading="loading">
      <p>{{ title }}</p>
      <a-icon type="down"></a-icon>
    </div>
    <p class="noMore" v-else-if="length">没有更多了</p>
    <p class="noMore" v-else>暂无数据</p>
    <div class="sep"></div>
  </div>
  <div class="loadMore" v-else>
    <a-button class="btn" @click="loadMore" v-if="hasMore" :loading="loading">查看更多</a-button>
    <p class="noMore" v-else-if="length">没有更多了</p>
    <p class="noMore" v-else>暂无数据</p>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    length: {
      type: Number,
      default: 1
    },
    hasMore: {
      type: Boolean,
      defalut: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    isText: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '更多动态'
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    loadMore() {
      this.$emit('loadMore');
    }
  }
};
</script>

<style scoped lang="less">
.loadMoreText {
  text-align: center;
  margin-top: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 40px;
  .textWrap {
    display: flex;
    color: #ccc;
    align-items: center;
    padding-bottom: 10px;
    p {
      margin: 0;
      color: #999;
      margin-right: 6px;
    }
  }
  .sep {
    position: absolute;
    right: -16px;
    left: -16px;
    top: 40px;
    height: 8px;
    background: #f1f1f1;
  }
}
.loadMoreText {
  text-align: center;
  margin-top: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}
/deep/ .ant-btn {
  background: #4169e2;
  color: #fff;
  font-weight: bold;
  width: 100%;
  &:hover {
    background: #4169e2;
    color: #fff;
  }
}
</style>
