<template>
  <div class="refCon">
    <!-- <h2 class="title" style="padding:0 16px;">重点关注</h2> -->
    <div class="schoolWrap">
      <top-header :padding="true" title="重点关注--学校"></top-header>

      <search></search>
      <school-table :params="paramsSchool" request="home/measure"></school-table>
    </div>
    <div class="stateWrap">
      <top-header :padding="true" title="重点关注--州"></top-header>
      <state-table :params="paramsState" request="home/map"></state-table>
    </div>
  </div>
</template>

<script>
import { LoadMore } from '@/components';
import TopHeader from '@/components/TopHeader';
import SchoolTable from '../components/SchoolTable';
import StateTable from '../components/StateTable';
import Search from '../components/Search';

export default {
  name: 'Focus',
  components: {
    TopHeader,
    LoadMore,
    SchoolTable,
    Search,
    StateTable
  },
  props: {},
  data() {
    return {};
  },
  computed: {},

  watch: {},
  created() {
    this.paramsSchool = {
      limit_start: 0,
      limit_page_length: 5,

      // filters: { latest_ranking_2022: ['!=', ''] },

      order_by: 'isnull(latest_ranking_2022),length(latest_ranking_2022),latest_ranking_2022 asc,ncov_confirmed desc'
    };
    this.paramsState = {
      limit_start: 0,
      limit_page_length: 5
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="less">
.refCon {
  padding: 16px 0;
}
.stateWrap {
  margin-top: 20px;
}
</style>
