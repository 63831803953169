<template>
  <div class="site-item" @click="goToUrl">
    <div class="img-wrap">
      <img :src="item.logo" alt="" />
    </div>

    <p>{{ item.title }}</p>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
  components: {},
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    goToUrl() {
      this.track();
      if (this.item.extra_url && this.item.extra_url.includes('http')) {
        // window.open(this.item.extra_url);
      } else if (this.item.extra_attach && this.item.extra_attach.includes('http')) {
        console.log(this.item.extra_attach);
        ImagePreview({
          images: [this.item.extra_attach]
        });
      } else {
        this.$router.push('/site');
      }
    },
    track() {
      this.$gtag.event('go_coronavirus_update_site', {
        event_category: 'CoronavirusUpdateSite',
        event_label: `${this.item.category}：：${this.item.title}`
      });
    }
  }
};
</script>

<style scoped lang="less">
.site-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-wrap {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
  p {
    width: 100%;
    margin: 0;
    font-size: 14px;
    color: #333;
    margin-top: 10px;
    text-align: center;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
}
</style>
