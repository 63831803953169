<template>
  <div class="myTable">
    <div class="table" :id="domId">
      <div v-show="tableFix" class="tableHeader"></div>
      <div class="tableHeader" :class="{tableFix:tableFix}" :style="{top:tabHeight+'px'}">
        <p class="name">州</p>
        <p class="confirm">简写</p>
        <p class="course">确诊</p>
        <p class="qushi">新增趋势</p>
        <p class="detail">备注</p>
      </div>
      <div class="tableItem" v-for="(item) in orderState" :key="item.name">
        <p class="name">{{ item.cn_state }}</p>
        <p class="confirm">{{ item.short_name }}</p>
        <p class="course">{{ item.ncov_confirmed | NumberFormat }}</p>
        <p class="qushi">
          <span v-if="item.new_case_status==='新增攀升'"> <i class="iconfont icon-up" style="color:red"></i></span>
          <span v-if="item.new_case_status==='新增稳定'"> <i class="iconfont icon-62" style="color:#fc8300"></i></span>
          <span v-if="item.new_case_status==='新增放缓'"> <i class="iconfont icon-down" style="color:#2Ea878"></i></span>
        </p>
        <p class="detail" @click="goToState(item)"><span class="detail-text">详情 <van-tag class="tips " v-if="item.news_count">+{{ item.news_count }}</van-tag> </span> </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MyTable',
  components: {

  },
  props: {
    tabHeight: {
      type: Number,
      default: 46
    },
    domId: {
      type: String,
      default: 'orderStateTable'
    }
  },
  data () {
    return {

      tableFix: false

    }
  },
  computed: {
    ...mapGetters(['orderState'])
  },
  watch: {

  },
  created () {

  },
  activated () {
    if (this.orderState.length) {
      window.addEventListener('scroll', this.onScroll)
    }
  },
  deactivated () {
    if (this.orderState.length) {
      // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
      window.removeEventListener('scroll', this.onScroll)
    }
  },
  mounted () {
  },
  methods: {
    onScroll () {
      // 获取所有锚点元素
      const tableContent = document.getElementById(this.domId)
      // 所有锚点元素的 offsetTop
      const offsetTop = tableContent.offsetTop
      const offsetHeight = tableContent.offsetHeight

      // 获取当前文档流的 scrollTop
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      //   console.log(123, scrollTop, offsetTop, offsetHeight)
      if (scrollTop > offsetTop - this.tabHeight) {
        this.tableFix = true
        if (scrollTop > offsetTop + offsetHeight - this.tabHeight - 40) {
          this.tableFix = false
        }
      } else {
        this.tableFix = false
      }
    },

    goToState (item) {
      console.log(item)
      this.$router.push({
        path: '/state',
        query: {
          name: item.name,
          short_name: item.short_name
        }
      })
    }
  }

}
</script>

<style scoped lang="less">
.red1 {
  color: red;
}
.green1 {
  color:rgb(40, 183, 163);
}
.gray1 {
  color:rgb(93, 112, 146);
}

.myTable{
    .tableHeader{
        display:flex;
        align-items:center;
        background:#f7f7f7;
        height:40px;
        &.tableFix{
            position:fixed;

            left:0;
            right:0;
            z-index:10;
        }
        p{
            height:100%;
            margin:0;
            line-height:40px;
            text-align:center;
            font-weight:bold;
            color:#333;
        }
        .name{
            background:#e3e7f3;
            width:30%;
        }
        .confirm{
            flex:1;
            background:#e3e7f3;
        }
        .course{
            flex:1;
            background:#e69a8d;
        }
        .qushi{
            flex:1;
            background:#FFC1C1;
        }

        .detail{
            flex:1;
            background:#e3e7f3;
        }
    }
    .tableItem{
        display:flex;
        align-items:center;
        background:#f7f7f7;
        border-bottom: 1px solid #e8e8e8;
        min-height:40px;
        p{
            height:100%;
            margin:0;
            font-size:12px;
            text-align:center;
            font-weight:bold;
            background:#f7f7f7;

        }
        .name{
            width:30%;
        }
        .confirm{
            flex:1;

        }
        .course{

            flex:1;
            color:red;

        }
        .qushi{
            flex:1;

        }
        .detail{
            flex:1;
            color:#4169e2;

            .detail-text{
              position:relative;
              right:10px;
            }
            .tips{
              position:absolute;
              right:-30px;
              top:0;
              background: #ECF0FC;
              color:#4169E2;
              margin-left:10px;
              border-radius:5px;
            }
        }
    }
}

</style>
