<template>
  <div class="schoolMeasure">
    <div class="titleWrap">
      <span></span>
      <h2 class="title">学校措施</h2>
      <p>与学校官方信息同步</p>
      <p class="con">
        <a :href="school.ncov_url" target="_blank">学校官网</a>
      </p>
    </div>
    <div v-if="school.campus_help" class="helpWrap">
      <p class="helpTitle">
        录取政策变化
        <span class="new">新</span>
      </p>
      <p class="autoline myCon">{{ school.admission_policy || '暂无数据' }}</p>
    </div>
    <div class="btnWrap">
      <a-popover placement="bottom" trigger="click" v-if="school.campus_help">
        <div slot="content" class="autoline">{{ school.campus_help }}</div>
        <div class="btn-item">
          就医/求助：
          <div class="btn-icon">
            <i class="iconfont icon-phone"></i>
          </div>
        </div>
        <!-- <a-button type="primary" ghost>州卫生局电话</a-button> -->
      </a-popover>
      <a-popover placement="bottom" trigger="click" v-if="school.consulate_info">
        <div slot="title">{{ school.consulate }}</div>
        <div slot="content" class="autoline">{{ school.consulate_info }}</div>
        <div class="btn-item">
          使领馆电话
          <div class="btn-icon">
            <i class="iconfont icon-phone"></i>
          </div>
        </div>
        <!-- <a-button type="primary" ghost>使领馆电话</a-button> -->
      </a-popover>
      <a-popover placement="bottom" trigger="click" v-if="school.doh_info">
        <div slot="content" class="autoline">{{ school.doh_info }}</div>
        <div class="btn-item">
          州卫生局电话
          <div class="btn-icon">
            <i class="iconfont icon-phone"></i>
          </div>
        </div>
        <!-- <a-button type="primary" ghost>州卫生局电话</a-button> -->
      </a-popover>
    </div>

    <div class="confirm-num">
      确诊人数：
      <span>{{ school.ncov_confirmed }}</span>
    </div>

    <p class="time">更新时间：{{ school.modified | moment }}</p>
  </div>
</template>

<script>
import ReadMore from '@/components/ReadMore';
export default {
  components: {
    ReadMore
  },
  props: {
    school: {
      default: () => {},
      type: Object
    },
    isShareDom: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      unfold: false // 文本是否是展开状态 默认为收起
    };
  },
  computed: {
    // 文本是否超出三行，默认否
    ifOver() {
      console.log(this.$refs.spanBox);
      if (this.$refs.spanBox) {
        console.log(this.$refs.spanBox.offsetHeight, this.$refs.textBox.offsetHeight, 'offset');
        return this.$refs.spanBox.offsetHeight > this.$refs.textBox.offsetHeight;
      } else {
        return true;
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    goToTran(url) {
      window.open(url);
    }
  }
};
</script>

<style scoped lang="less">
.schoolMeasure {
  padding: 0 16px;
  p {
    color: #333;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .time {
    color: #999;
  }
  .highlight {
    color: #d54321;
  }

  .helpWrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .autoline {
      &.myCon {
        padding: 10px;
        background: #ecf0fc;
      }
    }

    .helpTitle {
      font-weight: bold;
      position: relative;
      .new {
        position: absolute;
        left: 90px;
        top: 0;
        background: #cc5955;
        color: #fff;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
    }
  }
}
.titleWrap {
  display: flex;

  align-items: center;
  margin-bottom: 10px;
  span {
    width: 3px;
    height: 20px;
    background: #4169e2;
    margin-right: 10px;
  }
  .title {
    font-size: 18px;
    margin: 10px 0;
  }
  p {
    margin-bottom: 10px;
    margin-left: 20px;
    align-self: flex-end;
  }
}

/deep/ a {
  word-wrap: break-word;
}
.btnWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0px 0;
  .btn {
    margin-right: 20px;
    margin-bottom: 15px;
    color: @primary-color;
    border: 1px solid @primary-color;
  }
  .btn-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 15px;
    .btn-icon {
      width: 18px;
      height: 18px;
      border-radius: 3px;
      background: #4169e2;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      i {
        color: #fff;
        font-size: 12px;
      }
    }
  }
}
.confirm-num {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  span {
    color: red;
    font-weight: bold;
  }
}
</style>
