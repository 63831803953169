<template>
  <div class="welfare-item" @click="goToDetail">
    <img :src="$utils.checkImg(itemData)" alt="" @click="goToOrigin" v-if="from !== 'shareDom'" />
    <div class="con-wrap" :class="{ shareDom: from === 'shareDom' }">
      <h3 class="name">{{ itemData.title }}</h3>
      <p class="label" v-if="itemData.seminar_date">{{ itemData.seminar_date }}</p>
      <div class="tag-wrap">
        <van-tag color="#7EA8FC" class="tag">{{ itemData.seminar_category }}</van-tag>
      </div>
      <div class="btn-wrap">
        <van-button class="btn-plain" size="small" block plain v-if="checkIsVideo()">查看回放</van-button>
        <van-button class="btn-primary" size="small" block v-else @click="goToDetail()">
          <i class="iconfont icon-pointer"></i>立即报名
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  components: {},
  props: {
    itemData: {
      default: () => {},
      type: Object
    },
    from: {
      default: '',
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    ...mapMutations({
      setWelfareItem: 'SET_WELFARE_ITEM'
    }),
    goToDetail() {
      //   this.setWelfareItem(this.itemData)
      this.$router.push({
        path: '/welfareDetail',
        query: this.itemData
      });
    },
    goToOrigin() {
      if (this.itemData.original_url) {
        window.open(this.itemData.original_url);
      }
    },
    checkIsVideo() {
      if (this.itemData.bilibili_src_uri) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped lang="less">
.welfare-item {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ededed;
  img {
    width: 100%;
    height: 200px;

    object-fit: cover;
    object-position: left top;
    border-radius: 10px;
  }
  .con-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 15px;
    .name {
      font-size: 16px;
      color: #333;
      margin-bottom: 10px;
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .label {
      font-size: 14px;
      color: #666;
      margin-bottom: 10px;
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }
    .tag-wrap {
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .tag {
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 12px;
        background: @primary-color;
      }
      p {
        margin: 0;
        color: @primary-color;
        font-weight: bold;
      }
    }
    .btn-wrap {
      .van-button {
        border-radius: 5px;
        font-weight: bold;
      }
      .btn-plain {
        color: @primary-color;
        background: #fff;
        border-color: @primary-color;
      }
      .btn-primary {
        color: #fff;
        background: @primary-color;
        border-color: @primary-color;
        box-shadow: 5px 5px 5px rgba(16, 61, 153, 0.2);
        i {
          margin-right: 5px;
        }
      }
    }
  }
  .shareDom {
    margin-top: 0;
    .name {
      font-size: 25px;
    }
  }
}
</style>
