<template>
  <div class="ame" :class="{shareDom:isShareDom}" id="Ame">
    <h2 class="title" v-if="!isShareDom">全美疫情实时动态</h2>
    <div class="note">
      <p class="noteDes">
        更新时间：
      </p>
      <div class="noteTime">
        <!-- <p>{{ modifiedTime | moment }}   EST</p> -->
        <p>{{ modifiedTime | beijingTime }}   北京时间</p>
      </div>
    </div>

    <div class="top">
      <div
        class="topItem"
        v-for="item in colData"
        :key="item.title"
        :md="{ span: 5, offset: 1 }"
        :lg="{ span: 4, offset: 2 }"
      >
        <span class="tags">
          <span>昨日</span>
          <span :style="item.style">+{{ item.add ||'数据正在同步中' }}</span>
        </span>
        <span class="num" :style="item.style">{{ item.num | numberFormat }}</span>
        <span class="numTitle">{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import MapData from './Map'
import { Toast } from 'vant'
export default {
  components: { MapData },

  props: {
    isShareDom: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {

      modifiedTime: '',
      colData: [

        {
          num: '',
          title: '累计确诊',
          add: '0',
          style: { color: 'rgb(174, 33, 44)' }
        },
        {
          num: '',
          title: '治愈人数',
          add: '0',

          style: { color: 'rgb(40, 183, 163)' }
        },
        {
          num: '',
          title: '死亡人数',
          add: '0',
          style: { color: 'rgb(93, 112, 146)' }
        }
      ]
    }
  },
  computed: {

  },
  watch: {},
  created () {
    this.getCount()
  },
  mounted () {},
  methods: {

    getCount () {
      this.$api['home/count']().then(res => {
        if (res.message) {
          const mes = res.message

          this.colData[0].num = Number(mes.ncov_deaths) + Number(mes.ncov_confirmed) + Number(mes.ncov_recovered)
          this.colData[1].num = mes.ncov_recovered
          this.colData[2].num = mes.ncov_deaths

          this.colData[0].add = mes.ncov_increase_cumulative_confirmed
          this.colData[1].add = mes.ncov_increase_recovered
          this.colData[2].add = mes.ncov_increase_deaths

          this.modifiedTime = mes.ncov_update_datetime
          this.$bus.$emit('dataFrom', mes.announcement)
        }
      }).catch(err => {
        console.log(err)
        Toast('服务器开小差了,地图数据暂时无法获得，请稍后再试')
      })
    }
  }
}
</script>

<style scoped lang="less">
.ame{
  margin:0 16px;
  padding: 16px;
  background:#fff;
  border-radius:8px;
  margin-top:20px;
  &.shareDom{
    margin:10px 0;
    padding:0;
    .note{
      margin-left:5px;
    }
  }
  .title{
    margin-top:0;
  }
}

.note {
  font-size: 14px;
  color: #666;
  text-align: left;
  margin-bottom:20px;
  display:flex;
  align-items:center;
  p{
      margin:0;
      padding:0
    }
  .noteTime{

  }
}
.top {
  display: flex;
  flex-direction: row;

  .topItem {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    align-items: center;
    font-weight: 700;
    .num {
      margin-top:5px;
      font-size: 16px;
    }
    .numTitle {
      margin-top:5px;
      font-size: 12px;
      background: #ededed;
      padding: 2px 4px;
    }
    .tags {
      font-size: 10px;
    }
  }
}
/deep/ .ant-list {
  font-size: 12px;
}
.list {
  h4 {
    font-size: 20px;
    font-weight: 700;
  }
  p {
    font-size: 15px;
    a {
      color: #44c477;
    }
  }
}
</style>
