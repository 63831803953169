<template>
  <div class="state">
    <ShareDom></ShareDom>
    <van-nav-bar
      fixed
      :title="name"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
      <!-- <template #right>
        <order-tag type="state" :name="id"></order-tag>
      </template> -->
    </van-nav-bar>

    <van-tabs
      v-model="active"
      :border="true"
      offset-top="46"
      sticky
      swipeable
      scrollspy
      title-active-color="#4169e2"
      color="#4169e2"
      background="#f7f7f7"
    >
      <van-tab title="确诊数据">
        <div class="topWrap">
          <top-header title="确诊数据"></top-header>
          <div class="confirm">
            <p class="tag">累计确诊</p>
            <p class="tagNum">{{ stateData.ncov_confirmed | numberFormat }}</p>
            <p class="tag">较昨日</p>
            <p class="tagNum">+{{ stateData.ncov_increase_confirmed | numberFormat }}</p>
          </div>

          <!-- <p class="statePolice autoline" v-if="stateData.state_policy"><span style="color:#333">【居家令/复工安排】</span> {{ stateData.state_policy }}</p> -->
          <div class="btnWrap">
            <a-popover placement="bottom" trigger="click" v-if="stateData.consulate_info">
              <div slot="title">{{ stateData.consulate }}</div>
              <div slot="content" class="autoline">{{ stateData.consulate_info }}</div>

              <div class="btn-item">
                使领馆电话 <div class="btn-icon"><i class="iconfont icon-phone"></i></div>
              </div>
            </a-popover>
            <a-popover placement="bottom" trigger="click" v-if="stateData.doh_info">
              <div slot="content" class="autoline">{{ stateData.doh_info }}</div>
              <div class="btn-item">
                州卫生局电话 <div class="btn-icon"><i class="iconfont icon-phone"></i></div>
              </div>
            </a-popover>
          </div>
          <p class="time">更新时间：{{ stateData.modified | moment }}</p>
          <!-- <Sticky :offsetTop="90"></Sticky> -->
        </div>
        <state-map></state-map>
        <state-line></state-line>
        <ad-item v-if="ad['NCOV-AD-PL-00005']" :ad-data="ad['NCOV-AD-PL-00005']" style="margin:20px 0 10px"></ad-item>
      </van-tab>
      <van-tab title="复工通知">
        <back-work></back-work>
      </van-tab>
      <van-tab title="校园疫情">
        <table-data></table-data>
      </van-tab>
      <van-tab title="疫情动态">
        <news-data></news-data>
      </van-tab>
    </van-tabs>

    <ad-item v-if="ad['NCOV-AD-PL-00004']" :ad-data="ad['NCOV-AD-PL-00004']" style="margin:20px 0"></ad-item>
  </div>
</template>

<script>
import { TableData, NewsData, StateMap, StateLine, BackWork } from './item'
import { OrderTag, AdItem, Sticky } from '@/components'
import TopHeader from '@/components/TopHeader'
import ShareDom from './item/ShareDom'
import { mapGetters } from 'vuex'
export default {
  name: 'State',
  components: {
    TableData, NewsData, OrderTag, StateMap, AdItem, Sticky, StateLine, BackWork, TopHeader, ShareDom
  },
  props: {

  },
  data () {
    return {
      name: '',
      id: '',
      active: 0
    }
  },
  computed: {
    ...mapGetters(['stateData', 'ad'])
  },
  watch: {

  },
  created () {

  },
  activated () {
    console.log(421)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.track()
  },
  mounted () {
    this.$bus.$on('getStateName', ({ name, id }) => {
      this.name = name
      this.id = id
    })
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.stateFrom = true
      }

      console.log('fromvm stae', from, vm)
    })
  },
  methods: {

    onClickLeft () {
      console.log(this.$router)
      if (this.stateFrom) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    },
    track () {
      this.$gtag.event('go_coronavirus_update_state', {
        event_category: 'CoronavirusUpdateState',
        event_label: this.name
      })
    }

  }
}
</script>

<style scoped lang="less">
.autoline {
  word-wrap: break-word;
  white-space: pre-wrap;
}
.state{
  padding-top:46px;
}
.topWrap{
  padding:0 16px;
  position:relative;
  .statePolice{
    color:#666;
    margin:10px 0;
  }
  .time{
      color:#999;
      margin:10px 0;
  }
  .confirm{
    display:flex;
    margin:10px 0;
    p{
      margin:0;
    }
    .tag {
        font-size: 12px;
        background: #ededed;
        padding: 2px 4px;
        font-weight:500;
        margin-right:10px;
    }
    .tagNum{
        color:red;
        font-weight:bold;
        margin-right:20px;

    }
  }
  .btnWrap{
    display:flex;
    justify-content: flex-start;
    .btn-item{
          display:flex;
          flex-direction: row;
          align-items:center;
          margin-right:20px;
          .btn-icon{
            width:18px;
            height:18px;
            border-radius:3px;
            background:@primary-color;
            display:flex;
            justify-content: center;
            align-items: center;
            margin-left:10px;
            i{
              color:#fff;
              font-size:12px;

            }
          }
        }

  }
}
/deep/ .van-sticky--fixed{
  z-index:1
}
</style>
