<template>
  <div class="pdetailDes">
    <img :src="$utils.checkImg(itemData)" alt="">
    <h4 class="autoline">{{ itemData.title }}</h4>
    <div class="tagWrap">
      <p class="tagActive"><span>{{ itemData.ju_positions }}</span></p>
      <div>{{ online }}</div>
    </div>
    <div class="tipsWrap">
      <div class="tips"><p class="tipsLabel">实习时间：</p><p class="tipsValue">{{ itemData.ju_start_date }}</p></div>
      <div class="tips"><p class="tipsLabel">实习周期：</p><p class="tipsValue">{{ itemData.ju_duration }}</p></div>
      <div class="tips"><p class="tipsLabel">适合人群：</p><p class="tipsValue"><span v-for="(item,index) in ju_age" :key="index">{{ item }}</span></p></div>
      <div class="tips"><p class="tipsLabel">适合专业：</p><p class="tipsValue">{{ itemData.ju_major }}</p></div>
      <div class="tips"><p class="tipsLabel">备注：</p><p class="tipsValue">{{ itemData.ju_note }}</p></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    itemData: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {

    }
  },
  computed: {
    online () {
      return this.itemData.online ? '报名中' : '暂不开放'
    },
    ju_age () {
      if (this.itemData.ju_age) {
        return this.itemData.ju_age.split(',')
      } else {
        return ''
      }
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.pdetailDes{
    padding:16px;
    h4{
        margin-top:10px;
    }
    img{
        width:100%;
        border-radius:10px;
    }
    .tagWrap{
        margin:10px 0;
        display:flex;
        justify-content: space-between;
        align-items:center;
        p{
            margin:0;
            background:#f5631e;
            font-size:12px;
            height:25px;
            padding:0 10px;
            border-radius:15px;
            line-height:25px;
            color:#fff;
            font-weight:bold;
        }
        div{
            margin-right:10px;
            padding:5px 10px;
            border-radius:17px;
            color:#f5631e;
            font-weight:bold;
        }
    }
    .tipsWrap{
        .tips{
            display:flex;
            p{
                margin-bottom:10px;
            }
            .tipsLabel{
                width:90px;
                color:#999;
                font-weight:bold;
            }
            .tipsValue{
                flex:1;
                color:#333;
                font-size:14px;
                span{
                    padding:3px 5px;
                    background:#f7f7f7;
                    border:1px solid #ddd;
                    margin-right:10px;
                    border-radius:2px;
                }
            }
        }
    }
}
</style>
