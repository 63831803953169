
<template>
  <div class="practiceTab">
    <top-header color="#f5631e" :padding="true" title="往期学院offer榜"></top-header>
    <Banner :banner-data="practiceTab1"></Banner>
  </div>
</template>

<script>
import { Banner } from '@/components'
import TopHeader from '@/components/TopHeader'
import { mapMutations, mapState } from 'vuex'
export default {
  components: {
    TopHeader, Banner
  },
  props: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      practiceTab1: state => state.searchArea.practiceTab1
    })
  },
  watch: {

  },
  created () {
    this.params = {
      filters: { node_category: this.$utils.newsCategory.ju, ju_promotion_category: 'Offer榜' }
    }
    this._getData()
  },
  mounted () {

  },
  methods: {
    ...mapMutations({
      setPracticeTab1: 'SET_PRACTICE_TAB1'
    }),
    _getData () {
      this.$api['home/news'](this.params)
        .then(res => {
          if (res.message) {
            this.setPracticeTab1(res.message)
          }
        })
    }
  }
}
</script>

<style scoped lang="less">

</style>
