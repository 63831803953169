/** @format */

export default {
  state: {
    stateMapGeo: [],
    stateMapData: [],
    newsSlideData: []
  },
  mutations: {
    // 设置用户信息
    SET_STATE_MAP_GEO: (state, data) => {
      state.stateMapGeo = data
    },
    SET_STATE_MAP_DATA: (state, data) => {
      state.stateMapData = data
    },
    SET_NEWS_SLIDE_DATA: (state, data) => {
      state.newsSlideData = data
    }

  },
  actions: {

  }
}
