<template>
  <article class="share-card" id="shareDomSchool" v-show="isShowShareDom">
    <h1 class="title"># 美国校园疫情动态 实时更新 #</h1>
    <div class="content">
      <h3 class="title">{{ school.school_cn }}</h3>
      <div class="conWrap">
        <div class="confirm">
          <p class="tag">累计确诊</p>
          <p class="tagNum">{{ school.ncov_confirmed }}</p>
        </div>
        <p class="schoolState">所在州：<a @click="goToState(school)">{{ school.school_state_short }}</a></p>
      </div>
      <school-measure :school="school" :is-share-dom="true"></school-measure>
      <school-news :is-share-dom="true"></school-news>
      <state-news :is-share-dom="true"></state-news>
      <!-- <school-data :school="school" :isShareDom="true"></school-data> -->
    </div>
    <footer class="footer">
      <div class="right">
        <img src="@/assets/web-qrcode.png" class="qr-code">
        <div class=" qr-help">扫码 查看详情</div>
      </div>
      <div class="left">
        <div class="leftConWrap">
          <div class=" title1">全美、各州、定点学校</div>
          <div class=" title1">疫情趋势及应对措施</div>
          <div class=" title2">更多热点：使馆信息｜疫苗进展｜联邦新闻｜身份/出入境｜机票行情｜实习就业新闻</div>
        </div>
        <div class="logo">
          <img src="@/assets/logo-white.png">
          <div>美国厚仁教育</div>
        </div>
      </div>
    </footer>
  </article>
</template>

<script>
import { SchoolMeasure, SchoolNews, StateNews, SchoolData } from './'
import { mapState } from 'vuex'
export default {
  components: {
    SchoolMeasure, SchoolNews, StateNews, SchoolData
  },
  props: {

  },
  data () {
    return {
      isShowShareDom: true

    }
  },
  computed: {
    ...mapState({
      school: state => state.app.schoolData
    })
  },
  watch: {

  },
  created () {

  },
  mounted () {
    this.$bus.$on('isShowShareDom', (data) => {
      console.log(data, 'ddddd')
      this.isShowShareDom = data.show
    })
    this.isShowShareDom = false
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.share-card{
    display: flex;
    background-image: linear-gradient( to right,#593ae2,#499fe7);
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 50%;
    transform:translate(-50%);
    z-index: -1;
    // background: rgb(73, 123, 137);
    // background: rgb(28, 145, 177);

    padding: 10px;
    .title{
        color:#fff;
        font-size:18px;
    }
    @media screen and (min-width: 576px){
        article {
            width: 375px;
        }
    }

    .footer{
        display: flex;
        font-size: 12px;
        color: rgb(255, 255, 255);
        width: 100%;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-top: 20px;
        height: 118px;
        .left{
            font-weight: bold;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: justify;
            justify-content: space-between;
            text-align:left;
            align-items:center;
            margin-right:20px;
            position:relative;
            .leftConWrap{
              margin-left:15px;
            }
            .logo{

                position:absolute;
                left:50%;
                transform:translate(-50%);
                bottom:5px;
                display:flex;
                img{
                  width:20px;
                  height:20px;
                }
            }

            .title1{
                font-size:14px;
                color:#fff;

            }
            .title2{
                font-size:12px;
                margin-top:5px;
            }
        }
        .right{
            img{
                width:80px;
                height:80px;
                margin-bottom:10px;
            }
            .qr-help{
                text-align:center;
            }
        }
    }
}
 .content{
    width: 100%;
    background: rgb(255, 255, 255);
    border-radius: 6px;
    padding: 10px 5px;
     .title{
      color:#333;
      text-align:center;
      margin:0;
    }
 }
  .conWrap{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      .schoolState{
        text-align: right;
        margin-right:20px;
        width:30%;
      }

    }
     .btnWrap{
        display:flex;
        justify-content: space-evenly;
        margin-bottom:10px;
      }
    .confirm{
      display:flex;
      align-items:center;
      .tag {
        font-size: 12px;
        background: #ededed;
        padding: 2px 4px;
        font-weight:500;
        margin-right:20px;
      }
      .tagNum{
        color:red;
        font-weight:bold;

      }
      .tips{
        font-size:16px;
        margin-left:20px;
        margin-bottom:8px;
        color:#999

    }

}
</style>
