
import School from '@/views/school/School'
import SchoolList from '@/views/school/SchoolList'

export default [

  {
    path: '/school',
    name: 'school',
    component: School,
    meta: { title: '学校详情' }

  },
  {
    path: '/schoolList',
    name: 'schoolList',
    component: SchoolList,
    meta: { title: '学校列表' }

  }

]
