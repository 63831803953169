
<template>
  <div class="select" v-if="schoolList.length">
    <a-select
      show-search
      v-model="defaultValue"
      :placeholder="placeholder"
      default-active-first-option
      option-filter-prop="children"
      style="width: 85%"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
      :filter-option="filterOption"
    >
      <a-icon slot="suffixIcon" type="search"></a-icon>
      <p style="white-space:normal" slot="notFoundContent">抱歉，暂时没有收录这所学校，请您联系客服告知。我们会尽快核实收录。</p>

      <a-select-option :value="index" v-for="(item,index) in schoolList" :key="item.id">{{ item.name }}</a-select-option>
    </a-select>
    <a-button type="primary" class="btn" shape="round" size="small" @click="search">搜索</a-button>
  </div>
</template>
<script>
import { Toast } from 'vant'
import { mapState } from 'vuex'
export default {
  props: {
    canSearch: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {

      defaultValue: undefined,
      placeholder: '输入学校中文名称，查看详情'
    }
  },
  computed: {
    ...mapState({
      schoolList: state => state.app.schoolList
    })
  },
  created () {

  },
  mounted () {

  },

  methods: {
    handleFocus () {
      this.placeholder = ''
      this.defaultValue = undefined
    },
    handleBlur () {
      this.placeholder = '输入学校中文名称，查看详情'
    },
    handleChange (value) {
      this.defaultValue = value
    },
    search () {
      if (this.defaultValue === undefined) {
        Toast('请选择学校')
        return
      }

      const school = this.schoolList[this.defaultValue]

      if (this.canSearch) {
        this.$emit('search', { school: school })
        return
      }
      this.$router.push({
        path: '/school',
        query: {
          name: school.id,
          state: school.state
        }
      })
    },

    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }

  }

}
</script>
<style scoped lang="less">
.select{
    padding:0 16px;
    margin-bottom:10px;
    display:flex;
    align-items:center;
    .btn{
        margin-left:10px;
    }
}
/deep/ .ant-select-selection-selected-value{
    margin-left:10px;
}
/deep/ .ant-select-selection__placeholder{
    margin-left:10px;
}

/deep/ .ant-select-dropdown-menu-item{
        white-space: wrap;
    }
/deep/ .ant-select-selection{
    background:#f5f7fa
}

/deep/ .ant-select-open .ant-select-arrow-icon svg{
    transform:none
}
</style>
