<template>
  <div class="schoolItem">
    <p class="shortName">{{ itemData.school_state_short }}</p>
    <p class="name">{{ itemData.school_cn }}</p>
    <p>确诊 <span class="confirm">{{ itemData.ncov_confirmed | numberFormat }}</span> </p>
    <a-button v-if="!isOrder" type="primary" ghost @click="handleOrder" :loading="loading">订阅</a-button>
    <a-button v-else type="primary" @click="handleCancel" :loading="loading">已订阅</a-button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Toast } from 'vant'
export default {
  components: {

  },
  props: {
    itemData: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['orderSchool', 'loginId']),
    isOrder () {
      return this.orderSchool.some(item => {
        if (item.name === this.itemData.name) {
          return true
        }
      })
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    ...mapMutations({
      setOrderSchool: 'SET_ORDER_SCHOOL'

    }),
    async handleOrder () {
      const res = await this._getOrder('POST')
      if (res.code !== 200) {
        return
      }

      const myOrderSchool = [].concat(this.orderSchool)
      myOrderSchool.push(this.itemData)
      this.setOrderSchool(myOrderSchool)
    },
    async handleCancel () {
      const res = await this._getOrder('DELETE')
      if (res.code !== 200) {
        return
      }

      const myOrderSchool = [].concat(this.orderSchool)
      myOrderSchool.forEach((item, index) => {
        if (item.name === this.itemData.name) {
          myOrderSchool.splice(index, 1)
        }
      })
      this.setOrderSchool(myOrderSchool)
    },
    async _getOrder (method) {
      this.params = {
        dt: 'nCov School Data',
        dn: this.itemData.name,
        subscriber: this.loginId,
        action: 'add'
      }

      if (method === 'DELETE') {
        this.params.action = 'del'
      }
      this.loading = true
      const res = await this.$api['auth/handleSub'](this.params)
      this.loading = false
      Toast(res.msg)
      return res
    }
  }
}
</script>

<style scoped lang="less">
.schoolItem{
    width:150px;
    // flex:1;
    border:1px solid #dcdcdc;
    border-radius:4px;
    margin:10px 20px 20px 0;
    padding:10px 10px 15px;
    display:flex;
    flex-direction: column;
    align-items:center;
    p{

        text-align:center;
        color:#333;
        margin-bottom:6px;
        &.shortName{
            width:50px;
            height:50px;
            border-radius:25px;
            background:rgba(65,105,226,0.6);

            color:#fff;
            display:flex;
            justify-content: center;
            align-items: center;
        }
        &.name{
            width:100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .confirm{
            color:red;
        }
    }
    button{
        margin:0 30px;
        font-weight:bold;
    }

}
</style>
