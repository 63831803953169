export default [
  {
    name: 'redirect',
    method: 'GET',
    desc: '微信认证',
    path: '/api/method/soa.ncov.api.coronavirus_mp_authorize',
    headers: {
      'x-mp-platform': 'WeChat',
      'x-mp-appname': 'wrlx'
    }
  },
  {
    name: 'token',
    method: 'GET',
    desc: 'code换取token',
    path: '/api/method/soa.ncov.api.coronavirus_mp_access_token'
  },
  {
    name: 'refreshToken',
    method: 'GET',
    desc: '刷新token',
    path: '/api/method/soa.ncov.api.coronavirus_mp_refresh_token'
  },
  {
    name: 'userInfo',
    method: 'GET',
    desc: '用户信息',
    path: '/api/method/soa.ncov.api.coronavirus_mp_save_user'
  }

]
