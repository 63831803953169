<template>
  <div class="video ">
    <top-header title="后疫情时代之留美解析"></top-header>
    <!-- <h2 class="title">校园防疫知识视频</h2> -->

    <p class="label">
      这是「陈航说留美」小视频栏目，为大家带来特别解读，话题征集自群里家长反馈，包括留美申请注意事项、美国选举对留学的影响、解读入境限令、学生如何保护自己、如何保持学业不中断、后疫情时代求职技巧、远程学习的注意事项等。
    </p>

    <video-bili-item ref="video" :item-data="videoData"></video-bili-item>

    <a-button class="btn" @click="goToAll">更多视频 <a-icon type="double-right"></a-icon></a-button>
  </div>
</template>

<script>
import VideoBiliItem from '@/components/VideoBiliItem';
import { TopHeader } from '@/components';
export default {
  components: {
    VideoBiliItem,
    TopHeader
  },
  props: {},
  data() {
    return {
      videoData: {},
      current: 0
    };
  },
  computed: {},
  watch: {},
  created() {
    this.params = {
      limit_start: 0,
      limit_page_length: 1,
      filters: { node_category: this.$utils.newsCategory.shortVideo }
    };
    this.getNews();
  },
  mounted() {},
  methods: {
    goToAll() {
      this.$router.push('videoDetail');
    },
    getNews() {
      this.loading = true;
      this.$api['home/news'](this.params)
        .then(res => {
          console.log(res, 'video');
          if (res.message) {
            this.videoData = res.message[0];
            console.log(res.message, 'resMessage');
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }
};
</script>

<style scoped lang="less">
.video {
  padding: 16px;
  position: relative;
  .titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
    }
  }
  .label {
    font-size: 12px;
    margin-bottom: 8px;
  }
}

/deep/ .ant-btn {
  margin-top: 20px;
  // background:#4169e2;
  border-color: #4169e2;
  color: #4169e2;
  font-weight: bold;
  width: 100%;
  &:hover {
    background: #4169e2;
    color: #fff;
  }
}
</style>
