/** @format */

/** @format */

export const backInitMixin = {
  components: {},

  props: {},

  computed: {},

  data () {
    return {

    }
  },

  created () { },

  mounted () { },

  activated () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.backName = to.name
        vm[to.name] = true
      }

      console.log('fromvm stae', to, from, vm)
    })
  },

  methods: {
    onClickLeft () {
      console.log(this.$router)
      if (this[this.backName]) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    }
  }
}
