<template>
  <div class="" v-if="isShow">
    <!-- <div class="rate">
      <a-rate :allowClear="false" :defaultValue="3" />
      <span>4.9</span> / <span>5</span> <span>(5986</span>  <span>votes)</span>
    </div> -->

    <!-- <div v-if="shareData" style="margin:20px 0">
      <div v-html="shareData.block_execcode"></div>
    </div> -->
    <div class="foot">
      <p>客服电话：+86 (010) 5387-5758（中国）</p>
      <p>+1 (412) 756-3137（美国）</p>
      <p>北京厚仁教育科技有限公司 版权所有</p>
      <p>
        Copyright © 2014 - 2021 北京厚仁教育
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  props: {},
  data() {
    return {
      isShow: true
    };
  },
  computed: {
    ...mapGetters(['shareData'])
  },
  watch: {
    $route(newV, oldV) {
      console.log('newV', newV, oldV);

      if (newV) {
        if (this.noShowList.includes(newV.name)) {
          this.isShow = false;
        } else {
          this.isShow = true;
        }
      }
    }
  },
  created() {
    this.noShowList = ['welfareDetail', 'expertDetail'];
  },
  mounted() {
    console.log(this.$route.name, 'name');
    if (this.noShowList.includes(this.$route.name)) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  },
  methods: {}
};
</script>

<style scoped lang="less">
.iconWrap {
  margin: 10px 0;
}
p {
  margin: 0;
}
.foot {
  display: flex;
  flex-direction: column;

  padding: 20px 20px;
  justify-content: center;
  align-items: center;
  background: #555;
  p {
    margin-bottom: 10px;
    color: #fff;
    text-align: center;
    font-size: 14px;
  }
}
</style>
