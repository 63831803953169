<template>
  <div class="pdetailTab">
    <top-header color="#f5631e" title="项目介绍"></top-header>
    <div class="con" v-html="itemData.ju_brief">
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader'
export default {
  components: {
    TopHeader
  },
  props: {
    itemData: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created () {
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.pdetailTab{
    padding:0 16px;
    margin-top:10px;
    .con{
        text-align:left;
        font-size:14px;
    }
}
</style>
