<template>
  <div class="tagsWrap">
    <div class="tagItem" v-for="(item,index) in tagList" :key="item.name" @click="clickTag(item,index)">
      <span class="name">{{ item.name }}</span>
      <van-tag class="tips " type="danger" v-if="newsCount[item.tips]">+{{ newsCount[item.tips] }}</van-tag>
    </div>
    <!-- <div class="tagItem liuxue" @click="goToReport">
      <span>留学日报</span>
      <a-icon type="double-right"></a-icon>
    </div> -->
    <div class="line line_1"></div>
    <div class="line line_2"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {

  },
  props: {

  },
  data () {
    return {
      tagList: [

        {
          name: '校园疫情',
          tips: this.$utils.newsCategory.news,
          key: 'news'
        },
        {

          name: '学业新闻',
          tips: this.$utils.newsCategory.study,
          key: 'study'
        },
        {
          name: '实习就业',
          tips: this.$utils.newsCategory.job,
          key: 'job'
        },
        {
          name: '使馆信息',
          tips: this.$utils.newsCategory.embassy,
          key: 'embassy'
        },
        {
          name: '疫苗进展',
          tips: this.$utils.newsCategory.vaccine,
          key: 'vaccine'
        },

        {
          name: '身份/出入境',
          tips: this.$utils.newsCategory.identity,
          key: 'identity'
        }

      ]
    }
  },
  computed: {
    ...mapState({
      newsCount: state => state.searchArea.newsCount
    })
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    clickTag (item, index) {
      this.$router.push({
        path: '/news',
        query: {
          item: item,
          index: index + 2
        }

      })
    },
    goToReport () {
      this.$router.push('report')
    }
  }
}
</script>

<style scoped lang="less">
.tagsWrap{
  display:flex;
  flex-wrap:wrap;
  margin:10px 0 ;
  position:relative;
  .line{
    position:absolute;

    height:100%;
    width:1px;
    background:#f1f1f1;
    &.line_1{
      left:34%;
    }
    &.line_2{
      left:68%;
    }
  }
  .tagItem{
    width:25%;
    margin:0 4%;
    height:30px;
    display:flex;
    justify-content: center;
    align-items:center;
    position:relative;
      &.activeTag{
        background:@primary-color;
        border-radius:5px;
        .name{
           color:#fff
        }
      }
      &.liuxue{
        display:flex;
        align-items: center;
        justify-content: center;
        i{
          position:relative;
          top:1px;
          margin-left:5px;
        }
      }

    .name{
      color:#333;

    }
    .tips{
      position:absolute;
      right:-15px;
      background: #ECF0FC;
      color:#4169E2;
      margin-left:10px;
      border-radius:5px;
      &.hot{
         background:#fc8300
      }
      &.fei{

      }
      &.jian{

      }
      &.new{
        background:#ffcc00
      }
    }
  }
}
</style>
