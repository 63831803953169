<template>
  <div class="protect refCon">
    <van-nav-bar
      fixed
      title="辟谣与防护"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div style="padding:0 20px">
      <van-swipe class="my-swipe" :autoplay="3000" ref="vanSwipe" @change="change" :show-indicators="false">
        <van-swipe-item v-for="(item,index) in imgData" :key="index" class="imgWrap" @click="showModal(index)">
          <img :src="item" alt="" class="img">
        </van-swipe-item>
        <!-- <div class="custom-indicator" slot="indicator">
          {{ current + 1 }}/{{ protectData.length }}
        </div> -->
      </van-swipe>
    </div>

    <!-- <div class="getMore">
      <div>
        <p>左右滑动查看更多</p>
      </div>
    </div> -->
    <div class="iconWrap">
      <div @click="handlePrev">
        <a-icon type="left"></a-icon>
      </div>
      <div @click="handleNext">
        <a-icon type="right"></a-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'

export default {
  components: {

  },
  props: {

  },
  data () {
    return {
      protectData: [],
      current: 0
    }
  },
  computed: {
    imgData () {
      return this.protectData.map(item => {
        return item.attach_image_url || `${item.attach_image}`
      })
    }
  },
  watch: {

  },
  created () {
    this.params = {
      limit_start: 0,
      limit_page_length: 20,
      filters: { node_category: this.$utils.newsCategory.protect }
    }
    this.getNews()
  },
  mounted () {

  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.protect = true
      }

      console.log('fromvm stae', from, vm)
    })
  },
  methods: {
    change (index) {
      this.current = index
    },
    handlePrev () {
      this.$refs.vanSwipe.prev()
    },
    handleNext () {
      this.$refs.vanSwipe.next()
    },
    showModal (index) {
      ImagePreview({
        images: this.imgData,
        startPosition: index

      })
    },
    getNews () {
      this.loading = true
      this.$api['home/news'](this.params)
        .then(res => {
          console.log(res, 'protect')
          if (res.message) {
            this.protectData = res.message
          }
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    onClickLeft () {
      console.log(this.$router)
      if (this.protect) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped lang="less">
.protect{
    margin-top:46px;
    padding:0 16px;
    position:relative;
}
.imgWrap{
    background:#555;
    img{
        width:100%;
        object-fit:cover;

    }
}
.custom-indicator{
  position:absolute;
  top:0;
  left: 50%;
  transform: translate(-50%);
}

.getMore{
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translate(-50%);
  height:50px;
  justify-content: center;
  align-items: center;
  display:flex;
  div{
    background:rgba(0,0,0,.6);
    width:200px;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:5px;
    p{
      margin:0;
      color:#fff;
      font-size:22px;
      font-weight:bold;

    }
  }
}
.iconWrap{
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding:0 16px;
  width:100%;
  justify-content: space-between;
  align-items: center;
  display:flex;
  div{
    background:rgba(0,0,0,.4);
    width:40px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
    i{
      font-size:20px;
      color:#fff;
      font-weight:bold;
    }
  }
}
/deep/ .van-swipe__track{
  margin-top:20px;
}
/deep/ .slick-slide {
    text-align: center;
    // height: 160px;
    // line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }

 /deep/ .slick-slide h3 {
    color: #fff;
  }
</style>
