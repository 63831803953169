<template>
  <div>
    <div class="part application">
      <top-header title="直申录取数据"></top-header>
      <div class="listItem" :class="{ bgColor: index % 2 === 0 }" v-for="(item, index) in application" :key="item.key">
        <p>{{ item.label }}</p>
        <p v-if="item.type === 'rate'">{{ school[item.key] | checkRate }}</p>
        <p class="autoline" v-else>{{ school[item.key] || '暂无数据' }}</p>
      </div>
    </div>
    <div class="part change">
      <top-header title="转学录取数据"></top-header>
      <div class="listItem" :class="{ bgColor: index % 2 === 0 }" v-for="(item, index) in change" :key="item.key">
        <p>{{ item.label }}</p>
        <p v-if="item.type === 'rate'">{{ school[item.key] | checkRate }}</p>
        <p v-else-if="item.type === 'checkNumber'">{{ school[item.key] | NumberFormat }}</p>
        <p class="autoline" v-else>{{ school[item.key] || '暂无数据' }}</p>
      </div>
      <!-- <div class="school-link" @click="goToTran(school.transferadm)" v-if="school.transferadm">
        <div class="school-link-left">
          <i class="iconfont icon-ketangbiaoxian1"></i>
          <span>转学指南</span>
          <span>|</span>
          <span class="school">厚仁专家解析 本科转学申请</span>
        </div>
        <div class="school-link-right">
          <a-icon type="right"></a-icon>
        </div>
      </div> -->
    </div>
    <div class="part message">
      <top-header title="招生办公室"></top-header>
      <p class="autoline">{{ school.un_vint_stucontact || '暂无数据' }}</p>
    </div>
    <div class="part job">
      <top-header title="就业信息"></top-header>
      <div class="listItem" :class="{ bgColor: index % 2 === 0 }" v-for="(item, index) in jobData" :key="item.key">
        <p>{{ item.label }}</p>
        <p class="autoline" v-if="school[item.key]">${{ school[item.key] | NumberQian }}</p>
        <p class="autoline" v-else>{{ '暂无数据' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import { mapState } from 'vuex';
export default {
  components: { TopHeader },
  props: {},
  data() {
    return {
      application: [
        {
          label: '截止日期',
          key: 'un_application_deadline'
        },
        {
          label: 'EA',
          key: 'un_early_actplan_close'
        },
        {
          label: 'ED',
          key: 'un_early_decplan_close'
        },
        {
          label: '录取率',
          key: 'un_rc_accept_rate',
          type: 'rate'
        }
      ],
      change: [
        {
          label: '截止日期',
          key: 'un_vclo_date'
        },
        {
          label: '录取率',
          key: 'un_accept_rate_transfer',
          type: 'rate'
        },
        {
          label: '申请人数',
          key: 'un_trfstu_apptot',
          type: 'checkNumber'
        },
        {
          label: '录取人数',
          key: 'un_trfstu_admtot',
          type: 'checkNumber'
        }
      ],
      jobData: [
        {
          label: '毕业起薪',
          key: 'early_career_pay'
        },
        {
          label: '事业中期年薪',
          key: 'mid_career_pay'
        }
      ]
    };
  },
  computed: {
    ...mapState({
      school: state => state.app.schoolData
    })
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    goToTran(url) {
      window.open(url);
    }
  }
};
</script>

<style scoped lang="less">
.part {
  padding: 0 16px;
  background: #fff;
  padding-bottom: 20px;
  .bgColor {
    background: #f7f7f7;
  }
  .autoline {
    word-wrap: break-word;
    white-space: pre-wrap;
    color: #333;
  }
}
.summary {
  .website {
    width: 50px;
    height: 22px;
    border: 1px solid #4169e2;
    color: #4169e2;
    border-radius: 5px;
    line-height: 21px;
    margin-left: 20px;
  }
  .tags {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
  }
}
.change {
  .school-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 0 10px;
    border: 1px solid #ededed;
    border-radius: 6px;
    box-shadow: 3px 1px 9px rgba(0, 0, 0, 0.2);

    height: 35px;
    font-size: 14px;
    .icon-ketangbiaoxian1 {
      color: #4169e2;
      margin-right: 5px;
    }
    span {
      margin-right: 5px;
    }
    .school {
      color: #4169e2;
    }
  }
}
.message {
  margin-bottom: 0;
}
.listItem {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  p {
    color: #333;
    padding: 0 16px;
    text-align: left;
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    min-height: 30px;
    line-height: 24px;
  }
}
</style>
