/** @format */

import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import user from './modules/user';
import searchArea from './modules/searchArea';
import shareDom from './modules/shareDom';
import wechat from './modules/wechat';
import blog from './modules/blog';
import createPersistedState from 'vuex-persistedstate';
import createLogger from 'vuex/dist/logger';
import { VUEX_DEFAULT_CONFIG } from '@/config';

const createPersisted = createPersistedState({
  storage: window.localStorage,
  reducer(val) {
    return {
      // 只储存state中的assessmentData
      user: val.user
    };
  }
});

Vue.use(Vuex);

export default new Vuex.Store({
  ...VUEX_DEFAULT_CONFIG,
  modules: {
    app,
    user,
    searchArea,
    shareDom,
    wechat,
    blog
  },
  getters,
  strict: VUEX_DEFAULT_CONFIG.strict,
  plugins: VUEX_DEFAULT_CONFIG.strict ? [createLogger(), createPersisted] : [createPersisted]
});
