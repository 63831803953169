import Vue from 'vue';
import moment from 'moment-timezone';
import 'moment/locale/zh-cn';
import { Base64 } from 'js-base64';
moment.locale('zh-cn');

Vue.filter('NumberFormat', function(value) {
  if (!value) {
    return '数据正在同步中';
  }
  if (value === 0) {
    return '--';
  }
  const numStr = value.toString().split('.')[0];
  if (numStr.length < 5) {
    // 判断数字有多长,如果小于6,,表示10万以内的数字,让其直接显示
    console.log(numStr);
    const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断
    return intPartFormat;
  } else if (numStr.length >= 5 && numStr.length <= 8) {
    // 如果数字大于6位,小于8位,让其数字后面加单位万
    const decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + 1);
    console.log(decimal);
    // 由千位,百位组成的一个数字
    return parseFloat(parseInt(value / 10000) + '.' + decimal) + '万';
  } else if (numStr.length > 8) {
    // 如果数字大于8位,让其数字后面加单位亿
    const decimal = numStr.substring(numStr.length - 8, numStr.length - 8 + 1);
    console.log(decimal);
    return parseFloat(parseInt(value / 100000000) + '.' + decimal) + '亿';
  }
});

Vue.filter('NumberQian', function(value) {
  if (!value && value !== 0) {
    return '--';
  }
  if (value === 0) {
    return '--';
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断
  return intPartFormat;
});

Vue.filter('dayjs', function(dataStr, pattern = 'YYYY-MM-DD HH:mm') {
  if (!dataStr) {
    return '暂无数据';
  }
  return moment(dataStr).format(pattern);
});

Vue.filter('moment', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern);
});
Vue.filter('beijingTime', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr)
    .add(12, 'hours')
    .format(pattern);
});
Vue.filter('expertTime', function(dataStr) {
  const data1 = moment(dataStr).format('YYYY');
  if (data1 === '2019') {
    return moment(dataStr).format('YYYY年MM月DD日');
  } else {
    return moment(dataStr).format('MM月DD日');
  }
});
Vue.filter('authorHide', function(dataStr) {
  var data = Base64.decode(dataStr);

  const arr = data.split('');
  const len = arr.length;
  arr.forEach((item, index) => {
    if (index >= 3 && index <= len - 3) {
      arr[index] = '*';
    }
  });
  console.log('data', typeof data, data, arr, len);
  return arr.join('');
});
Vue.filter('checkImg', function(item, type) {
  let remoteUrl = '';
  if (!item) {
    return '';
  }
  if (typeof item === 'object') {
    remoteUrl = item.attach_image_url || `${item.attach_image}`;
  } else {
    remoteUrl = `${item}`;
  }

  return remoteUrl;
});
Vue.filter('checkImgScale', function(item, type) {
  let remoteUrl = '';
  if (typeof item === 'object') {
    remoteUrl = item.attach_image_url || `${item.attach_image}`;
  } else {
    remoteUrl = `${item}`;
  }

  const pinjieUrl = remoteUrl.split('/').slice(-1)[0];

  const imgType = pinjieUrl.split('.').slice(-1)[0];

  if (type === 100) {
    remoteUrl = remoteUrl + '_100-.' + imgType;
  }
  if (type === 1000) {
    remoteUrl = remoteUrl + '_-1000.' + imgType;
  }
  return remoteUrl;
});

Vue.filter('checkTime', function(time) {
  const startDate = moment.tz(time, 'America/New_York').utc();
  const endDate = moment().utc();

  // const ms = endDate.diff(startDate) // 返回毫秒数

  const month = endDate.diff(startDate, 'months'); // 0
  const weeks = endDate.diff(startDate, 'weeks'); // 1
  const days = endDate.diff(startDate, 'days'); // 7
  const hours = endDate.diff(startDate, 'hours'); // 7
  const minutes = endDate.diff(startDate, 'minutes'); // 7
  // console.log(ms, month, weeks, days, hours, minutes, startDate, endDate, 'startDate')
  if (month) {
    return '';
  } else if (weeks) {
    return '';
  } else if (days) {
    return '';
  } else if (hours) {
    return hours + '小时前';
  } else if (minutes) {
    return minutes + '分钟前';
  } else {
    return '';
  }
});

Vue.filter('checkRate', function(dataStr) {
  if (!dataStr) {
    return '暂无数据';
  }
  return `${dataStr}%`;
});
