<template>
  <div class="videoItem">
    <div :id="txPlayerId" class="videoID"></div>
  </div>

  <!-- <video
    :id="tcPlayerId"
    class="tencent-player"
    width="900"
    preload="auto"
    playsinline
    webkit-playsinline
  ></video> -->
</template>
<script>
export default {
  name: 'TencentPlayer',
  props: {
    itemData: {
      default: () => {},
      type: Object
    },

    keyIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      txPlayerId: 'txPlayerId' + this.keyIndex,
      player: null
    }
  },
  watch: {
    vid: {
      handler (newV, oldV) {
        this.$nextTick(() => {
          this.initVideo()
        })
      },
      immediate: true,
      deep: true
    }

  },
  methods: {
    pause () {
      this.player.pause()
    },
    initVideo () {
      const playerParm = {
        containerId: this.txPlayerId,
        vid: this.itemData.video_id,
        width: '100%'
        // height: screen.availHeight / 3

      }

      setTimeout(() => {
        if (!this.player) {
          // this.player = new window.Txplayer(playerParm)
          this.player = new window.Txp.Player(playerParm)
          console.log(this.player, 'window')
        } else {
          this.player.loadVideoByID(playerParm)
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.videoItem{
  width:100%;
  height:0;
  padding-top:57%;
  position:relative;
  .videoID{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
}
</style>
